import React, { useState, useContext, useEffect } from 'react';
import { Route, Switch, useLocation, Redirect, useHistory, global } from 'react-router-dom';
//import Dashboard from 'components/dashboards/default';
// import MyFarm from 'components/myfarm/default';
import MyFarm from 'Pages/Farms/DashboardContent';
import NavbarTop from 'Components/navbar/top/NavbarTop';
import NavbarVertical from 'Components/navbar/vertical/NavbarVertical';
// import AppContext from 'context/Context';
// import MainRoutes from './MainRoutes';
import classNames from 'classnames';
import climatedata from 'Components/Others/climatedata';
import AlertTemplate from 'react-alert-template-basic'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import DashboardRoutes from 'Routes/DashboardRoutes';
import { getZoneList } from 'API/hydroponicsFarmsAPI';
import AuthContext from 'Context/AuthContext';
import FarmContext from 'Context/FarmContext';
import DashboardContext from 'Context/DashboardContext';
import { getItemFromStore } from 'helpers/utils';

const options = {
    position: positions.BOTTOM_RIGHT,
    timeout: 2000,
    offset: '30px',
    transition: transitions.SCALE
}

const DashboardLayout = () => {
    const { authState } = useContext(AuthContext);
    const { farmState, dispatchFarmState } = useContext(FarmContext);
    const { dashboardState, dispatchDashboardState } = useContext(DashboardContext);
    const history = useHistory();

    const getZones = async () => {
        let resData = await getZoneList(authState.accessToken, farmState.currentFarmData.farm._id, dispatchFarmState);
        console.log(resData)
        if (resData.error) {
        }
        if (resData.success) {
        }
    }
    useEffect(() => {
        // if (!getItemFromStore('farmState') && !farmState) {
        //     history.push('/farm-selection');
        // }
        if (!farmState.currentFarmData.farm._id) {
            if (getItemFromStore('farmState')) {
                if (getItemFromStore('farmState').currentFarmData.farm._id) {
                    dispatchFarmState({
                        type: 'UPDATE_FARM_STATE',
                        payload: getItemFromStore('farmState')
                    })
                }
            }
            else {
                console.log("No farm selected")
                history.push('/farm-selection');
            }
        }
    }, []);
    // get zone list when famrId changes in any form
    useEffect(() => {
        if (farmState.currentFarmData.farm._id) {
            getZones();
        }
    }, [farmState.currentFarmData.farm._id])


    //update routes list when zones are loaded/updated
    useEffect(() => {
        console.log(farmState)
        dispatchDashboardState({
            type: 'SET_ZONE_ROUTES',
            payload: farmState.currentFarmData.zones
        })
        dispatchDashboardState({
            type: 'SET_FARM_DATA',
            payload: farmState.currentFarmData.farm
        })
        console.log(dashboardState)
    }, [farmState])





    return (
        <div className="container-fluid">
            <NavbarVertical />
            <div className={classNames('content', { 'pb-0': false })}>
                <NavbarTop />
                <Switch>
                    {/* Redirect to zone 1   */}
                    {dashboardState.zoneRoutes[0].children.length > 0 ? <Redirect exact from="/dashboard" to={dashboardState.zoneRoutes[0].children[0].to} /> : null}
                    {farmState.currentFarmData.zones.map((val) => {
                        return (
                            <Route path={`/dashboard/${val.name.trim()}/farm-management`} exact key={val._id}>
                                <MyFarm zone={val} updateZoneInfo={getZones} />
                            </Route>
                        )
                    })
                    }
                    <DashboardRoutes />
                </Switch>
            </div>
        </div>
    )
}

export default DashboardLayout
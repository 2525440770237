import React, { useState, useContext } from 'react'
import { Button, Form, Row, Col, Spinner, Image } from 'react-bootstrap';
import Section from '../../Components/common/Section';
import logo from 'assets/img/illustrations/4Climate_Logo.svg';
import { registerSubmit } from 'API/Authentication';
import AuthContext from 'Context/AuthContext';
import { useHistory } from 'react-router-dom';
import { state } from 'helpers/StateCity';

const Register = () => {
  let hasLabel = true;

  const { authDispatch } = useContext(AuthContext);
  const history = useHistory();

  const [formData, setFormData] = useState({
    name: "",
    password: "",
    confirmPassword: "",
    email: "",
    address: "",
    country: "India",
    city: "",
    state: "",
    phone: "",
    whatsappContact: "",
    companyName: "",
    gstin: "",
    zipCode: "",
  })


  const [errors, setErrors] = useState('')
  const [passError, setPassError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault();

    setPassError(false)
    setIsLoading(true);
    const resData = await registerSubmit({
      name: formData.name,
      email: formData.email,
      password: formData.password,
      address: formData.address,
      city: formData.city,
      country: formData.country,
      state: formData.state,
      whatsapp: formData.whatsappContact,
      zip: formData.zipCode,
      GSTIN: formData.gstin,
      legal_name: formData.companyName,
      phone: formData.phone,
    });
    setIsLoading(false);
    if (resData.status == "success") {
      setErrors('');
      authDispatch({
        type: "LOGIN_SUCCESS",
        payload: {
          accessToken: resData.data.access_token,
          username: resData.data.user_name,
        }
      })
      history.push('/type-selection')

    }
    else if (resData.status == "error") {
      setErrors(resData.data);
    }

  }

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };



  return (
    <Section className="py-0">
      <Row className="justify-content-center pt-6">
        <Col sm={10} lg={7} className="col-xxl-5 text-center">
          <Image
            className="mb-3"
            src={logo}
            width="200em"
          />
        </Col>
      </Row>
      <div className="p-4  flex-grow-1">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            {hasLabel && <Form.Label>Name</Form.Label>}
            <Form.Control
              placeholder={!hasLabel ? 'Name' : ''}
              value={formData.name}
              name="name"
              onChange={handleFieldChange}
              type="text"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            {hasLabel && <Form.Label>Email address</Form.Label>}
            <Form.Control
              placeholder={!hasLabel ? 'Email address' : ''}
              value={formData.email}
              name="email"
              onChange={handleFieldChange}
              type="email"
              required
            />
          </Form.Group>
          <Row className="felx-wrap">
            <Col xs="12" md="6">
              <Form.Group className="mb-3">
                {hasLabel && <Form.Label>Password</Form.Label>}
                <Form.Control
                  placeholder={!hasLabel ? 'Password' : ''}
                  value={formData.password}
                  name="password"
                  onChange={handleFieldChange}
                  type="password"
                  required
                  isInvalid={formData.password.length < 8}
                />
                <Form.Control.Feedback type='invalid'>
                  password should have atleast 8 characters
                </Form.Control.Feedback>
              </Form.Group>

            </Col>
            <Col xs="12" md="6">
              <Form.Group className="mb-3">
                {hasLabel && <Form.Label>Confirm Password</Form.Label>}
                <Form.Control
                  placeholder={!hasLabel ? 'Password' : ''}
                  value={formData.confirmPassword}
                  name="confirmPassword"
                  onChange={handleFieldChange}
                  type="password"
                  required
                  isInvalid={formData.password !== formData.confirmPassword}
                />
                <Form.Control.Feedback type='invalid'>
                  "password should match"
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3">
            {hasLabel && <Form.Label>Address</Form.Label>}
            <Form.Control
              placeholder={!hasLabel ? 'Address' : ''}
              value={formData.address}
              name="address"
              onChange={handleFieldChange}
              type="text"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            {hasLabel && <Form.Label>City</Form.Label>}
            <Form.Control
              placeholder={!hasLabel ? 'City' : ''}
              value={formData.city}
              name="city"
              onChange={handleFieldChange}
              type="text"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            {hasLabel && <Form.Label>State</Form.Label>}
            <Form.Select
              placeholder={!hasLabel ? 'State' : ''}
              value={formData.state}
              name="state"
              onChange={handleFieldChange}
              type="text"
              required
            // isInvalid={!formData.state}
            >
              <option value="">Select your State</option>
              {state.map((val) => {
                //console.log(val);
                return (
                  <option value={val} key={val}>{val}</option>
                )
              })}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            {hasLabel && <Form.Label>Country</Form.Label>}
            <Form.Select
              placeholder={!hasLabel ? 'Country' : ''}
              value={formData.country}
              name="country"
              onChange={handleFieldChange}
              type="text"
              required
            >
              <option value=''>Select your Country</option>
              <option value='India'>India</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            {hasLabel && <Form.Label>Zip Code</Form.Label>}
            <Form.Control
              placeholder={!hasLabel ? 'zipcode' : ''}
              value={formData.zipCode}
              name="zipCode"
              onChange={handleFieldChange}
              type="text"
              required
              isInvalid={formData.zipCode.length !== 6 && formData.zipCode}
            />
            <Form.Control.Feedback type='invalid'>
             Zip should have 6 characters
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            {hasLabel && <Form.Label>Phone</Form.Label>}
            <Form.Control
              placeholder={!hasLabel ? 'Phone' : ''}
              value={formData.phone}
              name="phone"
              onChange={handleFieldChange}
              type="text"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            {hasLabel && <Form.Label>Whatsapp Contact</Form.Label>}
            <Form.Control
              placeholder={!hasLabel ? 'Whatsapp Contact' : ''}
              value={formData.whatsappContact}
              name="whatsappContact"
              onChange={handleFieldChange}
              type="text"
              required
              isInvalid={formData.whatsappContact.length !== 10 && formData.whatsappContact}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            {hasLabel && <Form.Label>Legal name of Company (if registered)</Form.Label>}
            <Form.Control
              placeholder={!hasLabel ? 'company name' : ''}
              value={formData.companyName}
              name="companyName"
              onChange={handleFieldChange}
              type="text"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            {hasLabel && <Form.Label>GSTIN</Form.Label>}
            <Form.Control
              placeholder={!hasLabel ? 'GSTIN' : ''}
              value={formData.gstin}
              name="gstin"
              onChange={handleFieldChange}
              type="text"
            />
          </Form.Group>


          <Row>
            <p className="fs--1 mb-0 text-danger">
              {errors ? errors : ''}
            </p>
          </Row>

          <Form.Group >
            {
              <Button
                type="submit"
                color="primary"
                className="mt-3 w-100"
                disabled={isLoading}
              >
                {!isLoading ? 'Register' : <Spinner animation="border" />}
              </Button>
            }
          </Form.Group>
        </Form>
      </div>
    </Section>

  )
}

export default Register
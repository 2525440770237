import { removeDevice } from 'API/hydroponicsFarmsAPI';
import AuthContext from 'Context/AuthContext';
import DashboardContext from 'Context/DashboardContext';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  FormControl,
  FormLabel,
} from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { RelayConfigContext } from '.';
import RmvDeviceMode from './RmvDeviceModel';


const RemoveDeviceForm = (props,{ stateChanger, ...rest }) => {
  const [productID, setProductID] = useState('');

  const { dashboardState } = useContext(DashboardContext);
  console.log(dashboardState,'asydgguasydgygdagsdukashdasdjasldjilj')
  const zoneData = useContext(RelayConfigContext);
  const { authState } = useContext(AuthContext);
  const history = useHistory();

  const [productDetails, setProductDetails] = useState({});
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  console.log(zoneData,'oneeeedatqa')
  const [formData, setFormData] = useState({
    farmId: dashboardState.farmDetails._id
      ? dashboardState.farmDetails._id
      : '',
    zoneId: zoneData.zoneData.zoneId ? zoneData.zoneData.zoneId : '',
    deviceId: props.RelayDeviceId
      ?props.RelayDeviceId
      : '',
  });
  
 console.log(props);
  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setModal(true);
  };
  function reload(){
    history.go(0);
  }

  const onDeleteDevice = async () => {
    console.log('delte requestr', formData);

    const resData = await removeDevice(authState.accessToken, formData);
    if (resData.success) {
      setModal(false);
      history.push('/dashboard/'+dashboardState.activeZone.name.trim()+'/farm-management');
      setTimeout( reload , 100)
    
    }
    if (resData.error) {
      setModal(false);
      console.log('error', resData.error);
    }
  };

  useEffect(() => {
    setFormData((prevState) => {
      return {
        ...prevState,
        farmId: dashboardState.farmDetails._id
          ? dashboardState.farmDetails._id
          : '',
        zoneId: zoneData.zoneData.zoneId ? zoneData.zoneData.zoneId : '',
        deviceId: props.RelayDeviceId
      ?props.RelayDeviceId:'',
      };
    });
  }, [zoneData.zoneData.productDetails, dashboardState, zoneData]);

  useEffect(() => {
    setProductDetails(zoneData.zoneData.productDetails);
  }, [zoneData.zoneData.productDetails]);

  useEffect(() => {
    if (!modal) {
      setIsLoading(false);
    }
  }, [modal]);

  return (
    <>
    <RmvDeviceMode modal={modal} setModal={setModal} deviceId={props.RelayDeviceId} deleteDevice={onDeleteDevice} />
      <Card className='bg-light my-3'>
        <Card.Body className='p-3 position-relative'>
          <Form className='d-flex flex-row' onSubmit={onSubmit}>
            <Form.Text className='text-nowrap me-2 mt-1 text-danger d-none d-sm-block '>
              <strong className='fs-0'>Remove Device</strong>
            </Form.Text>
            <FormControl
              className='fs--1'
              placeholder='Enter the Product ID'
              aria-label='Product ID'
              aria-describedby='basic-addon2'
              value={props. RelayDeviceId}
            />
            <Button className='btn btn-danger' id='button-addon2' type='submit'>
              <span className='d-none d-sm-block'>Confirm</span>
              <span className='d-sm-none d-block'>Remove</span>
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default RemoveDeviceForm;

import React, { useState, useContext, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap';
import BackTop from '../BackTop';
import BackButton from '../FarmEnvironmentConfigPage/BackButton'
import ZoneAnalyticsIntro from './ZoneAnalyticsIntro';
import ExportButton from './ExportButton';
import ExportSection from './greetings/ExportSection';
import MonitoringParamaters from './MonitoringParamaters/MonitoringParamaters';
import { useLocation } from "react-router-dom";
import { createContext } from 'react';
import climatedata from 'Components/Others/climatedata';
import { apiRoutes } from 'API/apiRoutes';
import { round } from 'mathjs';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'

const GraphContext = createContext();
const options = {
    // you can also just use 'bottom center'
    position: positions.BOTTOM_CENTER,
    timeout: 2000,
    offset: '30px',
    // you can also just use 'scale'
    transition: transitions.SCALE
}
const FarmAnalytics = () => {

    const zoneContextData = useLocation();
    // console.log(zoneContextData);

    const [data, setData] = useState({
        zoneData: zoneContextData.state.params,
        deviceValues: [0, 0, 0, 0],
        deviceStatus: 0
    });

    useEffect(() => {
        setData((preValue) => {
            return {
                ...preValue,
                zoneData: zoneContextData.state.params,
            }
        });
    }, [zoneContextData.state.params]);

    useEffect(() => {
        if (data.zoneData.productDetails.id) {
            const ws = new WebSocket(`${apiRoutes.webSocket.path}/${data.zoneData.productDetails.id}`);
            ws.onopen = (event) => {
                console.log(event, "ON OPEN")
            };
            ws.onmessage = function (event) {
                const json = JSON.parse(event.data);
                console.log(json, "ws3")
                try {
                    setData((prevState) => {
                        return {
                            ...prevState,
                            deviceValues: [round(json.data.vpd, 2), round(json.data.dew, 2), round(json.data.temp, 2), round(json.data.hum, 2)],
                            status: json.status
                        }
                    })
                    // console.log(json.data, json.status);

                } catch (err) {
                    console.log(err);
                }
            };
            //clean up function
            return () => ws.close();
        }
    }, [data.zoneData.productDetails.id])

    return (
        <>
            <BackTop />
            <AlertProvider template={AlertTemplate} {...options}>

                <GraphContext.Provider value={data}>
                    <Row className="g-3 mb-3">
                        <Col xxl={12}>
                            <BackButton />
                            <ZoneAnalyticsIntro />
                            <MonitoringParamaters />
                        </Col>
                    </Row>
                    <Row className="g-3 mb-0">
                        <Col xxl={12}>
                            <ExportSection />
                        </Col>
                    </Row>
                </GraphContext.Provider>
            </AlertProvider>
        </>
    )
}

export default FarmAnalytics
export { GraphContext };

import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { CardGroup, Card, Col, Form, Row, Spinner, Button } from 'react-bootstrap';
import logo from 'assets/img/illustrations/4Climate_Logo.svg';
import FarmCard from '../../Components/Others/FarmCard';
import { Image } from 'react-bootstrap';
import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import bg2 from 'assets/img/icons/spot-illustrations/corner-2.png';
import FarmContext from 'Context/FarmContext';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { getfarmsHydroponics } from 'API/hydroponicsFarmsAPI';
import AuthContext from 'Context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSignOutAlt, faArrowLeft, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'
// import { faSign } from '@fortawesome/free-regular-svg-icons'
import { getItemFromStore } from 'helpers/utils';
import bgShape from 'assets/img/illustrations/bg-shape.png';
import shape1 from 'assets/img/illustrations/shape-1.png';
import { BackTop } from 'antd';


const FarmSelection = () => {
  const { farmState, dispatchFarmState } = useContext(FarmContext);
  const { authState } = useContext(AuthContext);

  const history = useHistory();


  const addNewfarm = () => {
    history.push('/addFarm');
  }

  const backHome = () => {
    history.push('/type-selection');
  }

  const logout = () => {
    history.push('/auth/logout');
  }

  useEffect(() => {
    if (farmState.farmType === '') {
      if (getItemFromStore('farmState') && getItemFromStore('farmState').farmType) {
        dispatchFarmState({
          type: 'UPDATE_FARM_STATE',
          payload: getItemFromStore('farmState')
        })
      }
      else {
        history.push('/type-selection');
      }
    }

  }, [])


console.log("load ",farmState);

  useEffect(() => {
    let response = getfarmsHydroponics(authState.accessToken, dispatchFarmState);
    if (response.error === 'Not authenticated') {
      history.push('/auth/logout');
    }
  }, [farmState.farmType])


  const farm_prop = { type: 'Hydroponics Farm', image: bg1, valueClassName: 'text-warning' };
  // const pond_prop = { type: 'Aquaculture Farm', image: bg2, valueClassName: 'text-info' };
  return (
    <>
    <BackTop></BackTop>
      <div className='container-fluid vh-100 '>
        <Row className='justify-content-center pt-6'>
          <Col sm={10} lg={7} className='col-xxl-5 text-center'>
            {/* <Logo width={45} textClass='fs-4' /> */}
            <Image
              className='mb-3'
              src={logo}
              width='200em'
            />
          </Col>
        </Row>
        <Row className='justify-content-start ms-2 me-2'>
          <Col>
            <Button variant='outline-primary' onClick={backHome}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
          </Col>
          <Col style={{zIndex:10}} >
            <Button style={{ float: 'right'}} variant='outline-primary' onClick={logout} title='Logout'>
              <FontAwesomeIcon icon={faSignOutAlt} />
            </Button>
          </Col>
        </Row>
        <img
          className='bg-auth-circle-shape'
          src={bgShape}
          alt=''
          width='600'
        />
        <img
          className='bg-auth-circle-shape-2'
          src={shape1}
          alt=''
          width='300'
        />

        <Row className='justify-content-start ms-2 me-2'>
          <Card className='bg-light my-3'>
            <Card.Body className='p-3 position-relative'>
              <Row className='align-items-center'>
                <Col className='pe-xl-8'>
                  <p className='fs-0 fw-bold mb-0 text-left'>
                    My Farms
                  </p>
                </Col>
                <Col className='pe-xl-3'>
                  <Button variant='outline-primary' style={{ float: 'right',fontSize:"0.8rem" }} className='px-1' onClick={addNewfarm}>
                    + Add New Farm
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Row>
        <Row className='g-3 mb-3 ms-2 me-2'>
          {
            farmState.isLoading ?
              (
                <Col sm={12} className='d-flex justify-content-center'>
                  <Spinner animation='border' variant='primary' />
                </Col>
              )
              :
              farmState.farmList.map(farm => (
                <Col key={farm._id} sm={4}>
                  <FarmCard stat={farm} meta={farm_prop} dispatchFarmState={dispatchFarmState} />
                </Col>
              ))

          }
        </Row>
        <Row className='justify-content-start ms-2 me-2'>
          <Card className='bg-light my-3'>
            <Card.Body className='p-3 position-relative'>
              <Row className='align-items-center'>
                <Col className='pe-xl-8'>
                  <p className='fs-0 fw-bold mb-0 text-left'>
                    Invited Farms
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Row>
        <Row className='g-3 mb-3 ms-2 me-2'>
          {
            farmState.isLoading ?
              (
                <Col sm={12} className='d-flex justify-content-center'>
                  <Spinner animation='border' variant='primary' />
                </Col>
              )
              :
              farmState.invitedFarmList.map(farm => (
                <Col key={farm._id} sm={4}>
                  <FarmCard stat={farm} meta={farm_prop} dispatchFarmState={dispatchFarmState} />
                </Col>
              ))

          }
        </Row>
      </div>
    </>
  );
};

export default FarmSelection;

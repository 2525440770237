import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button,Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";
const BackButton = () => {
  const history = useHistory();

  const routeChange = (e) =>{ 
    e.preventDefault();
    history.goBack();
    // let path = `/dashboard/`; 
    // history.push(path);
  }
  return (

    <Card className="bg-light my-3">
      <Card.Body className="p-0 position-relative">
      <Button className="btn btn-primary text-nowrap w-100" id="button-addon2" type="submit" onClick={routeChange}>
      <FontAwesomeIcon
                    icon="chevron-left"
                    className="me-2"
                    />
      Back to farm environment dashboard        
                </Button>
      </Card.Body>
    </Card>
  );
};

export default BackButton;

import React, { useContext } from 'react';
import { CardGroup, Card, Col, Form, Row, Button } from 'react-bootstrap';
import logo from 'assets/img/illustrations/4Climate_Logo.svg';
import Aquaculture from 'assets/img/farm-choose/Aquaculture.jpg';
import Hydroponics from 'assets/img/farm-choose/Hydroponics.jpg';
import Smart_Farming from 'assets/img/farm-choose/Smart_Farming.jpg';
import { Image } from 'react-bootstrap';
import FarmContext from 'Context/FarmContext';
import { useHistory } from 'react-router-dom';
import Background from 'Components/common/Background';
import bgShape from 'assets/img/illustrations/bg-shape.png';
import shape1 from 'assets/img/illustrations/shape-1.png';

const FarmTypeSelection = () => {
  const { farmState, dispatchFarmState } = useContext(FarmContext);
  const history = useHistory();

  const handleFarmSelect = (farmtype) => {
    dispatchFarmState({
      type: 'SET_FARM_TYPE',
      payload: {
        farmType: farmtype
      }
    });
    history.push('/farm-selection');
  }

  return (
    <div className='overflow-hidden vh-100'>
      <Row className="justify-content-center pt-6">
        <Col sm={10} lg={7} className="col-xxl-5 text-center">
          <Image
            className="mb-3"
            src={logo}
            width="200em"
          />
        </Col>
      </Row>

      <div className='container mt-sm-5 mt-3'>
        <img
          className="bg-auth-circle-shape overflow-x-hidden"
          src={bgShape}
          alt=""
          width="250"
        />
        <img
          className="bg-auth-circle-shape-2"
          src={shape1}
          alt=""
          width="150"
        />
        <Card className="bg-light px-2 pb-3 pt-3 fs--1">
          <Row className="gx-0 gy-1 text-center">
            <Col className='col-sm-4 col-12 mb-sm-0 mb-4'>
              <Card.Body className="bg-light px-1 pb-1 pt-0 fs--1">
                <Image
                  thumbnail
                  fluid
                  className="mb-3 shadow-sm"
                  src={Hydroponics}
                  width={400}
                />
                <Row className="gx-0 gy-1 text-center">
                  <Button onClick={(e) => {
                    e.preventDefault();
                    handleFarmSelect('hydroponics')
                  }}>Hydroponics</Button>
                </Row>
              </Card.Body>
            </Col>

            <Col className='col-sm-4 col-12 mb-sm-0 mb-4'>
              <Card.Body className="bg-light px-1 pb-1 pt-0 fs--1">
                <Image
                  thumbnail
                  fluid
                  className="mb-3 shadow-sm"
                  src={Aquaculture}
                  width={400}
                />
                <Row className="gx-0 gy-1 text-center">
                  <Button disabled onClick={(e) => {
                    e.preventDefault();
                    handleFarmSelect('aquaculture')
                  }}>Aquaculture</Button>
                </Row>
              </Card.Body>
            </Col>

            <Col className='col-sm-4 col-12 mb-sm-0 mb-4'>
              <Card.Body className="bg-light px-1 pb-1 pt-0 fs--1">
                <Image
                  thumbnail
                  fluid
                  className="mb-3 shadow-sm"
                  src={Smart_Farming}
                  width={400}
                />
                <Row className="gx-0 gy-1 text-center">
                  <Button disabled onClick={(e) => {
                    e.preventDefault();
                    handleFarmSelect('smart_farming')
                  }}>Smart Farming</Button>
                </Row>
              </Card.Body>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
};

export default FarmTypeSelection;

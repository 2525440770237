import React from 'react'
import { Button,Col, Row } from 'react-bootstrap';
const ZoneTab = ({typeChanger, ...rest}) => {

    const farmEvent = () =>{
        // typeChanger({
        //     zone: 1,
        //     type: 1,
        //     name: 'Zone-1',
        //     farmproductid: {},
        //     lightproductid: '',
        //     coproductid: '',
        //     switches: []
        // });
        // typeChanger.type("farm-management");
    }

    const nutrientEvent = () =>{
        // typeChanger.type("nutrient-management");

        // typeChanger({
        //     zone: 1,
        //     type: 2,
        //     name: 'Zone-1',
        //     farmproductid: {},
        //     lightproductid: '',
        //     coproductid: '',
        //     switches: []
        // });
    }

    return (
        <>
            <Row className=" align-items-sm-center">
                <Col className="align-items-center btn-group " role="group">               
                    <Button className="fs-sm-0 fs--1 btn btn-primary" onClick={farmEvent}>Farm Management</Button>
                    <Button className="fs-sm-0 fs--1" variant="falcon-primary" onClick={nutrientEvent}>Nutrient Management</Button>
                </Col>
            </Row>  
        </>
    )
}

export default ZoneTab

import AdvanceTable from 'Components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'Components/common/advance-table/AdvanceTableWrapper';
import Avatar from 'Components/common/Avatar';
import avatarImg from 'assets/img/team/avatar.png';
import CardDropdown from './CardDropdown';
import FalconCardFooterLink from 'Components/common/FalconCardFooterLink';
import FalconCardHeader from 'Components/common/FalconCardHeader';
import Flex from 'Components/common/Flex';
import IconItem from 'Components/common/icon/IconItem';
import SoftBadge from 'Components/common/SoftBadge';
import React, { useState, useContext, useEffect } from 'react';
import { Card, Col, Form, Image, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DashboardContext from 'Context/DashboardContext'
import AuthContext from 'Context/AuthContext';
import { inviteUser, uninviteUser } from 'API/hydroponicsFarmsAPI'
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const getColumns = (handleDelete, canUnInvite) => {
  return [
    {
      accessor: 'name',
      Header: 'Name',
      headerProps: {
        className: 'py-3'
      },
      Cell: rowData => {
        return (
          <Link to="#!">
            <Flex alignItems="center">
              <Avatar size="xl" src={avatarImg} />
              <h6 className="mb-0 ps-2 text-800">{rowData.row.original.name}</h6>
            </Flex>
          </Link>
        );
      }
    },
    {
      accessor: 'email',
      Header: 'Email',
      Cell: rowData => {
        return (
          <a href={`mailto:${rowData.row.original.email}`} className="mb-0">
            {rowData.row.original.email}
          </a>
        );
      }
    },
    // {
    //   accessor: 'roles',
    //   Header: 'Roles',
    //   headerProps: {
    //       className: 'px-5'
    //     },
    //   Cell: rowData => {
    //       return (
    //       // <SoftBadge pill bg={rowData.row.original.variant} className="me-2">
    //       //   {rowData.row.original.status}
    //       // </SoftBadge>
    //       <Form.Select size="sm" className="px-2 bg-transparent rounded-pill w-sm-50">
    //         <option value="Owner">Owner</option>
    //         <option value="Read">Read</option>
    //         <option value="Manager">Manager</option>
    //         <option value="Agronomist">Agronomist</option>
    //       </Form.Select>
    //     );
    //   }
    // },
    //  {
    //   accessor: 'status',
    //   Header: 'Invite Status',
    //   Cell: rowData => {
    //     return (
    //       <SoftBadge pill bg={rowData.row.original.variant} className="me-2">
    //         {rowData.row.original.status}
    //       </SoftBadge>
    //     );
    //   }
    // },
    {
      accessor: 'Action',
      Header: 'Action',
      headerProps: {
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end'
      },
      disableSortBy: true,
      Cell: rowData => {

        return (
          <IconItem
            tag="button"
            icon={faTrash}
            onClick={(e) => { e.preventDefault(); handleDelete(rowData.row.original.email) }}
            size="sm"
            className="btn rounded-3 me-2 fs--2 text-warning"
            disabled = {!canUnInvite}
            style={{ float: 'right' }}
          />
        )

      }
    }
  ];
}

const Roles = ({ invitedUserData, canUnInvite, getInvitedUsersData }) => {
  const { authState } = useContext(AuthContext);
  const { dashboardState } = useContext(DashboardContext);
  const [formData, setFormData] = useState({
    farmId: dashboardState.farmDetails._id ? dashboardState.farmDetails._id : '',
    owner_email: authState.email ? authState.email : '',
    invitee_email: '',
    farm_name: dashboardState.farmDetails.name ? dashboardState.farmDetails.name : '',
  })

  useEffect(() => {
    setFormData((prevState) => {
      return {
        ...prevState,
        farmId: dashboardState.farmDetails._id ? dashboardState.farmDetails._id : '',
        farm_name: dashboardState.farmDetails.name ? dashboardState.farmDetails.name : '',
      }
    })

  }, [dashboardState])

  const handleUnInvite = async (email) => {
    console.log('uninvite', email)
    console.log(formData);
    setFormData((prevState) => {
      return {
        ...prevState,
        invitee_email: email
      }
    })
    const resData = await uninviteUser(authState.accessToken, {...formData, invitee_email: email});
    console.log(resData)
    if (resData.success) {
      console.log('Invitation removed successfully');
      setFormData({
        ...formData,
        invitee_email: ''
      })
      getInvitedUsersData();
    } else {
      console.log(resData.error);
    }
  }


  return (
    <AdvanceTableWrapper
      columns={getColumns(handleUnInvite, canUnInvite)}
      data={invitedUserData}
      sortable
      pagination
      perPage={10}
    >
      <Card>
        <FalconCardHeader
          title="Invited Users"
          titleTag="h6"
          className="py-2"
        />
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        {/* <Card.Footer className="p-0">
          <FalconCardFooterLink title="Show full list" size="sm" />
        </Card.Footer> */}
      </Card>
    </AdvanceTableWrapper>
  );
};

export default Roles;

import axios from 'axios';
import { apiRoutes } from './apiRoutes';

//get user profile data
export const getUserProfile = async (token) => {
    try {
        let response = await axios.get(apiRoutes.user.getUserProfile.path, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        console.log(response.data);
        return { success: true, data: response.data }
    }
    catch (err) {
        return { error: err.response.data.detail }
    }
}


//update user profile data
export const updateUserProfile = async (token, data) => {
    console.log(data)
    let { GSTIN, address, city, country, email, legal_name, name, phone, state, whatsapp, zip } = data
    try {
        let response = await axios.put(apiRoutes.user.updateUserProfile.path, {
            GSTIN, address, city, country, email, legal_name, name, phone, state, whatsapp, zip
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        console.log(response.data);
        return { success: true }
    }
    catch (err) {
        return { error: err.response.data.detail }
    }
}

//Send OTP forget password
export const sendOTPForgetPassword = async (email) => {
    try {
        let response = await axios.post(`${apiRoutes.forgetPasswordOTP.path}?email=${email}`);
        console.log(response.data);
        return { success: true }
    }
    catch (err) {
        return { error: err.response ? err.response.data.detail : "Something went wrong" }
    }
}

//Confirm Password reset
export const confirmPasswordReset = async (data) => {
    const { email, password, otp } = data
    try {
        let response = await axios.post(`${apiRoutes.forgetPasswordConfirm.path}?email=${email}&otp=${otp}&password=${password}`);
        console.log(response.data);
        return { success: true }
    }
    catch (err) {
        return { error: err.response ? err.response.data.detail : "Something went wrong" }
    }

}

//Change password
export const changePassword = async (token, data) => {
    const { oldPassword, newPassword } = data
    try {

        let response = await axios.put(`${apiRoutes.user.changePassword.path}?old_password=${oldPassword}&new_password=${newPassword}`, {}, 
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        console.log(response.data);
        return { success: true }
    }
    catch (err) {
        console.log(err.response)
        return { error: err.response ? err.response.data.detail : "Something went wrong" }
    }
}
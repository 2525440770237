import team1 from 'assets/img/team/1-thumb.png';
import team2 from 'assets/img/team/2-thumb.png';
import team3 from 'assets/img/team/3-thumb.png';
import team4 from 'assets/img/team/4-thumb.png';
import team5 from 'assets/img/team/5-thumb.png';

export const FarmZonesData = [
    {
      zone: "Zone 1",
      img: team1,
      stage: 'Germination',
      status: 'Active',
      variant: 'success'
    },
    {
      zone: "Zone 2",
      img: team2,
      stage: 'Vegetative',
      status: 'Active',
      variant: 'success'
    },
    {
      zone: "Zone 3",
      img: team3,
      stage: 'Vegetative',
      status: 'Offline',
      variant: 'warning'
    },
    {
      zone: "Zone 4",
      img: team4,
      stage: 'pratt@mail.ru',
      status: 'Offline',
      variant: 'warning'
    },
    {
      zone: "Zone 5",
      img: team5,
      stage: 'Flowering',
      status: 'Active',
      variant: 'success'
    }
  ];
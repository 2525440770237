import React, { useEffect, useState, useContext } from 'react';
import FarmSettingsBanner from '../FarmSettingsBanner';
import coverSrc from 'assets/img/generic/4.jpg';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import FalconCardHeader from 'Components/common/FalconCardHeader';
import { state } from '../profile/StateCity';
import BackTop from '../../BackTop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BackButton from './BackButton';
import { useHistory, useLocation } from 'react-router-dom';
import { updateFarmDetails, deleteFarm } from 'API/hydroponicsFarmsAPI';
import AuthContext from 'Context/AuthContext';
import FarmDeleteModal from './FarmDeleteModal';


const FarmDetails = () => {
  let farmConfigData = useLocation()
  const { authState } = useContext(AuthContext);
  const history = useHistory();
  if(!farmConfigData.state){
    history.push('/farm-selection');
  }
  farmConfigData = farmConfigData.state.params;

  const [deleteModal, setDeleteModal] = useState(false);


  const [formData, setFormData] = useState({
    farmId: farmConfigData.farmId ? farmConfigData.farmId : '',
    farm_type: 'hydroponics', //default hard coded
    farm_name: farmConfigData.name ? farmConfigData.name : '',
    farm_size: farmConfigData.size ? farmConfigData.size : '',
    farm_unit: 'Sqft',
    plant_capacity: farmConfigData.capacity ? farmConfigData.capacity : '',
    farm_address: farmConfigData.address ? farmConfigData.address : '',
    farm_city: farmConfigData.city ? farmConfigData.city : '',
    farm_state: farmConfigData.state ? farmConfigData.state : '',
    farm_zip: farmConfigData.zip ? farmConfigData.zip : '',
    farm_country: farmConfigData.country ? farmConfigData.country : '',
  });

  useEffect(() => {
    console.log("farmConfigData", farmConfigData)
    setFormData((prevState) => {
      return {
        ...prevState,
        farmId: farmConfigData.farmId ? farmConfigData.farmId : '',
        farm_name: farmConfigData.name ? farmConfigData.name : '',
        farm_size: farmConfigData.size ? farmConfigData.size : '',
        farm_unit: 'Sqft',
        plant_capacity: farmConfigData.capacity ? farmConfigData.capacity : '',
        farm_address: farmConfigData.address ? farmConfigData.address : '',
        farm_city: farmConfigData.city ? farmConfigData.city : '',
        farm_state: farmConfigData.state ? farmConfigData.state : '',
        farm_zip: farmConfigData.zip ? farmConfigData.zip : '',
        farm_country: farmConfigData.country ? farmConfigData.country : '',
      }
    })
    console.log("formData", formData)
  }, [farmConfigData])

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let resData = await updateFarmDetails(authState.accessToken, formData);
    if (resData.success) {
      console.log(resData);
      history.push('/dashboard/user/farm-settings')
      history.go(0)
    }
  };

  const handleDeleteClick = (e) => {
    e.preventDefault();
    setDeleteModal(true);
  }

  const handleFarmDelete = async () => {
    let resData = await deleteFarm(authState.accessToken, { farmId: formData.farmId });
    if (resData.success) {
      history.push('/farm-selection');
      history.go(0);
    }
  }


  return (
    <>
      <BackTop />
      <BackButton />
      <FarmSettingsBanner>
        <FarmSettingsBanner.Header
          coverSrc={coverSrc}
          className="mb-0"
        />
      </FarmSettingsBanner>
      <Card>
        <FalconCardHeader title="Farm Details" />
        <Card.Body className="bg-light">
          <Form onSubmit={handleSubmit}>


            <Form.Group className="mb-3" controlId="farm_type">
              <Form.Label>Farming</Form.Label>
              <Form.Select aria-label="Select Farm" placeholder='Select your Farm' value={formData.farm_type}
                name="farm_type"
                disabled
                onChange={handleChange}>
                <option>Select your Farm</option>
                <option value='hydroponics'>Hydroponics</option>
                <option value='aquaculture'>Aquaculture</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="farm_name">
              <Form.Label>Farm Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Farm Name"
                value={formData.farm_name}
                name="farm_name"
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={6} controlId="farm_size">
                <Form.Label>Farm Size</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Farm Size"
                  value={formData.farm_size}
                  name="farm_size"
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group as={Col} lg={6} controlId="unit">
                <Form.Label>Unit</Form.Label>
                <Form.Select aria-label="Select Farm" placeholder='Select your Unit' value={formData.farm_unit}
                  name="farm_unit"
                  disabled
                  onChange={handleChange}>
                  <option>Select Unit </option>
                  <option value='Sqft'>Sqft</option>
                  <option value='Sqm'>Sqm</option>
                </Form.Select>
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="plant_capacity">
              <Form.Label>Plant Capacity</Form.Label>
              <Form.Control
                type="number"
                placeholder="Plant Capacity"
                value={formData.plant_capacity}
                name="plant_capacity"
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="farm_address">
              <Form.Label>Farm Address</Form.Label>
              <Form.Control
                as="textarea"
                placeholder=""
                value={formData.farm_address}
                name="farm_address"
                rows={2}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={6} controlId="farm_city">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="City"
                  value={formData.farm_city}
                  name="farm_city"
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group as={Col} lg={6} controlId="farm_state">
                <Form.Label>State</Form.Label>
                <Form.Select aria-label="Select State" placeholder='Select your State' value={formData.farm_state}
                  name="farm_state"
                  required
                  onChange={handleChange}>
                  <option>Select your State</option>
                  {state.map((val) => {
                    //console.log(val);
                    return (
                      <option value={val} key={val}>{val}</option>
                    )
                  })}
                </Form.Select>
              </Form.Group>
            </Row>

            <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={6} controlId="farm_zip">
                <Form.Label>Zipcode</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Zipcode"
                  value={formData.farm_zip}
                  name="farm_zip"
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group as={Col} lg={6} controlId="state">
                <Form.Label>Country</Form.Label>
                <Form.Select aria-label="Select Country" placeholder='Select your Country' value={formData.farm_country}
                  name="farm_country"
                  required
                  onChange={handleChange}>
                  <option value=''>Select your Country</option>
                  <option value='India'>India</option>
                </Form.Select>
              </Form.Group>
            </Row>

            <Button className="w-100" variant="primary" type="submit" value="update">
              Save
            </Button>

            <Button className="w-100 mt-3" variant="danger" onClick={handleDeleteClick} >
              Delete Farm
            </Button>

          </Form>
        </Card.Body>
      </Card>

      <FarmDeleteModal modal={deleteModal} setModal={setDeleteModal} farmName={formData.farm_name} deleteFarm={handleFarmDelete} />
    </>
  );
};

export default FarmDetails;

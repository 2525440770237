import React, { useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import SetupRoutes from './Routes/SetupRoutes';
import AuthContext from 'Context/AuthContext';
import LoadingPage from 'Components/Others/LoadingPage';

const App = () => {
  let { authState } = useContext(AuthContext);

    

  return (
    !authState.isLoading ? 
    <Router>
      <SetupRoutes />
    </Router>
    :
    <LoadingPage />
  );
};

export default App;

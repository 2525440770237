import React from 'react'
import { Container, Row, Spinner, Col } from 'react-bootstrap'

const LoadingPage = () => {
    return (
        <Container>
            <Row>
                <Col className="text-center">
                    <Spinner animation="border" variant="primary" />
                </Col>
            </Row>
        </Container>
    )
}

export default LoadingPage
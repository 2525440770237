import React, { useState, useContext } from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import image from 'assets/img/icons/spot-illustrations/navbar-vertical.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";
import { GlobalContext } from 'Context/GlobalContext';


const AddZoneButton = () => {
  const history = useHistory();
  const {
    config: { showBurgerMenu },
    setConfig
  } = useContext(GlobalContext);
  const navigateRoute = (e) => {
    e.preventDefault();
    history.push('/dashboard/addZone');
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  }

  return (
      <div className="settings my-3">
        <Card className="p-0 rounded-2 position-relative">
          <Card.Body className="text-center">
            <p className="fs--2 mt-0">
              Need multiple zones for your farm?
            </p>
            <div className="d-grid gap-2">
              <Button
                onClick={navigateRoute}
                size="sm"
                className="btn-purchase"
              ><FontAwesomeIcon icon="plus" className="fs--2 me-1" />
                Add Zone
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
    )
};

export default AddZoneButton;

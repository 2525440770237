import React, { useContext, useEffect } from 'react'
import { Form, Row, Col, Card, Button } from 'react-bootstrap'
import FalconCardHeader from 'Components/common/FalconCardHeader';
import { addZone } from 'API/hydroponicsFarmsAPI';
import AuthContext from 'Context/AuthContext';
import DashboardContext from 'Context/DashboardContext';
import FarmContext from 'Context/FarmContext';
import { getItemFromStore } from 'helpers/utils';
import { useHistory } from 'react-router-dom';
import { timeList } from 'helpers/timeList';

const AddZone = () => {
    const { authState } = useContext(AuthContext)
    const { farmState, dispatchFarmState } = useContext(FarmContext)
    const { DashboardState } = useContext(DashboardContext)
    const [formData, setFormData] = React.useState({
        zone_name: '',
        zone_stage: '',
        farm_id: '',
        sunrise: '',
        sunset: ''
    })
    const history = useHistory();



    useEffect(() => {
        if (!farmState.currentFarmData.farm._id) {
            if (getItemFromStore('farmState').currentFarmData.farm._id) {
                dispatchFarmState({
                    type: 'UPDATE_FARM_STATE',
                    payload: getItemFromStore('farmState')
                })
            }
            else {
                console.log("No farm selected")
                history.push('/dashboard');
            }
        }
    }, []);
    // get zone list when famrId changes in any form
    useEffect(() => {
        if (farmState.currentFarmData.farm._id) {
            setFormData({
                ...formData,
                farm_id: farmState.currentFarmData.farm._id
            })
        }
    }, [farmState.currentFarmData.farm._id])



    const handleFieldChange = e => {
        let value = e.target.value;
        // if (e.target.name === 'sunrise' || e.target.name === 'sunset') {
        //     value = String(parseInt(value))
        //     if (value > 23) {
        //         value = 0
        //     }
        //     else if (value < 0) {
        //         value = 23
        //     }
        // }
        setFormData({
            ...formData,
            [e.target.name]: value
        });
    };

    const submitData = async (e) => {
        e.preventDefault();
        console.log("submit", formData)
        let resData = await addZone(authState.accessToken, formData)
        console.log(resData)
        if (resData.success) {
            history.push('/dashboard')
            history.go()
        }
    }

    return (
        <div className='container flex'>
            {/* <BackButton /> */}

            <Card
                as={Form}
                onSubmit={submitData}>
                <FalconCardHeader title="Add New Zone" />
                <Card.Body>
                    <Form.Group className='mb-3'>
                        <Form.Label>Enter Zone Name</Form.Label>
                        <Form.Control required value={formData.zone_name} name="zone_name" onChange={handleFieldChange} />
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label>Select Zone Stage</Form.Label>
                        <Form.Select required value={formData.zone_stage} name="zone_stage" onChange={handleFieldChange}>
                            <option value="" key="0">
                                Select Zone Stage
                            </option>
                            <option value="seedling" key="1">
                                Seedling
                            </option>
                            <option value="vegetative" key="2">
                                Vegetative
                            </option>
                            <option value="flowering" key="3">
                                Flowering
                            </option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="sunrise">
                        <Form.Label>Sunrise Time</Form.Label>
                        <Form.Select
                            placeholder="sunrise Time"
                            value={formData.sunrise}
                            name="sunrise"
                            required
                            onChange={handleFieldChange}
                        >
                            <option value="">--Select--</option>
                            {timeList.map((time) => {
                                return (
                                    <option key={time.val} value={time.val}>{time.label}</option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="sunrise">
                        <Form.Label>Sunset Time</Form.Label>
                        <Form.Select
                            placeholder="sunset Time"
                            value={formData.sunset}
                            name="sunset"
                            onChange={handleFieldChange}
                            required
                        >
                            <option value="">--Select--</option>
                            {timeList.map((time) => {
                                return (
                                    <option key={time.val} value={time.val}>{time.label}</option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mt-3">
                        <Button variant="primary" type="submit" style={{ float: "right" }}>
                            Add
                        </Button>
                    </Form.Group>
                </Card.Body>
            </Card>
        </div>
    )
}

export default AddZone
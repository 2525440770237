import AuthContext from 'Context/AuthContext'
import React, { useEffect } from 'react'
import { useContext } from 'react'

const Logout = () => {
    const { authState, authDispatch } = useContext(AuthContext)
    useEffect(() => {
        authDispatch({ type: 'LOGOUT' })

    }, [])
    return (
        <div></div>
    )
}

export default Logout
import React, { useState } from 'react';

function Disabled({ disabled, children }) {
  if (disabled) {
    return (
      <div>
        {children}
      </div>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
}
export default Disabled;


export const dashboardReducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case 'SET_FARM_DATA':
            return {
                ...state,
                farmDetails: payload
            }
        case 'SET_ZONE_ROUTES':
            let routes = []
            
            // let defaultRotue = ''
            payload.map(zone => {
                let children = {
                    name: zone.name,
                    to: `/dashboard/${zone.name.trim()}/farm-management`,
                    exact: false,
                    active: true

                }
                routes.push(children)
            })
            // routes.length > 0 ? defaultRotue = routes[0].to : defaultRotue = ''
            return {
                ...state,
                zoneRoutes: [
                    {
                        name: 'My Farm',
                        active: true,
                        icon: 'chart-pie',
                        children: routes
                    }
                ],
                zoneList: payload
            }
        case 'SET_ACTIVE_ZONE':
            return {
                ...state,
                activeZone: payload
            }
        case 'SET_ZONE_LIST':
            return {
                ...state,
                zoneList: payload
            }

        default:
            return state;
    }
}
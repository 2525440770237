import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AddFarmContext from 'Context/AddFarmContext';

const FarmSetupContextProvider = ({ children }) => {
  const [step, setStep] = useState(1);

  const value = { step, setStep };
  return (
    <AddFarmContext.Provider value={value}>
      {children}
    </AddFarmContext.Provider>
  );
};



export default FarmSetupContextProvider;

import React, { useEffect, useState, useContext } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import FalconCardHeader from 'Components/common/FalconCardHeader';
import { state as stateList } from './StateCity'
import BackTop from '../../BackTop';
import { updateUserProfile } from 'API/profileAPI';
import { useAlert } from 'react-alert';
import { useHistory } from 'react-router-dom';
import AuthContext from 'Context/AuthContext';

const ProfileSettings = ({ data }) => {
  const { GSTIN, address, city, country, email, legal_name, name, phone, state, whatsapp, zip } = data;
  const { authState } = useContext(AuthContext);
  const alert = useAlert();
  const history = useHistory();

  const [formData, setFormData] = useState({
    name: name ? name : "",
    email: email ? email : "",
    address: address ? address : "",
    city: city ? city : "",
    state: state ? state : "",
    zip: zip ? zip : "",
    country: country ? country : "",
    phone: phone ? phone : "",
    whatsapp: whatsapp ? whatsapp : "",
    legal_name: legal_name ? legal_name : "",
    GSTIN: GSTIN ? GSTIN : "",
  });


  useEffect(() => {
    setFormData((prevState) => {
      return {
        ...prevState,
        name: name ? name : "",
        email: email ? email : "",
        address: address ? address : "",
        city: city ? city : "",
        state: state ? state : "",
        zip: zip ? zip : "",
        country: country ? country : "",
        phone: phone ? phone : "",
        whatsapp: whatsapp ? whatsapp : "",
        legal_name: legal_name ? legal_name : "",
        GSTIN: GSTIN ? GSTIN : "",
      }
    })
  }, [data])

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let resData = await updateUserProfile(authState.accessToken,formData);
    if (resData.success) {
      console.log(resData);
      alert.success("Profile Updated Successfully!");
      // history.go(0)
    }
    if (resData.error) {
      console.log(resData.error);
      alert.error("Something went wrong!")
    }

  };


  return (
    <>
      <BackTop />
      <Card>
        <FalconCardHeader title="Profile Settings" />
        <Card.Body className="bg-light">
          <Form onSubmit={handleSubmit}>

            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                value={formData.name}
                name="name"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                value={formData.email}
                name="email"
                disabled
              // onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="address">
              <Form.Label>Address</Form.Label>
              <Form.Control
                as="textarea"
                placeholder=""
                value={formData.address}
                name="address"
                rows={2}
                onChange={handleChange}
              />
            </Form.Group>

            <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={6} controlId="city">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="City"
                  value={formData.city}
                  name="city"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} lg={6} controlId="state"
              >
                <Form.Label>State</Form.Label>
                <Form.Select aria-label="Select State" name="state"
                  onChange={handleChange} placeholder='Select your State' value={formData.state}>
                  <option>Select your State</option>
                  {stateList.map((val) => {
                    //console.log(val);
                    return (
                      <option value={val} key={val}>{val}</option>
                    )
                  })}
                </Form.Select>
              </Form.Group>
            </Row>

            <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={6} controlId="zip">
                <Form.Label>Zipcode</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Zipcode"
                  value={formData.zip}
                  name="zip"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} lg={6} controlId="country">
                <Form.Label>Country</Form.Label>
                <Form.Select aria-label="Select Country" placeholder='Select your Country' value={formData.country}
                  name="country"
                  onChange={handleChange}>
                  <option>Select your Country</option>
                  <option value='India'>India</option>
                </Form.Select>
              </Form.Group>
            </Row>


            <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={6} controlId="phone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Phone"
                  value={formData.phone}
                  name="phone"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} lg={6} controlId="phone">
                <Form.Label>Whatsapp Phone</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Whatsapp Phone"
                  value={formData.whatsapp}
                  name="whatsapp"
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="company_name">
              <Form.Label>Legal Name of Company (if registered)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Company Name"
                value={formData.legal_name}
                name="legal_name"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="gstin">
              <Form.Label>GSTIN</Form.Label>
              <Form.Control
                type="text"
                placeholder="GST Number"
                value={formData.GSTIN}
                name="GSTIN"
                onChange={handleChange}
              />
            </Form.Group>

            <Button className="w-100" variant="primary" type="submit">
              Update
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default ProfileSettings;

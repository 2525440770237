import React, { useState, useContext, useEffect } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import invite from 'assets/img/icons/spot-illustrations/41.png';
import gmail from 'assets/img/logos/gmail.png';
import { Link } from 'react-router-dom';
import IconButton from 'Components/common/IconButton';
import Lottie from 'react-lottie';
import animationData from './business-team.json';
import { useAlert } from 'react-alert'
import DashboardContext from 'Context/DashboardContext'
import AuthContext from 'Context/AuthContext';
import { inviteUser } from 'API/hydroponicsFarmsAPI'

const InvitePeople = ({ canInvite, getInvitedUsersData }) => {
  const alert = useAlert();
  const { authState } = useContext(AuthContext);
  const { dashboardState } = useContext(DashboardContext);
  const [formData, setFormData] = useState({
    farmId: dashboardState.farmDetails._id ? dashboardState.farmDetails._id : '',
    owner_email: authState.email ? authState.email : '',
    invitee_email: '',
    farm_name: dashboardState.farmDetails.name ? dashboardState.farmDetails.name : '',
  })


  useEffect(() => {

    setFormData((prevState) => {
      return {
        ...prevState,
        farmId: dashboardState.farmDetails._id ? dashboardState.farmDetails._id : '',
        farm_name: dashboardState.farmDetails.name ? dashboardState.farmDetails.name : '',
      }
    })
    
  }, [dashboardState])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidyMid slice'
    }
  };

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }

  const onSubmit = async(event) => {
    event.preventDefault();
    console.log(formData)
    const resData = await inviteUser(authState.accessToken, formData);
    console.log(resData)
    if (resData.success) {
      alert.success('Invitation sent successfully');
      setFormData({
        ...formData,
        invitee_email: ''
      })
      getInvitedUsersData();
    } else {
      alert.error(resData.error);
    }
    // alert.show('Invitation Successfully Sent', {
    //   type: 'success'
    // })
    //    alert('Invitation Successfully Sent');
    //console.log('h1');
  };

  return (
    <>
      <Card>
        <Card.Body className="text-center py-5">
          <Row className="justify-content-center">
            <Col xs={7} md={5}>
              <div className="wizard-lottie-wrapper d-flex justify-content-center">
                <div className="wizard-lottie">
                  <Lottie options={defaultOptions} height={300} width={350} />
                </div>
              </div>

            </Col>
          </Row>
          <h3 className="mt-3 fw-normal fs-1 mt-md-4 fs-md-3">
            Invite your team and start working together in seconds
          </h3>
          <p className="lead mb-5 fs-md-2 fs-1">
            Everyone you invite will receive a joining email.
          </p>
          <Row className="justify-content-center">
            <Col md={7}>
              <Form className='d-flex flex-row justify-content-center' onSubmit={onSubmit}>
                <Row className='w-100 justify-content-center'>
                  <Col sm>
                    <Form.Control
                      type="email"
                      placeholder="Email address"
                      aria-label="Recipient's username"
                      name="invitee_email"
                      value={formData.invitee_email}
                      onChange={handleChange}
                      required
                    />
                  </Col>
                  <Col sm="auto">
                    <Button disabled={!canInvite} variant="primary" className="d-block w-100 mt-sm-0 mt-3" type="submit">
                      Send Invitation
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default InvitePeople;

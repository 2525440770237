import axios from 'axios';
import { normalizeArray } from 'helpers/utils';
import { apiRoutes } from './apiRoutes';
import { round } from 'mathjs';
import moment from 'moment';

export const getfarmsHydroponics = async (token, dispatchFarmState) => {
  dispatchFarmState({
    type: 'SET_FARM_LOADING',
    payload: {},
  });
  try {
    let response = await axios.post(
      apiRoutes.hydroponics.loadfarms.path,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatchFarmState({
      type: 'SET_FARM_LISTS',
      payload: response.data,
    });
    return { success: true };
  } catch (err) {
    console.log(err.response.data);
    if (err.response.data.detail === 'Not authenticated') {
      return { error: 'Not authenticated' };
    }
  }
};

export const createFarm = async (token, data) => {
  let {
    farm_name,
    farm_address,
    farm_city,
    farm_size,
    capacity,
    farm_zipCode,
    farm_country,
    farm_state,
    zone_stage,
    zone_name,
    sunrise,
    sunset,
  } = data;
  let apiData = {
    name: farm_name,
    address: farm_address,
    city: farm_city,
    country: farm_country,
    state: farm_state,
    size: farm_size,
    capacity: capacity,
    zip: farm_zipCode,
    zones: [
      {
        name: zone_name,
        stage: zone_stage,
        sunrise: sunrise,
        sunset: sunset,
      },
    ],
  };
  try {
    let response = await axios.post(
      apiRoutes.hydroponics.addFarm.path,
      apiData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return { success: true };
  } catch (err) {
    // console.log(err.response.data);
    return { error: err.response.data.detail };
  }
};

//get zone list

export const getZoneList = async (token, farmId, dispatchFarmState) => {
  try {
    let response = await axios.post(
      apiRoutes.hydroponics.getZones.path,
      { farm_id: farmId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    dispatchFarmState({
      type: 'SET_CURRENT_FARM_ZONES',
      payload: response.data,
    });
    return { success: true };
  } catch (err) {
    console.log(err.response.data);
    return { error: err.response.data.detail };
  }
};

//update zone detials
export const updateZone = async (token, data) => {
  let { farm_id, zone_id, zone_name, zone_stage, sunrise, sunset } = data;
  try {
    let response = await axios.put(
      apiRoutes.hydroponics.updateZone.path,
      {
        farm_id: farm_id,
        zone_id: zone_id,
        name: zone_name,
        stage: zone_stage,
        sunrise: sunrise,
        sunset: sunset,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return { success: true };
  } catch (err) {
    return { error: err.response.data.detail };
  }
};

//add new zone
export const addZone = async (token, data) => {
  let { farm_id, zone_name, zone_stage, sunrise, sunset } = data;
  try {
    let response = await axios.post(
      apiRoutes.hydroponics.createZone.path,
      {
        farm_id: farm_id,
        name: zone_name,
        stage: zone_stage,
        sunrise: sunrise,
        sunset: sunset,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return { success: true };
  } catch (err) {
    return { error: err.response.data.detail };
  }
};

//Delete zone
export const deleteZone = async (token, data) => {
  console.log(token);
  let { farm_id, zone_id } = data;
  try {
    let response = await axios.put(
      apiRoutes.hydroponics.deleteZone.path,
      {
        farm_id: farm_id,
        zone_id: zone_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return { success: true, data: response.data };
  } catch (err) {
    return { error: err.response.data.detail };
  }
};

//Add Device
export const addDevice = async (token, data) => {
  let { farmId, zoneId, deviceName, deviceId } = data;
  try {
    let response = await axios.post(
      apiRoutes.hydroponics.addDevice.path,
      {
        farm_id: farmId,
        zone_id: zoneId,
        name: deviceName,
        dev_id: deviceId,
        domain: 'farm_man',
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return { success: true };
  } catch (err) {
    return { error: err.response.data.detail };
  }
};

//Remove Device
export const removeDevice = async (token, data) => {
  let { farmId, zoneId, deviceId } = data;
  try {
    let response = await axios.put(
      apiRoutes.hydroponics.removeDevice.path,
      {
        farm_id: farmId,
        zone_id: zoneId,
        dev_id: deviceId,
        domain: 'farm_man',
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return { success: true };
  } catch (err) {
    console.log(err);
    return { error: err.response.data.detail };
  }
};

//edit device
export const editDevice = async (token, data) => {
  let { farmId, zoneId, deviceId, name } = data;
  try {
    let response = await axios.put(
      apiRoutes.hydroponics.editDevice.path,
      {
        farm_id: farmId,
        zone_id: zoneId,
        dev_id: deviceId,
        name: name,
        domain: 'farm_man',
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return { success: true };
  } catch (err) {
    console.log(err);
    return {
      error: err.response ? err.response.data.detail : 'Something went wrong',
    };
  }
};

//calibrate vpd
export const calibrateVpd = async (token, data) => {
  let { deviceId, temperature, humidity } = data;
  try {
    let response = await axios.post(
      apiRoutes.hydroponics.calibrateVpd.path,
      {
        dev_id: deviceId,
        temp: temperature,
        hum: humidity,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return { success: true };
  } catch (err) {
    console.log(err);
    return {
      error: err.response ? err.response.data.detail : 'Something went wrong',
    };
  }
};

//get Device details
export const getDeviceDetails = async (token, data) => {
  let { deviceId } = data;
  try {
    let response = await axios.get(
      `${apiRoutes.hydroponics.deviceDetails.path}/${deviceId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return { success: true, data: response.data };
  } catch (err) {
    console.log(err);
    return {
      error: err.response ? err.response.data.detail : 'Something went wrong',
    };
  }
};

//update Device detaild(made by vijay :: don't trust too much xD)
export const getRelayDeviceDetails = async (token, data) => {
  let { deviceId } = data;
  try {
    let response = await axios.get(
      `https://rule-engine.4climate.in/relay/trigger/get?dev_id=${deviceId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return { success: true, data: response.data };
  } catch (err) {
    console.log(err);
    return {
      error: err.response ? err.response.data.detail : 'Something went wrong',
    };
  }
};

export const updateDeviceDetails = async (token, data) => {
  let { devType, deviceid, stateRelay } = data;
  let channel = 0;
  if(data.channel) channel  = data.channel;
  console.log(devType, deviceid, stateRelay, 'ggwp');
  try {
    let response = await axios.post(
      'https://rule-engine.4climate.in/farm/actuate/relay',
      {
        dev_type: devType,
        dev_id: deviceid,
        channel,
        state: stateRelay,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    if(response && response.data)
    return { success: true , data :response.data};
    return { error: "something went wrong" };
  } catch (err) {
    return {
      error: err.response ? err.response.data.detail : 'Something went wrong',
    };
  }
};
export const updateTriggerDetails = async (token, data) => {
  let daily_trigger = data.pushDetails;
  let id = data.id;
  let automation = true;

  if (data.auto !== undefined) {
    automation = data.auto;
  }
  let channel = 0;
  if(data.channel) channel = data.channel; 
  console.log(id, daily_trigger, automation);
  try {
    let response = await axios.post(
      'https://rule-engine.4climate.in/relay/timetrigger/set',
      {
        dev_id: id,
        channel: channel,
        device_name: data.name,
        purpose: data.purpose,
        load_type: 'Single Phase (220 VAC)',
        rated_power: data.ratedPower,
        num_loads: data.noOfLoads,
        is_automation: automation,
        run_trigger: 'Daily at',
        on_duration:"1*hr",
        off_duration:"1*hr",
        daily_trigger: daily_trigger,
        email: ['krisbhatt6@gmail.com'],
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log(response.data);
    return { success: true };
  } catch (err) {
    return {
      error: err.response ? err.response.data.detail : 'Something went wrong',
    };
  }
};
export const updateTriggerDetails2 = async (token, data) => {
  let id = data.id;
  console.log("updateTriggerDetails2",data);
  let fill;
  let automation = true;
  if (data.auto !== undefined) {
    automation = data.auto;
  }
  let channel = 0;
  if(data.channel) channel = data.channel; 
  if (data.offDuration == '' || data.onDuration == '') {
    try {
      let response = await axios.post(
        'https://rule-engine.4climate.in/relay/timetrigger/set',
        {
          dev_id: id,
          channel,
          device_name: data.name,
          purpose: data.purpose,
          load_type: 'Single Phase (220 VAC)',
          rated_power: data.ratedPower,
          num_loads: data.noOfLoads,
          is_automation: automation,
          on_duration:"1*hr",
          off_duration:"1*hr",
          run_trigger: data.pushDetails,
          daily_trigger: [],
          email: ['krisbhatt6@gmail.com'],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);

      return { success: true };
    } catch (err) {
      console.log('adsadsads');
      return {
        error: err.response ? err.response.data.detail : 'Something went wrong',
      };
    }
  } else {
    console.log('ggwp');

    try {
      let response = await axios.post(
        'https://rule-engine.4climate.in/relay/timetrigger/set',
        {
          dev_id: id,
          channel,
          device_name: data.name,
          purpose: data.purpose,
          load_type: 'Single Phase (220 VAC)',
          rated_power: data.ratedPower,
          num_loads: data.noOfLoads,
          is_automation: automation,
          on_duration: data.onDuration,
          off_duration: data.offDuration,
          run_trigger: data.pushDetails,
          daily_trigger: [],
          email: ['subhajit@4climate.in'],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);

      return { success: true };
    } catch (err) {
      console.log('adsadsads');
      return {
        error: err.response ? err.response.data.detail : 'Something went wrong',
      };
    }
  }
};
export const UpdateSensorBasedData = async (token, data) => {
  let automation = true;
  console.log(data)
  if (data.auto !== undefined) {
    automation = data.auto;
  }
  let channel = 0;
  if(data.channel) channel = data.channel;
  if(data.offDuration !== undefined){
    try {
      let response = await axios.post(
        'https://rule-engine.4climate.in/relay/sensortrigger/set',
        {
          dev_id: data.relayId,
          channel,
          device_name: data.name,
          purpose: data.purpose,
          load_type: 'Single Phase (220 VAC)',
          rated_power: data.ratedPower,
          num_loads: data.noOfLoads,
          is_automation: automation,
          sensor_id: data.sensorId,
          sensor_type: data.sensorType,
          type: data.sensor_comparison,
          upperLimit: data.upperLimit,
          lowerLimit: data.lowerLimit,
          on_duration: data.onDuration,
          off_duration: data.offDuration,
          is_performance_mode: data.performanceMode,
          email: ['subhajit@4climate.in'],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      return { success: true };
    } catch (err) {
      return {
        error: err.response ? err.response.data.detail : 'Something went wrong',
      };
    }
  }
  else{
    try {
      let response = await axios.post(
        'https://rule-engine.4climate.in/relay/sensortrigger/set',
        {
          dev_id: data.relayId,
          channel,
          device_name: data.name,
          purpose: data.purpose,
          load_type: 'Single Phase (220 VAC)',
          rated_power: data.ratedPower,
          num_loads: data.noOfLoads,
          is_automation: automation,
          sensor_id: data.sensorId,
          sensor_type: data.sensorType,
          type: data.sensor_comparison,
          upperLimit: data.upperLimit,
          lowerLimit: data.lowerLimit,
          on_duration: data.onDuration,
          is_performance_mode: data.performanceMode,
          email: ['subhajit@4climate.in'],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      return { success: true };
    } catch (err) {
      return {
        error: err.response ? err.response.data.detail : 'Something went wrong',
      };
    }
  }
};

//get farm details ( for farm config )
export const getFarmDetails = async (token, data) => {
  let { farmId } = data;
  try {
    let response = await axios.get(
      `${apiRoutes.hydroponics.getFarmDetails.path}/${farmId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return { success: true, data: response.data };
  } catch (err) {
    console.log(err);
    return {
      error: err.response ? err.response.data.detail : 'Something went wrong',
    };
  }
};

//update farm details
export const updateFarmDetails = async (token, data) => {
  const {
    farmId,
    farm_name,
    farm_size,
    plant_capacity,
    farm_address,
    farm_city,
    farm_state,
    farm_zip,
    farm_country,
  } = data;
  try {
    let response = await axios.put(
      apiRoutes.hydroponics.updateFarmDetails.path,
      {
        farm_id: farmId,
        name: farm_name,
        size: farm_size,
        capacity: plant_capacity,
        address: farm_address,
        city: farm_city,
        state: farm_state,
        zip: farm_zip,
        country: farm_country,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log(response.data);
    return { success: true };
  } catch (err) {
    console.log(err);
    return {
      error: err.response ? err.response.data.detail : 'Something went wrong',
    };
  }
};

//delete farm
export const deleteFarm = async (token, data) => {
  let { farmId } = data;
  try {
    let response = await axios.delete(
      `${apiRoutes.hydroponics.deleteFarm.path}/${farmId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return { success: true };
  } catch (err) {
    console.log(err);
    return {
      error: err.response ? err.response.data.detail : 'Something went wrong',
    };
  }
};

//get invited users
export const getInvitedUsers = async (token, data) => {
  let { farmId } = data;
  try {
    let response = await axios.get(
      `${apiRoutes.hydroponics.getInvitedUsers.path}/${farmId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    if(response && response.data){
      return { success: true, data: response.data };
    }
    return {
      error: "something went wrong!"
    }
  } catch (err) {
    console.log(err);
    return {
      error: err.response ? err.response.data.detail : 'Something went wrong',
    };
  }
};

//Invite User in farm
export const inviteUser = async (token, data) => {
  let { farmId, owner_email, invitee_email, farm_name } = data;
  try {
    let response = await axios.put(
      apiRoutes.hydroponics.inviteUser.path,
      {
        farm_id: farmId,
        email: invitee_email,
        owner: owner_email,
        farm_name: farm_name,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return { success: true };
  } catch (err) {
    console.log(err.response);
    return {
      error: err.response ? err.response.data.detail : 'Something went wrong',
    };
  }
};

//Uninvite User in farm
export const uninviteUser = async (token, data) => {
  let { farmId, owner_email, invitee_email, farm_name } = data;
  try {
    let response = await axios.put(
      apiRoutes.hydroponics.uninviteUser.path,
      {
        farm_id: farmId,
        email: invitee_email,
        owner: owner_email,
        farm_name: farm_name,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return { success: true };
  } catch (err) {
    console.log(err.response);
    return {
      error: err.response ? err.response.data.detail : 'Something went wrong',
    };
  }
};

// Get Trendline
export const getTrendline = async (token, data) => {
  let { deviceId } = data;
  try {
    let response = await axios.get(
      `${apiRoutes.hydroponics.getTrendLine.path}/${deviceId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if(response && response.data){

      console.log(response.data);
      let humidityData = [];
      let temperatureData = [];
      let dewData = [];
      let vpdData = [];
      response.data.Data.forEach((element) => {
        element.humidity
        ? humidityData.push(element.humidity)
        : humidityData.push(0);
        element.temp
        ? temperatureData.push(element.temp)
        : temperatureData.push(0);
        element.dew ? dewData.push(element.dew) : dewData.push(0);
        element.vpd ? vpdData.push(element.vpd) : vpdData.push(0);
      });
      
      humidityData = normalizeArray(humidityData);
      temperatureData = normalizeArray(temperatureData);
      dewData = normalizeArray(dewData);
      vpdData = normalizeArray(vpdData);
      
      console.log(humidityData);
      
      return {
        success: true,
        data: {
          humidityData: humidityData,
        temperatureData: temperatureData,
        dewData: dewData,
        vpdData: vpdData,
      },
    };
  }
  
  } catch (err) {
    console.log(err);
    return {
      error: err.response ? err.response.data.detail : 'Something went wrong',
    };
  }
};

//Get graph data
export const getGraphData = async (token, data) => {
  let { dev_id, tf_index } = data;
  try {
    let response = await axios.get(
      `${apiRoutes.hydroponics.getFarmAnalytics.path}/${dev_id}/${tf_index}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if(response  && response.data){

      console.log(response.data);
      let humidityData = [];
      let temperatureData = [];
      let dewData = [];
      let vpdData = [];
      let timeStamps = [];
      
      response.data.Data.forEach((element) => {
        element.humidity
        ? humidityData.push(round(element.humidity, 2))
        : humidityData.push(0);
        element.temp
        ? temperatureData.push(round(element.temp, 2))
        : temperatureData.push(0);
        element.dew ? dewData.push(round(element.dew, 2)) : dewData.push(0);
        element.vpd ? vpdData.push(round(element.vpd, 2)) : vpdData.push(0);
        timeStamps.push(moment(element.timestamp).format('DD-MM-YYYY HH:mm:ss'));
      });
    let analytics = response.data.Analytics;
    let analyticsData = [
      {
        min: analytics.min[0],
        max: analytics.max[0],
        dayAvg: analytics.day_avg[0],
        nightAvg: analytics.night_avg[0],
        unit: 'kPa',
      },
      {
        min: analytics.min[1],
        max: analytics.max[1],
        dayAvg: analytics.day_avg[1],
        nightAvg: analytics.night_avg[1],
        unit: '°C',
      },
      {
        min: analytics.min[2],
        max: analytics.max[2],
        dayAvg: analytics.day_avg[2],
        nightAvg: analytics.night_avg[2],
        unit: '°C',
      },
      {
        min: analytics.min[3],
        max: analytics.max[3],
        dayAvg: analytics.day_avg[3],
        nightAvg: analytics.night_avg[3],
        unit: '%',
      },
    ];
    
    return {
      success: true,
      data: {
        humidityData: humidityData,
        temperatureData: temperatureData,
        dewData: dewData,
        vpdData: vpdData,
        analytics: analyticsData,
        timeStamps: timeStamps,
      },
    };
  }
  else {
     return {
      error: 'Something went wrong',
    };
  }
  } catch (err) {
    console.log(err);
    return {
      error: err.response ? err.response.data.detail : 'Something went wrong',
    };
  }
};

//send report
export const sendReport = async (token, data) => {
  const { dev_id, format, start_date, end_date } = data;
  try {
    let response = await axios.post(
      `${apiRoutes.hydroponics.sendReport.path}`,
      {
        dev_id: dev_id,
        format: format,
        start_date: start_date,
        end_date: end_date,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response);
    if (response.status === 200) {
      return { success: true };
    } else {
      return { error: 'Something went wrong' };
    }
  } catch (err) {
    console.log(err);
    return {
      error: err.response ? err.response.data.detail : 'Something went wrong',
    };
  }
};

import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import Background from 'Components/common/Background';
import bgImage from 'assets/img/icons/spot-illustrations/corner-5.png';
import connectCircle from 'assets/img/icons/connect-circle.png';
import Flex from 'Components/common/Flex';

const CO2Monitoring = () => {
  return (
    <Card>
      <Background
        image={bgImage}
        className="bg-card"
        style={{
          borderTopRightRadius: '0.375rem',
          borderBottomRightRadius: '0.375rem'
        }}
      />
      <Card.Body className="position-relative">
        <Row className="align-items-sm-center">
          <Col xs="auto" className='d-none d-sm-block '>
            <img className="img-responsive" src={connectCircle} alt="connectCircle" height="45"/>
          </Col>
          <Col>
            <Row className="align-items-center">
              <Col className="pe-xl-8">
                <h5 className="fs-sm-0 fs--1 mb-2 mb-sm-1 text-primary text-nowrap">
                  Farm CO2 Monitoring
                </h5>
                
              </Col>
              <Col xs="auto" className="ms-auto me-md-5 me-2 align-items-center">
              <p className="fs-sm-0 fs--1 mb-3 mb-sm-1"> Coming Soon! </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CO2Monitoring;

import React, { useEffect } from 'react';
import { Card, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'Components/common/Flex';
import Chart from './Chart';
import { useHistory } from "react-router-dom";





const StatsCard = ({ status, sname, comment, readings, graphData, isTrendLineLoading }) => {
  const history = useHistory();
  useEffect(() => {
    console.log(isTrendLineLoading, "Trendlihne Loadin", graphData)
  
    
  }, [isTrendLineLoading, graphData])
  return (
    <Card >
      <Card.Header as={Flex} alignItems="center" className="py-2">
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip> {status === '0' ? "Device Offline" : "Device Online"}</Tooltip>
          }
        >
          <div >
            <FontAwesomeIcon
              icon={'circle'}
              transform="shrink-1"
              className="mr-1"
              id="avgCallTooltip"
              color={status === '0' ? '#ff0000' : '#00c853'}
            />
          </div>
        </OverlayTrigger>
        <h6 className="mb-0" style={{ marginLeft: "10px" }}>{sname}</h6>
      </Card.Header>
      <Card.Body as={Flex} justifyContent="between">
        <div className="">
          <h4 className="text-primary fw-normal">{readings}</h4>
          <p className="fs--2 fw-semi-bold text-500 mb-0">{comment}</p>
        </div>
        {/* <a onClick={routeChangeAnalytics}> */}
        {
          isTrendLineLoading ?
            <Spinner animation="border" variant="primary" /> :
            <Chart data={graphData}/>
        }
        {/* </a> */}
      </Card.Body>
    </Card>
  );
};

export default StatsCard;

//=================================================================================


// vpdIntelligence(currentAirTemperature, currentRelativeHumidity, "seedling")

//=================================================================================

export const vpdIntelligence = (currentAirTemperature, currentRelativeHumidity, currentStage) => {

    const stages = ["seedling", "vegetative", "flowering"];
    let returnData = {};
    // TODO: Update it in later release
    let currentLeafTemperature = currentAirTemperature - 2
    var leafTemperatureAdjustment = currentLeafTemperature - currentAirTemperature

    var airSVP = 610.78 * Math.exp(currentAirTemperature / (currentAirTemperature + 238.3) * 17.2694) / 1000
    var leafSVP = 610.78 * Math.exp(currentLeafTemperature / (currentLeafTemperature + 238.3) * 17.2694) / 1000
    var airVPD = airSVP * (1 - currentRelativeHumidity / 100)
    var leafVPD = leafSVP - airSVP * currentRelativeHumidity / 100
    airSVP = airSVP.toFixed(1)
    leafSVP = leafSVP.toFixed(1)
    airVPD = airVPD.toFixed(1)
    leafVPD = leafVPD.toFixed(1)
    // console.log("Air SVP "+airSVP)
    // console.log("Air VPD "+airVPD)
    // console.log("Leaf SVP "+leafSVP)
    // console.log("Leaf VPD "+leafVPD)

    var temperature = new Array(37);
    for (let i = 0; i < temperature.length; i++) {
        temperature[i] = i + 10;
    }
    var relativeHumidity = new Array(51);
    let count = 100;
    for (let i = 0; i < relativeHumidity.length; i++) {
        relativeHumidity[i] = count;
        count = count - 2;
    }
    var chartZone = new Array(37);
    for (let i = 0; i < chartZone.length; i++) {
        chartZone[i] = new Array(51);
    }
    for (let i = 0; i < temperature.length; i++) {
        for (let j = 0; j < relativeHumidity.length; j++) {
            let tempVar1 = (610.78 * Math.exp((temperature[i] + leafTemperatureAdjustment) / ((temperature[i] + leafTemperatureAdjustment) + 238.3) * 17.2694) / 1000) - 610.78 * Math.exp(temperature[i] / (temperature[i] + 238.3) * 17.2694) / 1000 * relativeHumidity[j] / 100;
            let tempVar2;

            if (currentStage.localeCompare(stages[0]) == 0) {
                if (tempVar1 >= 0.6 && tempVar1 <= 0.8) {
                    tempVar2 = 1;
                } else if (tempVar1 >= 0.4 && tempVar1 <= 1) {
                    tempVar2 = 2;
                } else if (tempVar1 >= 0.2 && tempVar1 <= 1.4) {
                    tempVar2 = 3;
                } else {
                    tempVar2 = 4;
                }
            } else if (currentStage.localeCompare(stages[1]) == 0) {
                if (tempVar1 >= 0.9 && tempVar1 <= 1.1) {
                    tempVar2 = 1;
                } else if (tempVar1 >= 0.7 && tempVar1 <= 1.3) {
                    tempVar2 = 2;
                } else if (tempVar1 >= 0.45 && tempVar1 <= 1.7) {
                    tempVar2 = 3;
                } else {
                    tempVar2 = 4;
                }
            } else {
                if (tempVar1 >= 1.2 && tempVar1 <= 1.5) {
                    tempVar2 = 1;
                } else if (tempVar1 >= 1 && tempVar1 <= 1.7) {
                    tempVar2 = 2;
                } else if (tempVar1 >= 0.7 && tempVar1 <= 2.3) {
                    tempVar2 = 3;
                } else {
                    tempVar2 = 4;
                }
            }
            chartZone[i][j] = tempVar2;
        }
    }


    let stringState = "";
    let idealRange = new Array(2);
    if (currentStage.localeCompare(stages[0]) == 0) {
        idealRange[0] = 0.6;
        idealRange[1] = 0.8;
        if (leafVPD >= 0.6 && leafVPD <= 0.8) {
            stringState = "Perfect"
        } else if (leafVPD >= 0.4 && leafVPD <= 1) {
            stringState = "Good"
        } else if (leafVPD >= 0.2 && leafVPD <= 1.4) {
            stringState = "Average"
        } else {
            stringState = "Poor"
        }
    } else if (currentStage.localeCompare(stages[1]) == 0) {
        idealRange[0] = 0.9;
        idealRange[1] = 1.1;
        if (leafVPD >= 0.9 && leafVPD <= 1.1) {
            stringState = "Perfect"
        } else if (leafVPD >= 0.7 && leafVPD <= 1.3) {
            stringState = "Good"
        } else if (leafVPD >= 0.45 && leafVPD <= 1.7) {
            stringState = "Average"
        } else {
            stringState = "Poor"
        }
    } else {
        idealRange[0] = 1.2;
        idealRange[1] = 1.5;
        if (leafVPD >= 1.2 && leafVPD <= 1.5) {
            stringState = "Perfect"
        } else if (leafVPD >= 1 && leafVPD <= 1.7) {
            stringState = "Good"
        } else if (leafVPD >= 0.7 && leafVPD <= 2.3) {
            stringState = "Average"
        } else {
            stringState = "Poor"
        }
    }

    let idealLeafVPD = (idealRange[0] + idealRange[1]) / 2;
    // deltaPercentage = 100 - ((Math.abs(leafVPD - idealLeafVPD))/idealLeafVPD)*100

    console.log("Your current farm environment for the selected", currentStage, "stage is", stringState)
    returnData.stage = currentStage;
    returnData.state = stringState;
    currentRelativeHumidity = Math.floor(currentRelativeHumidity / 2) * 2
    currentAirTemperature = Math.floor(currentAirTemperature / 2) * 2

    let rhIndex = relativeHumidity.indexOf(currentRelativeHumidity);
    let tempIndex = temperature.indexOf(currentAirTemperature);
    let idealTempIdx = 0
    let idealRHIdx = 0
    if (stringState.localeCompare("Perfect") != 0) {
        let minDistance = 10000
        for (let i = 0; i < temperature.length; i++) {
            for (let j = 0; j < relativeHumidity.length; j++) {
                if (chartZone[i][j] == 1) {
                    let distance = Math.sqrt(Math.pow((i - tempIndex), 2) + Math.pow(((j - rhIndex)), 2))
                    if (distance < minDistance) {
                        minDistance = distance
                        idealTempIdx = i
                        idealRHIdx = j
                    }
                }
            }
        }
        console.log("Change the Temperature to ", temperature[idealTempIdx], " degree Celcius and change Relative Humidity to ", relativeHumidity[idealRHIdx], "% to achieve the optimal range of VPD ", idealRange[0], "-", idealRange[1], "kPa for faster growth")
        returnData.idealTemp = temperature[idealTempIdx]
        returnData.idealRH = relativeHumidity[idealRHIdx]
        returnData.optimalVpdmin = idealRange[0]
        returnData.optimalVpdmax = idealRange[1]
    } else {
        console.log("Continue current setting for best growth!")
        returnData.perfect = true
    }
    return returnData
}
import React, { createContext, useContext, useEffect, useState } from 'react';
import FarmSettingsBanner from '../FarmSettingsBanner';
import coverSrc from 'assets/img/generic/4.jpg';
import { Col, Row, Spinner } from 'react-bootstrap';
import Intro from './Intro';
import FarmConfiguartion from './FarmConfiguration';
import DeviceInfo from './DeviceInfo/DeviceInfo';
import { DeviceInfoData } from './DeviceInfo/DeviceInfoData';
import FarmZones from './FarmZones/FarmZones';
import BackTop from '../../BackTop';
import DashboardContext from 'Context/DashboardContext';
import { getFarmDetails } from 'API/hydroponicsFarmsAPI'
import AuthContext from 'Context/AuthContext';
import BackButton from 'Components/common/BackButton';

const FarmConfigContext = createContext();

const FarmSettings = () => {
  const { dashboardState } = useContext(DashboardContext)
  const { authState } = useContext(AuthContext)

  const [data, setData] = useState({
    farmId: dashboardState.farmDetails ? dashboardState.farmDetails._id : '',
    zonesList: dashboardState.zoneList ? dashboardState.zoneList : [],
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setData({
      farmId: dashboardState.farmDetails ? dashboardState.farmDetails._id : '',
      zonesList: dashboardState.zoneList ? dashboardState.zoneList : [],
    })
  }, [dashboardState.farmDetails])


  const getFarmDetailsData = async () => {
    console.log(data)
    if (data.farmId) {
      setIsLoading(true)
      let resData = await getFarmDetails(authState.accessToken, data)
      setIsLoading(false)
      if (resData.success) {
        setData((prevState) => {
          return {
            ...prevState,
            ...resData.data
          }
        })
        console.log(data)
      }
      else {
        console.log(resData.error)
      }
    }
  }

  useEffect(() => {
    getFarmDetailsData()
  }, [data.farmId])


  return (
    <FarmConfigContext.Provider value={{
      farmConfigData: data,
      getFarmDetails: getFarmDetailsData,
    }}>
      <BackTop />
      <BackButton />
      <FarmSettingsBanner>
        <FarmSettingsBanner.Header
          coverSrc={coverSrc}
          className="mb-0"
        />
      </FarmSettingsBanner>
      <Row className="g-3 mb-3">
        <Col xxl={9}>
          <Intro />
          <FarmConfiguartion />
        </Col>
      </Row>
      {isLoading ?
        <div className='d-flex justify-content-center'>
          <Spinner animation='border' variant="primary" />
        </div>

        :
        <>
          <Row className="g-3 mb-3">
            <Col xxl={9}>
              <DeviceInfo data={DeviceInfoData} />
            </Col>
          </Row>
          <Row className="g-3 mb-3">
            <Col xxl={9}>
              <FarmZones />
            </Col>
          </Row>
        </>
      }
    </FarmConfigContext.Provider>
  );
};

export default FarmSettings;
export { FarmConfigContext };
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Background from 'Components/common/Background';
import Flex from 'Components/common/Flex';
import React, { useState, useEffect, useContext } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import corner1 from 'assets/img/icons/spot-illustrations/corner-3.png';
import PropTypes from 'prop-types';
import { ZoneConfigContext } from '../index';
import climatedata from 'Components/Others/climatedata';
import DeviceSettingsCard from './DeviceSettingsCard';
import moment from 'moment';


const EditDeviceSettings = ({ titledata }) => {


  const zoneConfigData = useContext(ZoneConfigContext);
  //console.log(data.farmproductid);

  const [data, setData] = useState({
    productId: "",
    network: "--",
    lastCalibrated: "--/--/-- --:--:--",
    deviceStatus: "Offline",
    lastSynced: " --/--/-- --:--:--",
  });

  useEffect(() => {
    console.log(zoneConfigData);
    setData((preValue) => {
      return {
        ...preValue,
        productId: zoneConfigData.zoneData.productDetails.id ? zoneConfigData.zoneData.productDetails.id : "--",
        lastCalibrated: zoneConfigData.deviceData.last_calib ? moment(zoneConfigData.deviceData.last_calib).format("DD MMM YYYY") : "--/--/-- --:--:--",
        deviceStatus: zoneConfigData.deviceData.status ? (zoneConfigData.deviceData.status === '0' ? "Offline" : "Online") : '',
        network: zoneConfigData.deviceData.network ? zoneConfigData.deviceData.network : "--",
      }
    })
  }, [zoneConfigData]);


  console.log("efgh1234",data);


  return (
    <Card className="h-100">
      <Background image={corner1} className="rounded-soft bg-card" />
      <Card.Header className="z-index-1">
        <h5 className="text-primary">Edit Device Settings | Farm Environment | {zoneConfigData.zoneData.name}</h5>
        <h6 className="text-600">Here are some quick information about your product AER 360</h6>
      </Card.Header>
      <Card.Body className="z-index-1">
        <Row className="g-2 h-100 align-items-end">
          <DeviceSettingsCard title='Product ID' icon="chess-rook" color="primary" text={data.productId} />
          <DeviceSettingsCard title="Network" icon="crown" color="warning" text={data.network} />
          <DeviceSettingsCard title="Last Calibrated" icon="crown" color="warning" text={data.lastCalibrated} />
          <DeviceSettingsCard title="Device Status" icon="circle" color={data.deviceStatus === 'Offline' ? 'warning' : 'success'} text={data.deviceStatus} />
          <DeviceSettingsCard title="Strength" icon="user" color="info" text={data.lastSynced} />
        </Row>
      </Card.Body>
    </Card>
  );
};

EditDeviceSettings.propTypes = {
  titledata: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired
    })
  )
};

export default EditDeviceSettings;

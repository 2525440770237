import React, { useState } from "react";
import { Card, Form } from "react-bootstrap";

export default function AutomationChannelForm({
  multiDeviceChannel,
  stateChanger,
  deviceData,
}) {
  return (
    <Card className="bg-light my-3">
      <Card.Body className="p-3 position-relative">
        <Form className="d-flex flex-row">
          <Form.Text className="text-nowrap me-2 mt-1 text-primary d-none d-sm-block ">
            <strong className="fs-0 ">Automation</strong>
          </Form.Text>
          <select
            className="form-select fs--1"
            aria-label="Default select example"
            name="channel"
            onChange={(e) => {
                stateChanger(e.target.value);

              console.log("channel change", e.target.value, deviceData);
            }}
            value={deviceData}
          >
            <option value="" disabled selected>
              --choose--
            </option>
            {multiDeviceChannel &&
              multiDeviceChannel.length &&
              multiDeviceChannel.map((channel, indx) => (
                <option value={indx}> Channel {indx + 1}</option>
              ))}
          </select>
        </Form>
      </Card.Body>
    </Card>
  );
}

import React, { useState, useContext, useEffect } from 'react'
import { Button, Form, FormControl } from "react-bootstrap";
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ZoneConfigContext } from '.';
import DashboardContext from 'Context/DashboardContext';
import AuthContext from 'Context/AuthContext';
import { editDevice } from 'API/hydroponicsFarmsAPI';
import { useAlert } from 'react-alert'

const DeviceName = () => {
  const zoneConfigData = useContext(ZoneConfigContext);
  const { authState } = useContext(AuthContext)
  const { dashboardState } = useContext(DashboardContext)
  const alert = useAlert()
  const [formData, setFormData] = useState({
    farmId: dashboardState.farmDetails._id ? dashboardState.farmDetails._id : "",
    zoneId: zoneConfigData.zoneData.zoneId ? zoneConfigData.zoneData.zoneId : "",
    deviceId: zoneConfigData.zoneData.productDetails.id ? zoneConfigData.zoneData.productDetails.id : "",
    name: zoneConfigData.zoneData.productDetails.name ? zoneConfigData.zoneData.productDetails.name : "",
  })

  useEffect(() => {
    setFormData((prevState) => {
      return {
        ...prevState,
        farmId: dashboardState.farmDetails._id ? dashboardState.farmDetails._id : "",
        zoneId: zoneConfigData.zoneData.zoneId ? zoneConfigData.zoneData.zoneId : "",
        deviceId: zoneConfigData.zoneData.productDetails.id ? zoneConfigData.zoneData.productDetails.id : "",
        name: zoneConfigData.zoneData.productDetails.name ? zoneConfigData.zoneData.productDetails.name : "",
      }
    })
  }, [zoneConfigData.zoneData.productDetails, dashboardState])


  const onChangeName = (e) => {
    setFormData({
      ...formData,
      name: e.target.value
    })
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    if (formData.name.length < 10) {
      let resData = await editDevice(authState.accessToken, formData)
      if (resData.success) {
        console.log(resData)
        alert.show('Device Name Updated Successfully!')
      }
      else {
        alert.error("Try Again!")
      }
    }
  }


  return (
    <>
      <Card className="bg-light my-3">
        <Card.Body className="p-3 position-relative">
          <Form className='d-flex flex-row' onSubmit={onSubmit}>
            <Form.Text className='text-nowrap me-2 mt-1 text-primary d-none d-sm-block '>
              <strong className='fs-0'>Device Name</strong>
            </Form.Text>
            <FormControl className=' fs--1'
              placeholder='Device Name'
              aria-label="Device Name"
              aria-describedby="basic-addon2"
              value={formData.name}
              onChange={onChangeName}
              isInvalid={formData.name && formData.name.length > 10}
              required
            />
            <FormControl.Feedback type="invalid">
              Device Name should be less than 10 characters
            </FormControl.Feedback>

            <Button className="btn btn-primary text-nowrap" id="button-addon2" type="submit">
              Save
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  )
}

export default DeviceName;

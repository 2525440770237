import React, { useContext } from 'react';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import BasicECharts from 'Components/common/BasicEChart';
import { getColor, rgbaColor } from 'helpers/utils';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer
]);

const getOptions = (a) => ({

  series: [
    {
      type: 'line',
      data: a,
      color: getColor('warning'),
      areaStyle: {
        color: {
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('warning'), 0.23)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('warning'), 0.04)
            }
          ]
        }
      }
    }
  ],

  grid: { bottom: '0px', right: 0},
});

const Chart = ({ data }) => {
  // console.log(data);
  // let data = useContext(Data);
  // let data = [101, 101, 100, 111, 101, 100, 101, 111]  //dummy data
  return (
    <BasicECharts
      echarts={echarts}
      options={getOptions(data)}
      style={{ height: '3.5rem', width: '12rem' }}
    />
  );
};

export default Chart;



export const authReducer = (state, action) => {
    const { type, payload } = action;
    console.log(type, payload)
    switch (type) {
        case 'LOGIN_LOAD':
            return {
                ...state,
                isLoading: true
            }
        case 'LOGIN_SUCCESS':
            localStorage.setItem('access_token', payload.accessToken);
            return {
                isAuth: true,
                accessToken: payload.accessToken,
                username: payload.username,
                email:payload.email,
                isLoading: false
            }

        case 'LOGOUT':
            localStorage.removeItem('access_token');
            localStorage.removeItem('farmState');
            return {
                isAuth: false,
                accessToken: '',
                username: '',
                email:'',
                isLoading: false
            }
        default:
            return state
    }
}
import { getItemFromStore, setItemToStore } from 'helpers/utils';
import React, {useState} from 'react'
import { Card, Col, Row, Form, FormControl, Button, Dropdown, InputGroup } from 'react-bootstrap';
const VpdGuidance = () => {
    const [intelligence, setIntelligence] = useState(getItemFromStore('showFarmIntelligence') === 'true' ? true : false);
    const [leaf, setLeaf] = useState(false);
    console.log(getItemFromStore('showFarmIntelligence'), intelligence);
    const inputEvent = () => {
        setItemToStore('showFarmIntelligence', !intelligence ? "true" : "false");
        setIntelligence(!intelligence);
      };

      const inputLeafEvent = () => {
                console.log(leaf);
                setLeaf(!leaf);
              };
    return (
        <>
            <Card className="bg-light my-3">
                <Card.Body className="p-3 position-relative">
                    <Row className="align-items-center row">
                        <Col>
                            <p className=" mb-0 text-left text-primary">
                                <strong className='fs-0'>VPD Guidance</strong>    
                            </p>
                        </Col>
                    </Row> 
                    <Row className="no-gutters mt-2">
                        <Col className='col-lg-1 col-md-2 col-4 me-4 me-md-2 me-lg-5'>
                            <p className="fs-0 text-primary text-nowrap">
                                Farm Intelligence   
                            </p>
                
                        </Col>
                        <Col className='col-md-2'>
                        <Form.Check
                            type='switch'
                            id=''
                            checked={intelligence}
                            onChange={inputEvent}
                        />
                        </Col>
                    </Row>
                    <Row className="align-items-center row lh-sm">
                        <h6 className="text-500 fs--2">Enter the target VPD for your device (in kPa) and our algorithm tells you what temperature or humidity you need in order to achieve that VPD.</h6>
                        <Form.Check
                            className='ms-2' 
                            type='checkbox'
                            id='defaultCheckbox'>
                            <Form.Check.Input type='checkbox' className='fs--2 mt-sm-2 mt-0' onChange={inputLeafEvent}/>
                            <Form.Check.Label className="text-500 fs--2 lh-sm">Check this box if you are not sure about the leaf temperature. We will apply standard settings.</Form.Check.Label>
                            </Form.Check>
                            {/* label='Check this box if you are not sure about the leaf temperature. We will apply standard settings.' */}
                        
                    </Row>
                    <Row className="align-items-center row mt-2">
                    {/* V2.0 Stuff 
                    <Form>
                    <Row className='no-gutters justify-content-start'>
                    <Col className='col-5 col-sm-2'>
                        <Form.Text className='text-nowrap me-2 mt-3 text-primary fs-0'>
                        <strong className='fs-0'>Target VPD</strong>
                        </Form.Text>
                        </Col>
                        <Col className='col-7 col-sm-4 mt-0'>
                            <FormControl className='fs--1'
                            placeholder='"Target VPD"'
                            aria-label="VPD Target"
                            aria-describedby="basic-addon2"
                            />
                        </Col>
                        <div className="w-100"></div>
                        {leaf ? '' : (
                        <Col className='col-5 col-sm-2 mt-2'>
                        <Form.Text className='text-nowrap mt-1 text-primary fs-0'>
                        <strong className='fs-0'>Leaf Temperature</strong>
                        </Form.Text>
                        </Col>)}
                        {leaf ? '' : (
                        <Col className='col-7 col-sm-4 mt-2'>
                        <InputGroup>
                        <FormControl className='fs--1' aria-label="Set Target"  name=''
                            type="number" placeholder=''/>
                            <InputGroup.Text id="">°C</InputGroup.Text>
                        </InputGroup>
                        </Col>)}
                        </Row>
                        <Row className="align-items-center row mt-sm-4 mt-3 ms-1 me-1">
                        <button type="submit" className={`btn btn-primary text-nowrap ${intelligence ? '' : 'disabled'}`}>Save
                        </button>
                    </Row> 
                    </Form> */}
                    </Row>                   
                </Card.Body>
            </Card>
        </>
    )
}

export default VpdGuidance

import React, { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Background from 'Components/common/Background';
import Flex from 'Components/common/Flex';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import corner1 from 'assets/img/icons/spot-illustrations/corner-3.png';
import PropTypes from 'prop-types';
import { FarmConfigContext } from '../FarmSettings';
import { far } from '@fortawesome/free-regular-svg-icons';

const DeviceInfo = ({ data }) => {
  const { farmConfigData } = useContext(FarmConfigContext);
  const [devices, setDevices] = useState(0);

  useEffect(() => {
    let devCount = 0
    if (farmConfigData.zones) {
      farmConfigData.zones.forEach(zone => {
        if (zone.farm_man) {
          devCount += zone.farm_man.length
        }
        if (zone.nut_man) {
          devCount += zone.nut_man.length
        }
      })
      setDevices(devCount)
    }
  },
    [farmConfigData.zones])

  return (
    <Card className="h-100">
      <Background image={corner1} className="rounded-soft bg-card" />
      <Card.Body className="z-index-1">
        <Row className="g-2 h-100 align-items-end">
          <Col xs={5} md={4} key="Devices">
            <Flex className="position-relative">
              <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                <FontAwesomeIcon icon={"chess-rook"} className={`text-primary`} />
              </div>
              <div className="flex-1">
                <Link to="#!" className="stretched-link">
                  <h6 className="text-800 mb-0">{"Devices"}</h6>
                </Link>
                <p className="mb-0 fs--2 text-500 ">{devices}</p>
              </div>
            </Flex>
          </Col>
          <Col xs={5} md={4} key={"Farm Size"}>
            <Flex className="position-relative">
              <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                <FontAwesomeIcon icon={"crown"} className={`text-warning`} />
              </div>
              <div className="flex-1">
                <Link to="#!" className="stretched-link">
                  <h6 className="text-800 mb-0">{"Farm Size"}</h6>
                </Link>
                <p className="mb-0 fs--2 text-500 ">{farmConfigData.size} sqft</p>
              </div>
            </Flex>
          </Col>
          <Col xs={5} md={4} key="Total Zones">
            <Flex className="position-relative">
              <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                <FontAwesomeIcon icon={"user"} className={`text-info`} />
              </div>
              <div className="flex-1">
                <Link to="#!" className="stretched-link">
                  <h6 className="text-800 mb-0">{"Total Zones"}</h6>
                </Link>
                <p className="mb-0 fs--2 text-500 ">{farmConfigData.zonesList.length}</p>
              </div>
            </Flex>
          </Col>
          <Col xs={5} md={4} key="Location">
            <Flex className="position-relative">
              <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                <FontAwesomeIcon icon={"map"} className={`text-success`} />
              </div>
              <div className="flex-1">
                <Link to="#!" className="stretched-link">
                  <h6 className="text-800 mb-0">{"Location"}</h6>
                </Link>
                <p className="mb-0 fs--2 text-500 ">{farmConfigData.city}, {farmConfigData.state}, {farmConfigData.country}</p>
              </div>
            </Flex>
          </Col>
          <Col xs={5} md={4} key="Capacity">
            <Flex className="position-relative">
              <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                <FontAwesomeIcon icon={"crown"} className={`text-info`} />
              </div>
              <div className="flex-1">
                <Link to="#!" className="stretched-link">
                  <h6 className="text-800 mb-0">{"Capacity"}</h6>
                </Link>
                <p className="mb-0 fs--2 text-500 ">{farmConfigData.capacity} plants</p>
              </div>
            </Flex>
          </Col>
          <Col xs={5} md={4} key="Farm Type">
            <Flex className="position-relative">
              <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                <FontAwesomeIcon icon={"user"} className={`text-info`} />
              </div>
              <div className="flex-1">
                <Link to="#!" className="stretched-link">
                  <h6 className="text-800 mb-0">{"Farm Type"}</h6>
                </Link>
                <p className="mb-0 fs--2 text-500 ">{"Hydroponics"}</p>
              </div>
            </Flex>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

DeviceInfo.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired
    })
  )
};

export default DeviceInfo;

import { getDates } from 'helpers/utils';
const climatedata = [
    {
        postalid: 1,
        postalcode: {
            code: 400058,
            zone: [
                {
                    zoneno: 1,
                    zoneurl: "zone-1",
                    zonename: "Zone 1",
                    types:[
                        {
                            typeno: 1,
                            typename: "Farm Management",
                            typeurl: "farm-management",
                            productCategory: [
                                {
                                    productno: 1,
                                    productid: "FAVA100103",
                                    productname: "Farm Environment",
                                    sensors: [
                                        {
                                            sensorno: 1,
                                            sensorname: "VPD",
                                            sensorunit: "kPa",
                                            data: [8, 15, 12, 14, 18, 12, 12, 25, 13, 12, 10, 13, 15],
                                            monitoringdata: {
                                                dates: getDates(
                                                    new Date('5-6-2019'),
                                                    new Date('5-6-2021'),
                                                    1000 * 60 * 60 * 24 * 30
                                                  ),
                                                dataset: [645, 500, 550, 550, 473, 405, 286, 601, 743, 450, 604, 815, 855, 722,
                                                    700, 896, 866, 952, 719, 558, 737, 885, 972, 650, 600],
                                                current: 1.2,
                                                min: 0.4,
                                                max: 1.8,
                                                dayavg: 1.2,
                                                nightavg: 1.4
                                            }
                                        },
                                        {
                                            sensorno: 2,
                                            sensorname: "Air Temp",
                                            sensorunit: "°C",
                                            data: [8, 18, 12, 12, 25, 13, 12, 10, 15, 12, 14, 13, 15],
                                            monitoringdata: {
                                                dates: getDates(
                                                    new Date('5-6-2019'),
                                                    new Date('5-6-2021'),
                                                    1000 * 60 * 60 * 24 * 30
                                                  ),
                                                dataset: [545, 500, 650, 727, 773, 705, 686, 501, 643, 580, 604, 615, 755, 722,
                                                    727, 816, 836, 952, 719, 758, 937, 785, 872, 850, 800],
                                                current: 30,
                                                min: 25,
                                                max: 35,
                                                dayavg: 30,
                                                nightavg: 20
                                            }
                                        },
                                        {
                                            sensorno: 3,
                                            sensorname: "Humidity",
                                            sensorunit: "%",
                                            data: [8, 15, 12, 10, 13, 12, 14, 18, 12, 12, 25, 13, 15],
                                            monitoringdata: {
                                                dates: getDates(
                                                    new Date('5-6-2019'),
                                                    new Date('5-6-2021'),
                                                    1000 * 60 * 60 * 24 * 30
                                                  ),
                                                dataset: [545, 400, 450, 627, 473, 450, 460, 780, 770, 800, 504, 550, 500, 530,
                                                    727, 716, 736, 820, 719, 758, 737, 885, 872, 850, 800],
                                                current: 80,
                                                min: 70,
                                                max: 85,
                                                dayavg: 80,
                                                nightavg: 80
                                            }
                                        },
                                        {
                                            sensorno: 4,
                                            sensorname: "Dew Pt",
                                            sensorunit: "°C",
                                            data: [8, 18, 12, 12, 25, 13, 12, 10, 13, 15, 15, 12, 14],
                                            monitoringdata: {
                                                dates: getDates(
                                                    new Date('5-6-2019'),
                                                    new Date('5-6-2021'),
                                                    1000 * 60 * 60 * 24 * 30
                                                  ),
                                                dataset: [545, 400, 450, 627, 673, 605, 686, 501, 843, 518, 504, 715, 955, 622,
                                                    627, 716, 736, 952, 619, 558, 937, 785, 872, 550, 400],
                                                current: 13,
                                                min: 11,
                                                max: 15,
                                                dayavg: 12,
                                                nightavg: 12
                                            }
                                        }, 
                                    ] 
                                },
                                {
                                    productno: 2,
                                    productid: "FALI100103",
                                    productname: "Lighting Environment",
                                    sensors: [
                                        {
                                            sensorno: 1,
                                            sensorname: "Light Intensity",
                                            sensorunit: "lux",
                                            monitoringdata: {
                                                dates: getDates(
                                                    new Date('5-6-2019'),
                                                    new Date('5-6-2021'),
                                                    1000 * 60 * 60 * 24 * 30
                                                  ),
                                                dataset: [645, 500, 550, 550, 473, 405, 286, 601, 743, 450, 604, 815, 855, 722,
                                                    700, 896, 866, 952, 719, 558, 737, 885, 972, 650, 600],
                                                current: 1200,
                                                min: 1100,
                                                max: 1300,
                                                dayavg: 1200,
                                                nightavg: 1150
                                            }
                                        },
                                        {
                                            sensorno: 2,
                                            sensorname: "Par",
                                            sensorunit: "PPFD",
                                            monitoringdata: {
                                                dates: getDates(
                                                    new Date('5-6-2019'),
                                                    new Date('5-6-2021'),
                                                    1000 * 60 * 60 * 24 * 30
                                                  ),
                                                dataset: [545, 500, 650, 727, 773, 705, 686, 501, 643, 580, 604, 615, 755, 722,
                                                    727, 816, 836, 952, 719, 758, 937, 785, 872, 850, 800],
                                                current: 1000,
                                                min: 900,
                                                max: 1100,
                                                dayavg: 1000,
                                                nightavg: 950
                                            }
                                        }
                                    ] 
                                },
                                {
                                    productno: 3,
                                    productid: "FACO100103",
                                    productname: "CO2 Monitoring"
                                },
                                {
                                    productno: 4,
                                    productname: "Relay Switches",
                                    devices: [
                                        {
                                            deviceno: 1,
                                            deviceid: "REL1011XXQ",
                                            switchname: "Grow Lights 1",
                                            forceon: false,
                                            automode: false
                                        },
                                        {
                                            deviceno: 2,
                                            deviceid: "REL1022XXQ",
                                            switchname: "Pump 1 HP",
                                            forceon: false,
                                            automode: false
                                        },
                                        {
                                            deviceno: 3,
                                            deviceid: "REL1033XXQ",
                                            switchname: "Grow Lights 2",
                                            forceon: false,
                                            automode: false
                                        },
                                        {
                                            deviceno: 4,
                                            deviceid: "REL1033XXQ",
                                            switchname: "Grow Lights 2",
                                            forceon: false,
                                            automode: false
                                        }
                                    ]   
                                }
                            ]
                        },
                        {
                            typeno: 2,
                            typename: "Nutrient Management",
                            typeurl: "nutrient-management",
                        }
                    ]
                },
                {
                    zoneno: 2,
                    zoneurl: "zone-2",
                    zonename: "Zone 2",
                    types:[
                        {
                            typeno: 1,
                            typename: "Farm Management",
                            productCategory: [
                                {
                                }
                            ]
                        },
                        {
                            typeno: 2,
                            typename: "Nutrient Management" 
                        }
                    ]
                }
            ]
        }
    },
    {
        postalid: 2,
        postalcode: {
            code: 400059,
            zone: [

            ]
        }
    },
    {
        postalid: 3,
        postalcode: {
            code: 400060,
            zone: [

            ]
        }
    },
]

export default climatedata;
import React, { useReducer } from 'react'
import DashboardContext from 'Context/DashboardContext'
import { dashboardReducer } from 'reducers/dashboardReducer'

const DashboardProvider = (props) => {

    const dashboardStateInit = {
        zoneRoutes: [{
            name: 'My Farm',
            active: true,
            icon: 'chart-pie',
            children: [
            ]
        }],
        activeZone: {},
        zoneList: [],
        farmDetails: {}
    }
    const [dashboardState, dispatchDashboardState] = useReducer(dashboardReducer, dashboardStateInit)

    return (
        <DashboardContext.Provider value={{ dashboardState, dispatchDashboardState }}>
            {props.children}
        </DashboardContext.Provider>
    )
}

export default DashboardProvider
import FarmAnalytics from 'Pages/Farms/DashboardContent/FarmEnvironmentAnalyticsPage'
import FarmConfig from 'Pages/Farms/DashboardContent/FarmEnvironmentConfigPage'
import RelayConfiguration from 'Pages/Farms/DashboardContent/RelayConfiguration'
import FarmDetails from 'Pages/Farms/DashboardContent/user/farmSettings/FarmDetails'
import FarmSettings from 'Pages/Farms/DashboardContent/user/farmSettings/FarmSettings'
import Permissions from 'Pages/Farms/DashboardContent/user/Permissions/Permission'
import Profile from 'Pages/Farms/DashboardContent/user/profile/Profile'
import ZoneDetails from 'Pages/Farms/DashboardContent/ZoneDetails'
import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import AddZone from 'Pages/Farms/DashboardContent/Zones/AddZone'

const DashboardRoutes = () => {
  
    return (
        <Switch>
            <Route path='/dashboard/:zonename/farm-management/farm-environment/farm-config' exact component={FarmConfig} />
            <Route path='/dashboard/:zonename/farm-management/farm-environment/farm-analytics' exact component={FarmAnalytics} />
            <Route path='/dashboard/:zonename/farm-management/relay-switch/relay-config' exact component={RelayConfiguration} />
            <Route path='/dashboard/:zonename/farm-management/zone-details' exact component={ZoneDetails} />
            <Route path='/dashboard/user/profile' exact component={Profile} />
            <Route path='/dashboard/user/farm-settings' exact component={FarmSettings} />
            <Route path='/dashboard/user/farm-settings/farm-details' exact component={FarmDetails} />
            <Route path='/dashboard/user/permissions' exact component={Permissions} />
            <Route path='/dashboard/addzone' exact component={AddZone} />
            <Route render={() => <Redirect to='/dashboard' />} />
        </Switch>
    )
}

export default DashboardRoutes
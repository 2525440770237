import React, { useState, useEffect, useContext, createContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Card, Col, Form, FormControl, Row } from 'react-bootstrap';
import BackTop from '../BackTop';
import BackButton from '../FarmEnvironmentConfigPage/BackButton';
import RelayConfigIntro from './RelayConfigIntro';
import EditDeviceSettings from './EditDeviceSettings/EditDeviceSettings';
import { EditDeviceSettingsData } from './EditDeviceSettings/EditDeviceSettingsdata';
import AddDeviceForm from './AddDeviceForm';
import RemoveDeviceForm from './RemoveDeviceForm';
import GeneralSettings from '../FarmEnvironmentConfigPage/GeneralSettings';
import GeneralSettingsForm from './GeneralSettingsForm';
import LatituteRelayIntro from './LatituteRelayIntro';
import Triggers from './Triggers';
import TimeBasedTrigger from './TimeBasedTrigger';
import SensorBasedTrigger from './SensorBasedTrigger';
import RelaySwitches from '../RelaySwitches';
import { useParams } from 'react-router-dom';
import { ZoneContext } from '..';
import AuthContext from 'Context/AuthContext';
import { getDeviceDetails } from 'API/hydroponicsFarmsAPI';
import Disabled from './Disabled';
import AutomationChannelForm from './AutomationChannelForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RelayConfigContext = createContext();

const RelayConfiguration = (props) => {
  const [ProductID, setProductID] = useState('');
  
  const [disabled, setDisabled] = useState(false);
  const [generalDevice, setGeneralDevice] = useState({
    deviceName: '',
    purpose: '',
    ratedPower: '',
    noOfLoads: '',
}); 

  const [triggerType, setTrigger] = useState();
  const zoneData = useLocation();
  const {multiDeviceChannel}  = zoneData.state;
  console.log("chikd",multiDeviceChannel);
  const { authState } = useContext(AuthContext);
  const [data, setData] = useState({
    zoneData: zoneData.state.params,
    deviceData: {},
  });
  const [addDevice, setAddDevice] = useState(true);

  const sensorState = zoneData.state.sensor_state;
  const [channel,setChannel] = useState(0);
  console.log(zoneData,'Zone ka data')
  const loadDeviceDetails = async (deviceId) => {
    let resData = await getDeviceDetails(authState.accessToken, { deviceId });
    console.log('resData.data');
    console.log(resData.data);
    if (resData.success) {
      setData({
        ...data,
        deviceData: resData.data,
      });
      
    }
  };

  useEffect(() => {
    setData({
      zoneData: zoneData.state.params,
      deviceData: {},
    });
    
    if (zoneData.state.specificRelayId) {
      loadDeviceDetails(zoneData.state.specificRelayId);
    }
  }, [zoneData]);
  useEffect(() => {
    
    if (zoneData.state.settingRelay) {
      setDisabled(zoneData.state.deviceStatus)
      setAddDevice(false)
    }
  }, [zoneData]);
  

  console.log("zone124",zoneData,channel,generalDevice);



  return (
    <RelayConfigContext.Provider value={data}>
      <BackTop />
      <Row className="g-3 mb-0">
        <Col xxl={9}>
          <BackButton />
          <RelayConfigIntro />
        </Col>
      </Row>
      <Row className="g-3 mb-0">
        <Col xxl={6} lg={12}>
          <EditDeviceSettings
            status={zoneData.state.deviceStatus}
            network={zoneData.state.network}
            RelayDeviceId={zoneData.state.specificRelayId}
            deviceData={generalDevice}
          />
        </Col>
      </Row>
      <Row className="g-3 mb-0">
        <Col xxl={9}>
          {addDevice ? <AddDeviceForm stateChanger={setAddDevice} /> : ""}
          {addDevice ? (
            " "
          ) : (
            <RemoveDeviceForm
              stateChanger={setProductID}
              RelayDeviceId={zoneData.state.specificRelayId}
            />
          )}
          {!addDevice && multiDeviceChannel && multiDeviceChannel.length && (
            <AutomationChannelForm
              multiDeviceChannel={multiDeviceChannel}
              stateChanger={setChannel}
              deviceData={channel}
            />
          )}
          {addDevice ? (
            " "
          ) : (
            <GeneralSettings
              channel={channel}
              multiDeviceChannel={multiDeviceChannel}
            />
          )}
          {addDevice ? (
            " "
          ) : (
            <GeneralSettingsForm
              channel={channel}
              stateChanger={setGeneralDevice}
              RelayDeviceId={zoneData.state.specificRelayId}
            />
          )}
          {!addDevice && multiDeviceChannel && multiDeviceChannel.length && (
            <Card className="bg-light my-3">
            <Card.Body className="p-3 position-relative">
              <Row className="align-items-center row">
                <Col className="order-1 col-12 col-lg-9 col-md-8 col-sm-7">
                  <p className="fs--1 mb-0 text-left text-primary">
                    <FontAwesomeIcon
                      icon="exchange-alt"
                      className="me-2"
                      transform="rotate-90"
                    />
                    Your current active load on this relay switch is
                    <strong className="">  {(generalDevice.ratedPower * generalDevice.noOfLoads )/1000} kW </strong>
                    {props && props.multiDeviceChannel && (
                      <span>
                        {"  "}
                        for "<strong className='fs--1'>Channel</strong>" {parseInt(props.channel)+1} Automation Panel
                      </span>
                    )}
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          )}
          <Disabled disabled={disabled}>
            {addDevice ? (
              " "
            ) : (
              <Triggers
                channel={channel}
                multiDeviceChannel={multiDeviceChannel}
                triggerChanger={setTrigger}
                deviceData={generalDevice}
                RelayDeviceId={zoneData.state.specificRelayId}
              />
            )}
            {console.log(generalDevice, "gen1")}
            {triggerType == 1 ? (
              <TimeBasedTrigger
                channel={channel}
                deviceData={generalDevice}
                RelayDeviceId={zoneData.state.specificRelayId}
              />
            ) : (
              ""
            )}
            {triggerType == 2 ? (
              <SensorBasedTrigger
                channel={channel}
                sensorState={sensorState}
                deviceData={generalDevice}
                RelayDeviceId={zoneData.state.specificRelayId}
              />
            ) : (
              ""
            )}
          </Disabled>
        </Col>
      </Row>
    </RelayConfigContext.Provider>
  );
};

export default RelayConfiguration;
export { RelayConfigContext };

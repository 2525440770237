import React, { useState, useContext, useEffect } from 'react';
import { Button, Col, Form, FormControl, Spinner } from 'react-bootstrap';
import { Card, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DashboardContext from 'Context/DashboardContext';
import { ZoneConfigContext } from '.';
import { addDevice } from 'API/hydroponicsFarmsAPI';
import AuthContext from 'Context/AuthContext';
import { useHistory } from 'react-router-dom';

const AddDeviceForm = () => {
  const { dashboardState } = useContext(DashboardContext);
  const zoneData = useContext(ZoneConfigContext);
  const { authState } = useContext(AuthContext);

  const history = useHistory();

  const [formData, setFormData] = useState({
    deviceId: '',
    deviceName: '',
    farmId: dashboardState.farmDetails._id
      ? dashboardState.farmDetails._id
      : '',
    zoneId: zoneData.zoneData.zoneId ? zoneData.zoneData.zoneId : '',
  });
  const [error, setError] = useState('');
  const [NameError, setNameError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleFieldChange = (e) => {
    let value = e.target.value;
    if (e.target.name === 'deviceId') {
      value = value.toUpperCase();
    }
    setFormData({
      ...formData,
      [e.target.name]: value,
    });
    setNameError(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    if (formData.deviceName && formData.deviceName.length > 10) {
      setNameError(true);
      return;
    }
    setNameError(false);

    setIsLoading(true);
    setError('');
    let resData = await addDevice(authState.accessToken, formData);
    if (resData.success) {
      setIsLoading(false);
      setError('');
      history.push('/dashboard');
      history.go(0);
    }    if (resData.error) {
      setIsLoading(false);
      setError(resData.error);
    }
    // setError('Invalid Product ID!');
  };

  useEffect(() => {
    setFormData((prevState) => {
      return {
        ...prevState,
        farmId: dashboardState.farmDetails._id
          ? dashboardState.farmDetails._id
          : '',
        zoneId: zoneData.zoneData.zoneId ? zoneData.zoneData.zoneId : '',
      };
    });
  }, [dashboardState, zoneData]);

  return (
    <>
      <Card className='bg-light my-3'>
        <Card.Body className='p-3 position-relative'>
          <Form className='' onSubmit={onSubmit}>
            <Row className='w-100 mb-2'>
              <Col>
                <Form.Text className='text-nowrap me-2 mt-1 text-primary'>
                  <FontAwesomeIcon icon='plus' className='fs-0 me-1' />
                  <strong className='fs-0'>Add Device</strong>
                </Form.Text>
              </Col>
            </Row>
            <div className=''>
              <Row className='mb-2'>
                <Col>
                  <FormControl
                    className='fs-0 mr-2 '
                    placeholder='Enter Product ID'
                    aria-label='Product ID'
                    aria-describedby='basic-addon2'
                    name='deviceId'
                    onChange={handleFieldChange}
                    value={formData.deviceId}
                    required
                  />
                </Col>
              </Row>
              <Row className='mb-2'>
                <Col>
                  <FormControl
                    className='fs-0 mr-2 '
                    placeholder='Device Name'
                    aria-label='Product ID'
                    aria-describedby='basic-addon2'
                    name='deviceName'
                    onChange={handleFieldChange}
                    value={formData.deviceName}
                    isInvalid={NameError}
                    required
                  />
                  <FormControl.Feedback type='invalid'>
                    Device Name should be less than 10 characters
                  </FormControl.Feedback>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    className='btn btn-primary text-nowrap ml-2'
                    id='button-addon2'
                    type='submit'
                    style={{ float: 'right' }}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Spinner animation='border' size='sm' />
                    ) : (
                      <>
                        <span className='d-none d-sm-block'>Confirm</span>
                        <span className='d-sm-none d-block'>Add</span>
                      </>
                    )}
                  </Button>
                </Col>
              </Row>
            </div>
            <Row className='w-100 mt-2'>
              <span className='fs-0 text-danger'>{error ? error : ''}</span>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default AddDeviceForm;

import FalconCardHeader from 'Components/common/FalconCardHeader';
import React, { useContext, useState } from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import { changePassword } from 'API/profileAPI';
import AuthContext from 'Context/AuthContext';
import { toast } from 'react-toastify';

const ChangePassword = () => {
  const { authState } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isLoading, setIsLoading] = useState(false);


  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let resData = await changePassword(authState.accessToken, formData);
    if (resData.success) {
      toast.success("Password changed successfully");
      setFormData({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      });
    }
    else {
      toast.error(resData.error);
    }
    setIsLoading(false)

  };

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Change Password" />
      <Card.Body className="bg-light">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="oldPassword">
            <Form.Label>Old Password</Form.Label>
            <div className=" d-flex align-items-center">
              <Form.Control
                value={formData.oldPassword}
                name="oldPassword"
                onChange={handleChange}
                type={showPassword ? 'text' : 'password'}
                required
              />
              <FontAwesomeIcon className="mx-2" type='button' icon={!showPassword ? 'eye' : faEyeSlash} onClick={() => setShowPassword(!showPassword)} />
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="newPassword">
            <Form.Label>New Password</Form.Label>
            <div className=" d-flex align-items-center">
              <Form.Control
                type={showNewPassword ? 'text' : 'password'}
                value={formData.newPassword}
                name="newPassword"
                onChange={handleChange}
                required
                isInvalid={formData.newPassword.length < 8 && formData.newPassword.length > 0}
              />
              <FontAwesomeIcon className="mx-2" type='button' icon={!showNewPassword ? 'eye' : faEyeSlash} onClick={() => setShowNewPassword(!showNewPassword)} />
            </div>
            <Form.Control.Feedback type="invalid" > Password should be atleast 8 characters </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="confirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <div className=" d-flex align-items-center">
              <Form.Control
                type={showConfirmPassword ? 'text' : 'password'}
                value={formData.confirmPassword}
                name="confirmPassword"
                onChange={handleChange}
                required

                isInvalid={formData.newPassword !== formData.confirmPassword && formData.confirmPassword !== ''}
              />
              <FontAwesomeIcon className="mx-2" type='button' icon={!showConfirmPassword ? 'eye' : faEyeSlash} onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
            </div>
          </Form.Group>
          <Button className="w-100" type="submit" disabled={isLoading}>
            {
              isLoading ?
                <Spinner animation="border" variant="light" /> :
                'Update Password'}
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ChangePassword;

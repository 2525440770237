import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import avatarimg from 'assets/img/team/avatar.png';
import Avatar from 'Components/common/Avatar';

const ProfileDropdown = () => {
  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        // as={Link}
        // to="#"
        className="pe-0 nav-link bg-transparent border-0 shadow-none pb-0"
      >
        <Avatar src={avatarimg} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item as={Link} to="/dashboard/user/profile">
            User Profile
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/dashboard/user/farm-settings">
            Farm Settings
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/dashboard/user/permissions">
            User Permissions
          </Dropdown.Item>
          {/* <Dropdown.Item href="#!">Feedback</Dropdown.Item> */}
          <Dropdown.Divider />
          {/* <Dropdown.Item as={Link} to="/pages/settings">
            Settings
          </Dropdown.Item> */}
          <Dropdown.Item as={Link} to="/auth/logout">
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;

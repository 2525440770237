import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  Col,
  Row,
  Form,
  FormControl,
  DropdownButton,
  Dropdown,
  InputGroup,
  Button,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import { duration } from 'dayjs';
import moment from 'moment';
import { useAlert } from 'react-alert';
import {
  getDeviceDetails,
  getRelayDeviceDetails,
  updateTriggerDetails,
  updateTriggerDetails2,
} from 'API/hydroponicsFarmsAPI';
import AuthContext from 'Context/AuthContext';
import DashboardContext from 'Context/DashboardContext';
import { useHistory } from 'react-router-dom';

const TimeBasedTrigger = (props) => {
  const [timeTrigger, setTimeTrigger] = useState({
    runTrigger: '',
    durationTime: 'Hours',
    durationValue: '',
    onCustomDuration: '',
    offCustomDuration: '',
    onCustomDurationTime: 'hr',
    offCustomDurationTime: 'hr',
  });
  const { dashboardState } = useContext(DashboardContext);
  console.log(dashboardState,'asydgguasydgygdagsdukashdasdjasldjilj')
  const history = useHistory();

  const generalDevice = props.deviceData;
  const { isMultiChannelDevice, channel } = props;

  let n = 1;
  const {authState} = useContext(AuthContext);
  const [date, setDate] = useState();
  const [triggerDailyAtList, setTriggerDailyAtList] = useState([{ key: 0, StartTime: '', EndTime: '' }]);

  const loadDeviceDetails = async (deviceId) => {
    let resData = await getRelayDeviceDetails(authState.accessToken, {
      deviceId,
    });
    console.log('resData.data');

    if (resData.success) {
      if (resData.data[0].sensor_trigger !== undefined) {
      } else if (resData.data[0].time_trigger !== undefined) {
        setTimeTrigger((preValue) => {
          return {
            ...preValue,
            runTrigger: resData.data[0].time_trigger.run_trigger,
          };
        });
        if (resData.data[0].time_trigger.run_trigger[0] == 'D') {
          let p = 1;
          let temArray;
          if(triggerDailyAtList.length == 1){
            temArray = []
          }
          else{
            temArray = [...triggerDailyAtList];
          }
          
          resData.data[0].time_trigger.daily_trigger.map((e, p) => {
            var date1 = new Date(
              null,
              null,
              null,
              e[0].at(2) == ':' ? e[0].slice(0, 2) : e[0].slice(0, 1),
              e[0].at(-3) == ':' ? e[0].slice(-2) : e[0].slice(-1)
            );
            var date2 = new Date(
              null,
              null,
              null,
              e[1].at(2) == ':' ? e[1].slice(0, 2) : e[1].slice(0, 1),
              e[1].at(-3) == ':' ? e[1].slice(-2) : e[1].slice(-1)
            );
            p = p++;

            temArray.push({ key: p, StartTime: date1, EndTime: date2 });
          });
         
          setTriggerDailyAtList(temArray);
        } else if (resData.data[0].time_trigger.run_trigger == 'Custom') {
          console.log('babyxBae');
          let i = 0,
            j = 0;
          console.log(resData.data[0].time_trigger.on_duration);
          for (
            i = 0;
            i < resData.data[0].time_trigger.on_duration.length;
            i++
          ) {
            if (resData.data[0].time_trigger.on_duration[i] == '*') {
              break;
            }
          }
          let onDurationTemp = resData.data[0].time_trigger.on_duration.slice(
            0,
            i
          );
          let onDurationTimeTemp =
            resData.data[0].time_trigger.on_duration.slice(
              i + 1,
              resData.data[0].time_trigger.on_duration.length
            );
          console.log(onDurationTemp);
          for (
            j = 0;
            j < resData.data[0].time_trigger.off_duration.length;
            j++
          ) {
            if (resData.data[0].time_trigger.off_duration[j] == '*') {
              break;
            }
          }
          let offDurationTemp = resData.data[0].time_trigger.off_duration.slice(
            0,
            j
          );
          let offDurationTimeTemp =
            resData.data[0].time_trigger.off_duration.slice(
              j + 1,
              resData.data[0].time_trigger.off_duration.length
            );

          setTimeTrigger((preValue) => {
            return {
              ...preValue,
              onCustomDuration: onDurationTemp,
              offCustomDuration: offDurationTemp,
              onCustomDurationTime: onDurationTimeTemp,
              offCustomDurationTime: offDurationTimeTemp,
            };
          });
        }
      }
    }
  };
  useEffect(() => {
    loadDeviceDetails(props.RelayDeviceId);
  }, [date]);

  function keyValueIncrement(triggerDailyAtList) {
    let incrementCounter = 0;
    triggerDailyAtList.map((e) => {
      e.key = incrementCounter;
      incrementCounter++;
    });
  }
  
  const endTimeValidation = (startTime,endtime) =>{
    console.log(startTime,endtime)
    if(startTime=='' || endtime == ''){
      return 1;
    }
    else if(endtime > startTime){
      return 1;
    }
    
    else{return 0;}
    
    
  }
  const addTriggerConditionCheck = (triggerDailyAtList) =>{
     
      let temp = 0;
      let returnValue = 0;
      triggerDailyAtList.map((val)=>{
        val.startTime == '' || val.EndTime == '' ? temp =1:temp = temp;

      })
      temp ? console.log('fill all details'): returnValue = 1;
      return returnValue

  }

  const triggerAddClicked = (event) => {
    //console.log(event.target.selectedOptions[0].text);
    event.preventDefault();
    const returnValue = addTriggerConditionCheck(triggerDailyAtList);
    if(returnValue){
      
      let n = triggerDailyAtList.length;
      setTriggerDailyAtList([
        ...triggerDailyAtList,
        { key: n, StartTime: '', EndTime: '' },
      ]);
    }
    
    
  };
  const triggerRemoveClicked = (event, key) => {
    event.preventDefault();

    const index = key;
    console.log(index, 'kkj');
    if (index > -1) {
      let list6 = triggerDailyAtList.filter((item) => item.key !== index);
      console.log(triggerDailyAtList, 'list6');
      keyValueIncrement(list6);
      setTriggerDailyAtList([...list6]);
    }
  };
  const inputEvent = (event) => {
    //console.log(event.target.selectedOptions[0].text);
    event.preventDefault();
    const value = event.target.value;
    const name = event.target.name;
    console.log(value, name);
    setTimeTrigger((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };

  const inputStartDateEvent = (event, key) => {
    let newArr = [...triggerDailyAtList]; // copying the old datas array
    
    if(key >0 ){
      const diff = endTimeValidation(newArr[key-1].EndTime,event)
      if (diff === 0) return;
    }
   
    let response2 = endTimeValidation(event,newArr[key].EndTime)
    if(response2 == 1){
      newArr[key].StartTime = event;
      setTriggerDailyAtList(newArr);
    }
   
  };
  const inputEndDateEvent = (event, key) => {
    console.log(triggerDailyAtList, 'jkjkjkjjk');
    
    let newArr = [...triggerDailyAtList]; // copying the old datas array
    
    console.log(triggerDailyAtList, 'jkjkjkjjk');
    let response = endTimeValidation(newArr[key].StartTime,event)
    if(response == 1){
      newArr[key].EndTime = event;
      setTriggerDailyAtList(newArr);
    }
    
   
  };
  
  const onSubmit = async(event) => {
    event.preventDefault();
    console.log(timeTrigger, 'kakakakakkaak',authState);
    let pushDetails = [];
    let response;
    let onDuration =
      timeTrigger.onCustomDuration !== ''
        ? timeTrigger.onCustomDuration + '*' + timeTrigger.onCustomDurationTime
        : '';
    let offDuration =
      timeTrigger.offCustomDuration !== ''
        ? timeTrigger.offCustomDuration +
          '*' +
          timeTrigger.offCustomDurationTime
        : '';

    if (timeTrigger.runTrigger == 'Daily at') {
      triggerDailyAtList.map((e) => {
        let temp = [];
        let time = e.StartTime;
        let time2 = e.EndTime;
        temp.push(time.getHours() + ':' + time.getMinutes());
        temp.push(time2.getHours() + ':' + time2.getMinutes());
        pushDetails.push(temp);
      });
      response = await updateTriggerDetails(authState.accessToken, {
        name: generalDevice.deviceName,
        purpose: generalDevice.purpose,
        ratedPower: generalDevice.ratedPower,
        noOfLoads: generalDevice.noOfLoads,
        pushDetails: pushDetails,
        id: props.RelayDeviceId,
        channel
      });
    } else if (
      timeTrigger.runTrigger[0] == 'E' ||
      timeTrigger.runTrigger == 'Custom'
    ) {
      console.log('oh yeah', timeTrigger.onCustomDuration, offDuration);
      response = await updateTriggerDetails2(authState.accessToken, {
        name: generalDevice.deviceName,
        purpose: generalDevice.purpose,
        ratedPower: generalDevice.ratedPower,
        noOfLoads: generalDevice.noOfLoads,
        pushDetails: timeTrigger.runTrigger,
        onDuration: onDuration,
        offDuration: offDuration,
        id: props.RelayDeviceId,
        channel
      });
    }

   if(response.success==true){
    history.push('/dashboard/'+dashboardState.activeZone.name.trim()+'/farm-management');
    
   }
  };

  console.log({triggerDailyAtList})
  return (
    <>
      <Card className='bg-light my-3'>
        <Card.Body className='p-3 position-relative'>
          <Row className='align-items-center row'>
            <Col xs-auto='true'>
              <p className=' mb-3 text-left text-primary'>
                <strong className='fs-0'>Time-based Trigger | Activate</strong>
              </p>
            </Col>
          </Row>
          <Row className='align-items-center row'>
            <Form onSubmit={onSubmit}>
              <Row className='no-gutters justify-content-start'>
                <Col className='col-4 col-sm-2'>
                  <Form.Text className='text-nowrap me-2 mb-3 text-primary fs-0'>
                    Run Trigger
                    <FontAwesomeIcon icon='plus' className='fs--1 ms-1' />
                  </Form.Text>
                </Col>
                <Col className='col-8 col-sm-4 mt-0'>
                  <select
                    className='form-select fs--1'
                    aria-label='Default select example'
                    name='runTrigger'
                    onChange={inputEvent}
                    value={timeTrigger.runTrigger}
                  >
                    <option value='' disabled selected>
                      --choose--
                    </option>
                    <option value='Every 5 mins'>Every 5 minutes</option>
                    <option value='Every 10 mins'>Every 10 minutes</option>
                    <option value='Every 10 mins'>Every 15 minutes</option>
                    <option value='Every 30 mins'>Every 30 minutes</option>
                    <option value='Every hour'>Every Hour</option>
                    <option value='Daily at'>Daily at</option>
                    <option value='Custom'>Custom</option>
                  </select>
                </Col>
                <div className='w-100'></div>
                {timeTrigger.runTrigger == 'Daily at' ? (
                  <>
                    {triggerDailyAtList.length ? (
                      <>
                        {' '}
                        {triggerDailyAtList.map((e, n) => (
                          <>
                            {/* <Col className = 'col-4 col-sm-2 mt-2'>Trigger 1</Col>
                     <div className='w-100'></div>
                     
                     <div className = 'd-flex flex-row'>
                     <Form.Text className='text-nowrap me-2 text-primary fs-0'>Start Time
                        </Form.Text>
                        
                        <Col className={`fs--1  ${timeTrigger.runTrigger=='Hourly at' || timeTrigger.runTrigger=='Daily at' ? 'd-inline-block' : 'd-none'}`}>
                        <DatePicker
                            selected={date}
                            // onChange={(date)=>setDate(date)}
                            name='date'
                            onChange={inputDateEvent} value={date}
                            className='form-control fs--1'
                            placeholderText='Select Time'
                            timeIntervals={5}
                            dateFormat='h:mm aa'
                            showTimeSelect
                            showTimeSelectOnly
                            disabled={timeTrigger.runTrigger=='Hourly at' || timeTrigger.runTrigger=='Daily at' ? '' : 'disabled'}
                            />
                        </Col>
                        </div>
                        
                        <div className = 'd-flex flex-row'>
                     <Form.Text className='text-nowrap me-2 text-primary fs-0'>End Time
                        </Form.Text>
                        
                        <Col className={`fs--1  ${timeTrigger.runTrigger=='Hourly at' || timeTrigger.runTrigger=='Daily at' ? 'd-inline-block' : 'd-none'}`}>
                        <DatePicker
                            selected={date}
                            // onChange={(date)=>setDate(date)}
                            name='date'
                            onChange={inputDateEvent} value={date}
                            className='form-control fs--1'
                            placeholderText='Select Time'
                            timeIntervals={5}
                            dateFormat='h:mm aa'
                            showTimeSelect
                            showTimeSelectOnly
                            disabled={timeTrigger.runTrigger=='Hourly at' || timeTrigger.runTrigger=='Daily at' ? '' : 'disabled'}
                            />
                        </Col>
                        </div>
                        <div className='w-100'></div> */}
                            {/* <Row className='align-items-center'>
                          <Col className='col-4 col-sm-2 mt-2'>Trigger 1</Col>
                          <Col
                            xs='auto'
                            className='ms-auto me-auto align-items-center'
                          ></Col>     
                        </Row> */}
                            <Row className='align-items-center mt-3'>
                              <Col className='col-4 col-sm-2 mt-4'>
                                Trigger {n + 1}
                              </Col>

                              <Col
                                xs='auto'

                                style={{display:"flex",alignItems:"center",marginLeft:"3rem"}}
                                className='ms-auto me-sm-6 me-md-9 mt-4 '
                              >
                                <FontAwesomeIcon
                                  className='ms-auto'
                                  icon='times'
                                  cursor={'pointer'}
                                  style={{marginRight:'1rem' , display:"flex",alignItems:"center"}}
                                  onClick={(event) =>
                                    triggerRemoveClicked(event, e.key)
                                  }
                                />
                                {/* <Button
                              className='px-2 me-1 fs-sm-0 fs-0'
                              style={{ borderRadius: '200%'}}
                              
                              
                              onClick={(event) =>
                                triggerRemoveClicked(event, e.key)
                              }
                            >
                              
                            </Button> */}
                              </Col>
                            </Row>
                            <div className='w-100'></div>
                            <Row className='no-gutters justify-content-start mt-2'>
                              <Col className='col-4 col-sm-2'>
                                <Form.Text className='text-nowrap text-primary fs-0'>
                                  Start Time
                                  {/* <strong className='fs-0'>Device Name</strong> */}
                                </Form.Text>
                              </Col>
                              <Col className='col-8'>
                                <DatePicker
                                  selected={e.StartTime}
                                  // onChange={(date)=>setDate(date)}
                                  
                                  name='date'
                                  onChange={(event) =>
                                    inputStartDateEvent(event, e.key)
                                  }
                                  value={date}
                                  className='form-control fs--1'
                                  placeholderText='Select Time'
                                  timeIntervals={5}
                                  dateFormat='h:mm aa'
                                  showTimeSelect
                                  showTimeSelectOnly
                                />
                              </Col>
                              <div className='w-100'></div>
                              <Col className='col-4 col-sm-2 mt-2'>
                                <Form.Text className='text-nowrap mt-1 text-primary fs-0'>
                                  {' '}
                                  End Time
                                  {/* <strong className='fs-0'>Purpose</strong> */}
                                </Form.Text>
                              </Col>

                              <Col className='col-8 mt-2'>
                                <DatePicker
                                  // onChange={(date)=>setDate(date)}
                                  selected={e.EndTime}
                                  onChange={(event) =>
                                    inputEndDateEvent(event, e.key)
                                  }
                                  // minTime={n> 0 ? new Date(`${triggerDailyAtList[n-1].EndTime}`) : new Date()}
                                  value={date}
                                  className='form-control fs--1'
                                  placeholderText='Select Time'
                                  timeIntervals={5}
                                  dateFormat='h:mm aa'
                                  showTimeSelect
                                  showTimeSelectOnly
                                  disabled={
                                    timeTrigger.runTrigger == 'Hourly at' ||
                                    timeTrigger.runTrigger == 'Daily at'
                                      ? ''
                                      : 'disabled'
                                  }
                                />
                              </Col>
                              <div className='w-100'></div>
                            </Row>
                          </>
                        ))}
                        <Row className='align-items-center'>
                          <Col
                            xs='auto'
                            className=' me-md-9 me-2 mt-4 col-sm-2 col-5 align-items-center'
                            style={{marginLeft:"auto",padding:"0"}}
                            
                          >
                            
                            <Form.Text className='text-nowrap me-md-3 me-1 mt-3  ' >
                            <button className='btn btn-outline-secondary me-md-4 me-1 mb-1' style={{margin:"0"}} type='button' onClick={triggerAddClicked}>
                              <span className=' me-2' style={{fontSize:"0.9rem"}}>Add Trigger</span>
                              
                              <FontAwesomeIcon
                                className='ms-auto me-md-1'
                                icon='plus'
                                cursor={'pointer'}
                                
                              />
                              </button>
                            </Form.Text>
                            
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <Row className='align-items-center'>
                        <Col className='me-md-9 me-1 mt-1 align-items-center'>
                          <Form.Text className='text-nowrap  mt-5 d-none d-sm-block '>
                            <span className='fs-0 me-2 ' style={{fontSize:"0.9rem"}}>Add Trigger</span>
                            <span> </span>
                            <Button
                              className='px-2 me-1 fs-sm-0 fs-0'
                              style={{ borderRadius: '100%' }}
                              variant='falcon-primary'
                              onClick={triggerAddClicked}
                            >
                              <FontAwesomeIcon icon='plus' className='fs-0' />
                            </Button>
                          </Form.Text>
                        </Col>
                      </Row>
                    )}
                  </>
                ) : (
                  ''
                )}
                <Row className='align-items-center'>
                  <div className='w-100'></div>
                  {timeTrigger.runTrigger == 'Custom' ? (
                    <>
                      <Col className='col-4 col-sm-2 mt-4'>
                        <Form.Text className='text-nowrap mt-1 text-primary fs-0'>
                          On Duration
                        </Form.Text>
                      </Col>

                      <Col className='col-8 col-sm-8 mt-4 '>
                        <InputGroup>
                          <FormControl
                            className='fs--1 me-1'
                            aria-label='number input with dropdown button'
                            name='onCustomDuration'
                            type='number'
                            value={timeTrigger.onCustomDuration}
                            onChange={inputEvent}
                          />
                          <select
                            className='form-select fs--1'
                            aria-label='Default select example'
                            name='onCustomDurationTime'
                            value={timeTrigger.onCustomDurationTime}
                            onChange={inputEvent}
                          >
                            <option className='' value='hr'>
                              Hours
                            </option>
                            <option className='' value='min'>
                              Minute
                            </option>
                          </select>
                        </InputGroup>
                      </Col>

                      <div className='w-100'></div>
                      <Col className='col-4 col-sm-2 mt-2'>
                        <Form.Text className='text-nowrap mt-1 text-primary fs-0'>
                          Off Duration
                        </Form.Text>
                      </Col>

                      <Col className='col-8 col-sm-8 mt-2 '>
                        <InputGroup>
                          <FormControl
                            className='fs--1 me-1'
                            aria-label='Text input with dropdown button'
                            name='offCustomDuration'
                            type='number'
                            value={timeTrigger.offCustomDuration}
                            onChange={inputEvent}
                          />
                          <select
                            className='form-select fs--1'
                            aria-label='Default select example'
                            name='offCustomDurationTime'
                            value={timeTrigger.offCustomDurationTime}
                            onChange={inputEvent}
                          >
                            <option className='' value='hr' selected>
                              Hours
                            </option>
                            <option className='' value='min'>
                              Minute
                            </option>
                          </select>
                        </InputGroup>
                      </Col>
                    </>
                  ) : (
                    ''
                  )}
                </Row>
                {/* {timeTrigger.runTrigger=='Daily at'  ? (
                        <Col className = 'col-4 col-sm-2 mt-2'>Trigger 1</Col>
                        ):''}
                        <div className='w-100'></div>
                        <Col className={`col-4 col-sm-2 mt-2 ${timeTrigger.runTrigger=='Hourly at' || timeTrigger.runTrigger=='Daily at' ? 'd-inline-block' : 'd-none'}`}
                        >
                        <Form.Text className='text-nowrap mt-3 text-primary fs-0'>Set Time
                        </Form.Text>
                        </Col>
                        <Col className={`col-8 col-sm-4 mt-2 ${timeTrigger.runTrigger=='Hourly at' || timeTrigger.runTrigger=='Daily at' ? 'd-inline-block' : 'd-none'}`}>
                        <DatePicker
                            selected={date}
                            // onChange={(date)=>setDate(date)}
                            name='date'
                            onChange={inputDateEvent} value={date}
                            className='form-control fs--1'
                            placeholderText='Select Time'
                            timeIntervals={5}
                            dateFormat='h:mm aa'
                            showTimeSelect
                            showTimeSelectOnly
                            disabled={timeTrigger.runTrigger=='Hourly at' || timeTrigger.runTrigger=='Daily at' ? '' : 'disabled'}
                            />
                        </Col>
                        <div className='w-100'></div>
                        {timeTrigger.runTrigger=='Daily at' ? (
                        <Col className='col-4 col-sm-2 mt-2'>
                        <Form.Text className='text-nowrap mt-1 text-primary fs-0'>On Duration
                        </Form.Text>
                        </Col>) : ''}
                        {timeTrigger.runTrigger=='Daily at' ? (
                        <Col className='col-8 col-sm-4 mt-2'>
                        <InputGroup>
                        <FormControl className='fs--1' aria-label='Text input with dropdown button'  name='durationValue'
                            onChange={inputEvent} value={timeTrigger.durationValue} type='number' />
                        <select className='form-select fs--1' aria-label='Default select example'  name='durationTime'
                            onChange={inputEvent} value={timeTrigger.durationTime}>
                            <option value='Hours' selected>Hours</option>
                            <option value='Minute'>Minute</option>
                        </select>
                        </InputGroup>
                        </Col>) : ''} */}
              </Row>
              {
                 timeTrigger.runTrigger !== 'Daily at'  &&
              
              <Row className='align-items-center row mt-3'>
                <h6 className='text-500 fs--2'>
                  Trigger Summary
                  <br/>
                  <br/>
                  <span style={{marginTop:"0rem"}}>
                  Run {props.data} relay switch for 
                  {timeTrigger.runTrigger === "Custom" && <span>{" "}
                  <strong style={{fontWeight:"bolder"}}>
                  {timeTrigger.onCustomDuration} {timeTrigger.onCustomDurationTime === 'hr'? "Hour " : "Minute "}
                  </strong>
                   and then turn it off again for the next{" "}
                   <strong style={{fontWeight:"bolder"}}>
                   {timeTrigger.offCustomDuration} {timeTrigger.offCustomDurationTime === 'hr'? "Hour":"Minute"}.
                   </strong>
                    {" "}Keep repeating this trigger in loop.

                    </span>} 
                    
                  </span>
                  {/* Run {props.data} relay switch {timeTrigger.runTrigger}{' '}
                  <span
                    className={`${
                      timeTrigger.runTrigger == 'Hourly at' ||
                      timeTrigger.runTrigger == 'Daily at'
                        ? 'd-inline-block'
                        : 'd-none'
                    }`}
                  >
                    {moment(date).format('LT')} for a duration of{' '}
                    {timeTrigger.durationValue} {timeTrigger.durationTime} and
                    then automatically turn it OFF .
                  </span>
                  <span
                    className={`${
                      timeTrigger.runTrigger == 'Every 5 minutes' ||
                      timeTrigger.runTrigger == 'Every 10 minutes' ||
                      timeTrigger.runTrigger == 'Every 15 minutes' ||
                      timeTrigger.runTrigger == 'Every 30 minutes' ||
                      timeTrigger.runTrigger == 'Hourly'
                        ? 'd-inline-block'
                        : 'd-none'
                    }`}
                  >
                  
                    Run {props.deviceData.purpose} relay switch for “every 10 minutes” and then turn it OFF again for the next 10 minutes. Keep repeating this trigger in loop.  
                  </span> */}
                </h6>
              </Row>
}
              <Row className='align-items-center row mt-2'>
                
                <Form.Check
                  className='ms-2'
                  type='checkbox'
                  id='defaultCheckbox'
                >
                  <Form.Check.Input
                    type='checkbox'
                    className='fs--2 mt-sm-2 mt-0'
                  />
                  
                  <Form.Check.Label className='text-500 fs--2 lh-sm'>
                    Send me a notification on Email/Whatsapp whenever this
                    trigger is activated.
                  </Form.Check.Label>
                </Form.Check>
              </Row>

              <Row className='align-items-center row mt-sm-3 mt-2 ms-1 me-1'>
                <button
                  type='submit' className='btn btn-primary btn-block text-nowrap'>
                  Save
                </button>
              </Row>
            </Form>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default TimeBasedTrigger;

import React from 'react'
import { Row, Col, Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";

const Failed = () => {
    const history = useHistory();

    const routeFinish = () => {
        history.push('/addFarm');
    }
    return (
        <>
            <Row>
                <Col className="text-center">
                    {/* <div className="wizard-lottie-wrapper">
                        <div className="wizard-lottie mx-auto" style={{ transform: "translateY(-50%)" }}>
                            <Lottie options={defaultOptions} />
                        </div>
                    </div> */}
                    <h4 className="mb-1 text-danger">Something went wrong! Please Try again later!</h4>
                    {/* <p className="fs-0">Now you can access to your Farms</p> */}
                    <Row>
                        <Col className='col-sm-6 col-12'>
                            <Button className="px-5 my-3 ms-sm-1 ms-0 mw-100" variant="primary" onClick={routeFinish}> Try Again </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default Failed
import React, { useState, useContext, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button,Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Intro = () => {

  return (

    <Card className="bg-light mb-3">
      <Card.Body className="p-3 position-relative">
        <Row className="align-items-center row">
            <Col className="order-1 col-12 col-lg-9 col-md-8 col-sm-7">
                <p className="fs--1 mb-0 text-left">
                <Link to="#!">
                    <FontAwesomeIcon
                    icon="exchange-alt"
                    className="me-2"
                    transform="rotate-90"
                    />
                    Hi!</Link>{' '}
                    This is the page for managing farm settings and user permissions.
                </p>
            </Col>
        </Row> 
      </Card.Body>
    </Card>
  );
};

export default Intro;

import { createContext } from "react";

const AddFarmContext = createContext(
    {}
);


export default AddFarmContext;



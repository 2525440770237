import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
import avatarImg from 'assets/img/team/avatar.png';
import { isIterableArray } from 'helpers/utils';
import Avatar from 'Components/common/Avatar';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import Flex from 'Components/common/Flex';
import { Col, Row } from 'react-bootstrap';

const FarmForm = ({ register, errors, setValue }) => {


  return (
    <>
      <WizardInput
        type="select"
        name="farm"
        label="Farming*"
        errors={errors}
        placeholder="Select your farm"
        options={[
          "hydroponics",
          "aquaculture"
        ]}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('farm', {
            required: 'Farm Type is requred',
          }),
          disabled: true,
          
        }}
      />

      <WizardInput
        label="Farm Name*"
        name="farm_name"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('farm_name', {
            required: 'Farm Name field is required',
            validate: value => String(value).length < 25 || 'Name should be less than 25 characters',
          })
        }}
      />

      <Row className="g-2 mb-3">
        <WizardInput
          type="number"
          label="Farm Size*"
          name="farm_size"
          errors={errors}
          formGroupProps={{ as: Col, sm: 6 }}
          formControlProps={{
            ...register('farm_size', {
              required: 'Farm Size field is required',
              validate: value => parseInt(value) > 0 || 'Should be positive',
            })
          }}
        />

        <WizardInput
          type="select"
          name="unit"
          label="Unit*"
          errors={errors}
          placeholder="Select Unit"
          options={[
            "Sqft"
          ]}
          formGroupProps={{ as: Col, sm: 6 }}
          formControlProps={{
            ...register('unit', {
              required: 'Select Unit',

            })
          }}
        />
      </Row>


      <WizardInput
        type="number"
        name="capacity"
        label="Plant Capacity*"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          className: 'input-spin-none',
          ...register('capacity', {
            required: 'Plant Capacity is requred'
          })
        }}
      />


      <WizardInput
        type="textarea"
        errors={errors}
        label="Farm Address*"
        name="farm_address"
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('farm_address', {
            required: 'Address field is required',
            rows: 2,
            minLength: {
              message: 'Address must have at least 10 characters'
            }
          })
        }}
      />

      <Row className="g-2 mb-3">
        <WizardInput
          label="City*"
          name="farm_city"
          errors={errors}
          formGroupProps={{ as: Col, sm: 6 }}
          formControlProps={{
            ...register('farm_city', {
              required: 'Farm City is requred'
            })
          }}
        />

        <WizardInput
          type="select"
          name="farm_state"
          label="State*"
          errors={errors}
          placeholder="Select your state"
          options={[
            "Andaman and Nicobar (UT)",
            'Andhra Pradesh',
            "Arunachal Pradesh",
            "Assam",
            "Bihar",
            "Chandigarh (UT)",
            "Chhattisgarh",
            "Dadra and Nagar Haveli and Daman and Diu	 (UT)",
            "Delhi (UT)",
            "Goa",
            "Gujarat",
            "Haryana",
            "Himachal Pradesh",
            "Jammu and Kashmir (UT)",
            "Jharkhand",
            "Karnataka",
            "Kerala",
            "Ladakh  (UT)",
            "Lakshadweep  (UT)",
            "Madhya Pradesh",
            "Maharashtra",
            "Manipur",
            "Meghalaya",
            "Mizoram",
            "Nagaland",
            "Odisha",
            "Puducherry",
            "Punjab  (UT)",
            "Rajasthan",
            "Sikkim",
            "Tamil Nadu",
            "Telangana",
            "Tripura",
            "Uttar Pradesh",
            "Uttarakhand",
            "West Bengal"


          ]}
          formGroupProps={{ as: Col, sm: 6 }}
          formControlProps={{
            ...register('farm_state', {
              required: 'Select a state',

            })
          }}
        />

      </Row>

      <Row className="g-2 mb-3">
        <WizardInput
          type="number"
          name="farm_zipCode"
          label="Zip Code*"
          placeholder="123456"
          errors={errors}
          formGroupProps={{ as: Col, sm: 6 }}
          formControlProps={{
            className: 'input-spin-none',
            ...register('farm_zipCode', {
              required: 'Zip code is requred',
              validate: value => String(value).length === 6 || 'Zip code must be 6 digits'
            })
          }}
        />

        <WizardInput
          type="select"
          name="farm_country"
          label="Country*"
          errors={errors}
          placeholder="Select your country"
          options={[
            'India'
          ]}
          formGroupProps={{ as: Col, sm: 6 }}
          formControlProps={{
            ...register('farm_country', {
              required: 'Select a country',

            })
          }}
        />
      </Row>


    </>
  );
};

FarmForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired
};

export default FarmForm;

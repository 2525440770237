import React from 'react'
import { Link } from 'react-router-dom';

import LoginForm from '../../Components/Auth/LoginForm';
import AuthCardLayout from '../../Components/layouts/AuthCardLayout';

const Login = () => {


    return (
        <AuthCardLayout
            leftSideContent={
                <p className="text-white">
                    {/* Don't have an account?
                    <br /> */}
                   
                </p>
            }
        >
            <h3>Account Login</h3>
            <LoginForm layout="card" />
        </AuthCardLayout>
    )
}

export default Login
import React from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'

const Loading = () => {
  return (
    <>
        <Row>
            <Col className="text-center">
                <Spinner animation="border" variant="primary" />
            </Col>
        </Row>
    </>
  )
}

export default Loading
import React from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";
const ExportButton = ({ handleExport, loading }) => {
  const history = useHistory();

  const routeChange = () => {
    let path = `/`;
    history.push(path);
  }
  return (

    <Card className="bg-light my-3">
      <Card.Body className="p-0 position-relative">
        <Button className="btn btn-danger text-nowrap w-100" id="button-addon2" type="submit" onClick={handleExport} disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" />
            : "Click to Export Data"}
        </Button>
      </Card.Body>
    </Card>
  );
};

export default ExportButton;

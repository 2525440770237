import React, { useReducer } from 'react'

import FarmContext from 'Context/FarmContext';
import { farmReducer } from 'reducers/farmReducer';

const FarmRoutesProvider = (props) => {

    const farmStateInit = {
        farmType: "",
        farmList: [],
        invitedFarmList: [],
        isLoading: false,
        currentFarmData:{
            farm:{},
            zones:[]
        }
    }

    const [farmState, dispatchFarmState] = useReducer(farmReducer, farmStateInit);


    return (
        <FarmContext.Provider value={{ farmState, dispatchFarmState }}>
           {props.children}
        </FarmContext.Provider>
    )
}

export default FarmRoutesProvider
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Form, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import Flex from 'Components/common/Flex';
import MonitoringParamatersChart from './MonitoringParamatersChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from "react-router-dom";
import { GraphContext } from '../index';
import climatedata from 'Components/Others/climatedata';
import { getGraphData } from 'API/hydroponicsFarmsAPI';
import AuthContext from 'Context/AuthContext';

const NavItem = ({ item }) => {

  const [data, setData] = useState({
    listTabs: ["VPD", "DEW", "TEMP", "HUMIDITY"]
  });

  return (
    <Nav.Item as="li">
      <Nav.Link
        className="mb-0 py-3 cursor-pointer"
        eventKey={item}
      >
        {data.listTabs[item - 1]}
      </Nav.Link>
    </Nav.Item>
  );
};

const MonitoringParamaters = () => {

  const contextData = useContext(GraphContext);
  const { authState } = useContext(AuthContext);
  const testDataset = [645, 500, 550, 550, 473, 405, 286, 601, 743, 450, 604, 815, 855, 722,
    700, 896, 866, 952, 719, 558, 737, 885, 972, 650, 600]

  const [navItems] = useState(['1', '2', '3', '4']);
  const [selectedKey, setSelectedKey] = useState("1");
  const [tf_index, settf_index] = useState('0');
  const [graphData, setGraphData] = useState({
    vpdData: [0, 0, 0, 0, 0],
    dewData: [0, 0, 0, 0, 0],
    temperatureData: [0, 0, 0, 0, 0],
    humidityData: [0, 0, 0, 0, 0],
    analytics: [{
      unit: "kPa",
    },
    {
      unit: "°C",
    },
    {
      unit: "°C",
    },
    {
      unit: "%",
    }]
  });
  const [loading, setLoading] = useState(false);

  const getGraphValues = async () => {
    setLoading(true);
    let resData = await getGraphData(authState.accessToken, { dev_id: contextData.zoneData.productDetails.id, tf_index: tf_index });
    console.log("res Data",resData);
    if (resData.success) {
      if(resData.data){
        if(resData.data.dewData){
          resData.data.dewData =  resData.data.dewData.map((dew)=>dew === 0 ? undefined : dew);
        }
        if(resData.data.vpdData){
          resData.data.vpdData =  resData.data.vpdData.map((dew)=>dew === 0 ? undefined : dew);
        }
        if(resData.data.humidityData){
          resData.data.humidityData =  resData.data.humidityData.map((dew)=>dew === 0 ? undefined : dew);
        }
        if(resData.data.temperatureData){
          resData.data.temperatureData =  resData.data.temperatureData.map((dew)=>dew === 0 ? undefined : dew);
        }
      }
      setGraphData(resData.data);
    }
    console.log(graphData)
    setLoading(false);
  }


  useEffect(() => {
    console.log(contextData);

  }, [contextData.zoneData]);

  useEffect(() => {
    if (contextData.zoneData.productDetails) {
      getGraphValues();
    }
  }, [contextData.zoneData.productDetails, tf_index]);

  console.log("vpd data",graphData);

  return (
    <Card className="mt-3">
      <Tab.Container id="audience-tab" defaultActiveKey={selectedKey} onSelect={(k) => {
        setSelectedKey(k);
        //console.log(k);
      }}>
        <Card.Header
          as={Flex}
          justifyContent="between"
          alignItems="center"
          className="ps-0 py-0 border-bottom"
        >
          <Nav
            as="ul"
            className="nav-tabs border-0 flex-nowrap chart-tab tab-active-caret"
          >
            {navItems.map(item => (
              <NavItem item={item} key={item} />
            ))}

          </Nav>
        </Card.Header>

        <Card.Body>
          <Row className="g-1">
            <Col xxl={3}>
              <Row className="g-0 my-2">
                <Col md={6} xxl={12}>
                  <div className="border-xxl-bottom border-xxl-200 mb-3">
                    <h2 className="text-primary">{`${contextData.deviceValues[selectedKey-1]}
                    ${graphData.analytics[selectedKey - 1].unit}`}
                    </h2>
                  </div>
                  <div style={{ "height": "100%", "width": "170px" }}>
                    <select className="form-select" value={tf_index} onChange={(e) => { e.preventDefault(); settf_index(e.target.value) }}>
                      <option value="0">Last 24 hrs</option>
                      <option value="1">Last 3 days</option>
                      <option value="2">Last 7 days</option>
                      <option value="3">Last 30 days</option>
                      <option value="4">Last 90 days</option>
                    </select>
                  </div>
                </Col>
                <Col md={6} xxl={12} className="py-2">
                  <Row className="mx-0">
                    <Col xs={6} className="border-end border-bottom py-3">
                      <h5 className="fw-normal text-600">{`${(loading || graphData.analytics.length == 0) ? "--" : graphData.analytics[selectedKey - 1].max} ${graphData.analytics[selectedKey - 1].unit}`}

                      </h5>
                      <h6 className="text-500 mb-0">Max</h6>
                    </Col>
                    <Col xs={6} className="border-bottom py-3">
                      <h5 className="fw-normal text-600">{`${(loading || graphData.analytics.length == 0) ? "--" : graphData.analytics[selectedKey - 1].min}  
                    ${graphData.analytics[selectedKey - 1].unit}`}</h5>
                      <h6 className="text-500 mb-0">Min</h6>
                    </Col>
                    <Col xs={6} className="border-end py-3">
                      <h5 className="fw-normal text-600">{`${(loading || graphData.analytics.length == 0) ? "--" : graphData.analytics[selectedKey - 1].dayAvg} 
                    ${graphData.analytics[selectedKey - 1].unit}`}</h5>
                      <h6 className="text-500 mb-0">Day Avg</h6>
                    </Col>
                    <Col xs={6} className="py-3">
                      <h5 className="fw-normal text-600">{`${(loading || graphData.analytics.length == 0) ? "--" : graphData.analytics[selectedKey - 1].nightAvg} 
                    ${graphData.analytics[selectedKey - 1].unit}`}</h5>
                      <h6 className="text-500 mb-0">Night Avg</h6>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xxl={9}>
              <Tab.Content>
                {loading ?
                  <div className="text-center">
                    <Spinner animation="border" variant="primary" />
                  </div>
                  :
                  <>
                    <Tab.Pane unmountOnExit eventKey="1">
                      <MonitoringParamatersChart data={graphData.vpdData} name="VPD" tf_index={tf_index} xData={graphData.timeStamps} unit={graphData.analytics[selectedKey - 1].unit} />
                    </Tab.Pane>
                    <Tab.Pane unmountOnExit eventKey="2">
                      <MonitoringParamatersChart data={graphData.dewData} name="DEW" tf_index={tf_index} xData={graphData.timeStamps} unit={graphData.analytics[selectedKey - 1].unit} />
                    </Tab.Pane>
                    <Tab.Pane unmountOnExit eventKey="3">
                      <MonitoringParamatersChart data={graphData.temperatureData} name="TEMP" tf_index={tf_index} xData={graphData.timeStamps} unit={graphData.analytics[selectedKey - 1].unit} />
                    </Tab.Pane>
                    <Tab.Pane unmountOnExit eventKey="4">
                      <MonitoringParamatersChart data={graphData.humidityData} name="HUM" tf_index={tf_index} xData={graphData.timeStamps} unit={graphData.analytics[selectedKey - 1].unit} />
                    </Tab.Pane>
                  </>
                }

              </Tab.Content>
            </Col>
          </Row>
        </Card.Body>
      </Tab.Container>
    </Card>
  );
};

NavItem.propTypes = {
  item: PropTypes.string.isRequired
};

export default MonitoringParamaters;

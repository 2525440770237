import { getRelayDeviceDetails } from 'API/hydroponicsFarmsAPI';
import AuthContext from 'Context/AuthContext';
import React, {useState, useEffect, useContext} from 'react'
import { Card, Col, Row, Form, FormControl, Button } from 'react-bootstrap';

const GeneralSettingsForm = ({stateChanger, ...rest}) => {
    const authState = useContext(AuthContext)
    const deviceId =rest.RelayDeviceId;
    const [generalDevice, setGeneralDevice] = useState({
        deviceName: '',
        purpose: '',
        ratedPower: '',
        noOfLoads: '',
        loadType:''
    }); 
    stateChanger(generalDevice)
    const [totalWatts, setTotalWatts] = useState();
    const loadDeviceDetails = async (deviceId) => {
        let resData = await getRelayDeviceDetails(authState.accessToken, {
          deviceId,
        });
                // console.log("temp xgriddata received, json",{json},{temp},data);
        console.log('temp xgriddata resData.data',rest.channel,resData);
    
        if (
          resData.data[rest.channel].device_name &&
          resData.data[rest.channel].purpose &&
          resData.data[rest.channel].rated_power &&
          resData.data[rest.channel].num_loads &&
          resData.data[rest.channel].load_type
        ) {
          if (resData.success) {
            setGeneralDevice((preValue) => {
              return {
                ...preValue,
                deviceName: resData.data[rest.channel].device_name,
                purpose: resData.data[rest.channel].purpose,
                ratedPower: resData.data[rest.channel].rated_power,
                noOfLoads: resData.data[rest.channel].num_loads,
                loadType: resData.data[rest.channel].load_type,
              };
            });
          }
        } else {
          setGeneralDevice({
            deviceName: "",
            purpose: "",
            ratedPower: "",
            noOfLoads: "",
            loadType: "",
          });
        }
      };



  const inputEvent = (event) => {
    //console.log(event.target.value);
    const value = event.target.value;   
    const name = event.target.name;

    //const {value, name}=event.target;

    setGeneralDevice((preValue) => {
        return{
            ...preValue,
            [name] : value
        }
    });
    
  };



  useEffect(() => {
    loadDeviceDetails(rest.RelayDeviceId);
  }, [deviceId,rest.channel]);

    return (
        <>
            <Card className='bg-light my-3'>
                <Card.Body className='p-3 position-relative'>
                    <Row>
                    <Form>
                    <Row className='no-gutters justify-content-start'>
                        <Col className='col-4 col-sm-2'>
                        <Form.Text className='text-nowrap text-primary fs-0'>Device Name
                            {/* <strong className='fs-0'>Device Name</strong> */}
                        </Form.Text>
                        </Col>
                        <Col className='col-8 col-sm-4'>
                        <FormControl className='fs--1'
                            placeholder='Nickname'
                            aria-label='Device Name'
                            aria-describedby='basic-addon2'
                            name='deviceName'
                            onChange={inputEvent} value={generalDevice.deviceName}
                        />
                        </Col>
                        <div className='w-100'></div>
                        <Col className='col-4 col-sm-2 mt-2'>
                        <Form.Text className='text-nowrap mt-1 text-primary fs-0'> Purpose
                            {/* <strong className='fs-0'>Purpose</strong> */}
                        </Form.Text>
                        </Col>
                        <Col className='col-8 col-sm-4 mt-2'>
                        <select className='form-select fs--1' aria-label='Default select example' name='purpose' onChange={inputEvent} value={generalDevice.purpose}>
                            <option value='' disabled selected>--choose--</option>
                            <option value='Exhaust fan'>Exhaust fan</option>
                            <option value='Pad'>Pad</option>
                            <option value='AC'>AC</option>
                            <option value='Foggers'>Foggers</option>
                            <option value='Chillers'>Chillers</option>
                            <option value='RO'>RO</option>
                            <option value='Pad Pumps'>Pad Pumps</option>
                            <option value='Grow Lights'>Grow Lights</option>
                            <option value='Irrigation'>Irrigation</option>
                            <option value='Air Pump'>Air Pump</option>
                            <option value='Valves'>Valves</option>
                            <option value='Other'>Other</option>
                        </select>
                        </Col>
                        <div className='w-100'></div>
                        <Col className='col-4 col-sm-2 mt-2'>
                        <Form.Text className='text-nowrap mt-1 text-primary fs-0'> Load Type
                            {/* <strong className='fs-0'>Rated Power</strong> */}
                        </Form.Text>
                        </Col>
                        <Col className='col-8 col-sm-4 mt-2'>
                        <select className='form-select fs--1' aria-label='Default select example' name='loadType' onChange={inputEvent} value={generalDevice.loadType}>
                            <option value='' disabled selected>--choose--</option>
                            <option value='Three Phase (415 VAC)' disabled={rest.RelayDeviceId.slice(0, 3) === "REL"} >Three Phase (415 VAC)</option>
                            <option value='Single Phase (220 VAC)'>Single Phase (220 VAC) </option>
                            
                        </select>
                        </Col>
                        <div className='w-100'></div>
                        <Col className='col-4 col-sm-2 mt-2'>
                        <Form.Text className='text-nowrap mt-1 text-primary fs-0'> Rated Power
                            {/* <strong className='fs-0'>Rated Power</strong> */}
                        </Form.Text>
                        </Col>
                        <Col className='col-8 col-sm-4 mt-2'>
                        <FormControl className='fs--1'
                            type='number'
                            placeholder='Enter value in Watts '
                            aria-label='Rated Power'
                            aria-describedby='basic-addon2'
                            name='ratedPower'
                            onChange={inputEvent} value={generalDevice.ratedPower}
                        />
                        
                        </Col>
                        <div className='w-100'></div>
                        <Col className='col-4 col-sm-2 mt-2'>
                        <Form.Text className='text-nowrap mt-1 text-primary fs-0'> No. of Loads
                            {/* <strong className='fs-0'>No. of Loads</strong> */}
                        </Form.Text>
                        </Col>
                        <Col className='col-8 col-sm-4 mt-2'>
                        <FormControl className='fs--1'
                            type='number'
                            placeholder='Qty'
                            aria-label='No. of Loads'
                            aria-describedby='basic-addon2'
                            name='noOfLoads'
                            onChange={inputEvent} value={generalDevice.noOfLoads}
                        />
                        </Col>
                        </Row>

                    {/* <Row className='align-items-center row mt-sm-4 mt-3 ms-1 me-1'>
                        <button type='submit' className='btn btn-primary btn-block text-nowrap' >Save
                        </button>
                    </Row>  */}
                    </Form>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}

export default GeneralSettingsForm

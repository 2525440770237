import { calibrateVpd } from 'API/hydroponicsFarmsAPI';
import AuthContext from 'Context/AuthContext';
import React, { useContext, useEffect, useState } from 'react'
import { useAlert } from 'react-alert';
import { Card, Col, Row, Form, FormControl, Button, Spinner } from 'react-bootstrap';
import { ZoneConfigContext } from '.';
import CalibrationModal from './CalibrationModal';

const Callibaration = () => {
    const zoneConfigData = useContext(ZoneConfigContext)
    const { authState } = useContext(AuthContext)
    const alert = useAlert();
    const [formData, setFormData] = useState({
        deviceId: zoneConfigData.zoneData.productDetails.id ? zoneConfigData.zoneData.productDetails.id : "",
        temperature: "",
        humidity: "",
    })
    const [isLoading, setIsLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const handleInput = (e) => {
        if (!isNaN(e.target.value)) {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            })
        }
    }


    useEffect(() => {
        setFormData((prevState) => {
            return {
                ...prevState,
                deviceId: zoneConfigData.zoneData.productDetails.id ? zoneConfigData.zoneData.productDetails.id : "",
            }
        })
    }, [zoneConfigData.zoneData.productDetails])

    const handleModal = (e) => {
        e.preventDefault();
        setModal(true)
    }

    const handleSubmit = async () => {
        // e.preventDefault();
        setIsLoading(true);
        let resData = await calibrateVpd(authState.accessToken, formData);
        setIsLoading(false);
        setModal(false);

        if (resData.success) {
            console.log(resData);
            alert.success("Device Calibrated Successfully!");

        }
        if (resData.error) {
            console.log(resData.error);
            alert.error("Something went wrong!")
        }
        setFormData((prevState) => {
            return {
                ...prevState,
                temperature: "",
                humidity: "",
            }
        })
    }

    return (
        <>
            <Card className="bg-light my-3">
                <Card.Body className="p-3 position-relative">
                    <Row className="align-items-center row">
                        <Col xs-auto="true">
                            <p className=" mb-0 text-left text-primary">
                                <strong className='fs-0'>Calibaration</strong>
                            </p>
                        </Col>
                    </Row>
                    <Row className="align-items-center row mt-2 mb-3">
                        <h6 className="text-500 fs--2">Always calibrate the device to a known standard. We always ship our devices calibrated at factory </h6>
                        <h6 className="text-500 fs--2 mt-3">Tip: make sure the device has been in place for 24hrs before calibrating.</h6>
                        <h6 className="text-500 fs--2" >Tip: make sure the calibration standard is close to the Aer 360 device.</h6>
                        <h6 className="text-500 fs--2"> Tip: calibrate temperature first, then humidity.</h6>
                    </Row>
                    <Row>
                        <Form onSubmit={handleModal}>
                            <Row className='no-gutters justify-content-start'>
                                <Col className='col-4 col-sm-2'>
                                    <Form.Text className='text-nowrap mt-1 text-primary'>
                                        <strong className='fs-0'>Temperature</strong>
                                    </Form.Text>
                                </Col>
                                <Col className='col-8 col-sm-4'>
                                    <FormControl className='fs--1'
                                        name="temperature"
                                        aria-label="Temperature"
                                        aria-describedby="basic-addon2"
                                        value={formData.temperature}
                                        onChange={handleInput}
                                        required
                                    />
                                </Col>
                                <div className="w-100"></div>
                                <Col className='col-4 col-sm-2 mt-2'>
                                    <Form.Text className='text-nowrap mt-1 text-primary'>
                                        <strong className='fs-0'>Humidity</strong>
                                    </Form.Text>
                                </Col>
                                <Col className='col-8 col-sm-4 mt-2'>
                                    <FormControl className='fs--1'
                                        name="humidity"
                                        aria-label="Humidity"
                                        aria-describedby="basic-addon2"
                                        value={formData.humidity}
                                        onChange={handleInput}
                                        required
                                    />
                                </Col>
                                <Row className="align-items-center row mt-sm-4 mt-3 ms-1 me-1">

                                    <button type="submit" className="btn btn-primary btn-block text-nowrap  d-none d-sm-block" disabled={isLoading}>{!isLoading ? "I understand this will wipe original factory calibration and replace with new settings. Click to Proceed"
                                        : <Spinner animation="border" variant="primary" />}</button>
                                    <button type="submit" className="btn btn-primary btn-block text-nowrap d-sm-none d-block" disabled={isLoading}>{!isLoading ? "Click to Proceed"
                                        : <Spinner animation="border" variant="primary" />}</button>

                                </Row>

                            </Row>
                        </Form>
                    </Row>
                </Card.Body>
            </Card >
            <CalibrationModal modal={modal} setModal={setModal} deviceId={formData.deviceId} calibrateDevice={handleSubmit} />
        </>
    )
}

export default Callibaration

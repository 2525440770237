import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";

import AuthContext from "Context/AuthContext";

function ProtectedRoute({ component: Component, contextComponentProvider: ContextProvider, secondaryContextProvider: SecondaryProvider, ...restOfProps }) {
    const isAuthenticated = useContext(AuthContext).authState.isAuth;
    // console.log(isAnother,secondaryContextProvider)
    return (
        <Route
            {...restOfProps}
            render={(props) =>
                isAuthenticated ?
                    <ContextProvider>
                        {SecondaryProvider ?
                            <SecondaryProvider>
                                <Component {...props} />
                            </SecondaryProvider>
                            :
                            <Component {...props} />}

                    </ContextProvider>
                    :
                    <Redirect to="/auth/signin" />
            }
        />
    );
}

export default ProtectedRoute;

import React, { useContext, useState, useEffect } from 'react';
import AdvanceTable from 'Components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'Components/common/advance-table/AdvanceTableWrapper';
import Avatar from 'Components/common/Avatar';
import CardDropdown from 'Components/common/CardDropdown';
import FalconCardHeader from 'Components/common/FalconCardHeader';
import Flex from 'Components/common/Flex';
import IconItem from 'Components/common/icon/IconItem';
import SoftBadge from 'Components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FarmZonesData } from './FarmZonesData';
import { Card, Col, Row, Form, FormControl, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { FarmConfigContext } from '../FarmSettings';
import IconButton from 'Components/common/IconButton';
import { faTrash,  } from '@fortawesome/free-solid-svg-icons';
import DashboardContext from 'Context/DashboardContext';
// import { zoneDeleteModal } from '../../../Zones/ZoneDeleteModal';



const getColumns = (handleConfig) => {
  return [
    {
      accessor: 'zone',
      Header: 'Zone',
      headerProps: {
        className: 'py-3'
      },
      Cell: rowData => {
        return (
          <Link to="#!">
            {/* <Flex alignItems="center"> */}
            {/* <Avatar size="xl" src={rowData.row.original.img} /> */}
            <h6 className="mb-0 text-800">{rowData.row.original.zone}</h6>
            {/* </Flex> */}
          </Link>
        );
      }
    },
    {
      accessor: 'stage',
      Header: 'Stage',
      Cell: rowData => {
        return (
          <a className="mb-0 ">
            {rowData.row.original.stage}
          </a>
        );
      }
    },
    {
      accessor: 'Action',
      Header: 'Action',
      headerProps: {
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end'
      },
      disableSortBy: true,
      Cell: rowData => {
       
        return (
          <IconItem
            tag="button"
            icon={['far', 'edit']}
            onClick={(e) => { e.preventDefault(); handleConfig(rowData.row.original.id) }}
            size="sm"
            className="btn rounded-3 me-2 fs--2 text-warning"
            style={{ float: 'right' }}
          />
        )
       
      }
    }
  ];
}


const FarmZones = () => {
  const { farmConfigData } = useContext(FarmConfigContext);
  const [zonesData, setZonesData] = useState([]);
  const { dashboardState, dispatchDashboardState } = useContext(DashboardContext);
  const history = useHistory();

  useEffect(() => {
    if (farmConfigData.zonesList.length > 0) {
      let data = []
      farmConfigData.zonesList.map(zone => {
        data.push({
          zone: zone.name,
          stage: zone.stage,
          id: zone._id,
        })
      }
      )
      setZonesData(data)
    }
  }, [farmConfigData.zonesList])

  const handleConfig = (id) => {
    // e.preventDefault()
    console.log('delete', id)
    let zoneData = farmConfigData.zonesList.filter(zone => zone._id == id)
    console.log('zoneData', zoneData)
    console.log(dashboardState)
    // dispatchDashboardState({
    //   type: "SET_ACTIVE_ZONE",
    //   payload: zoneData[0]
    // });
    // console.log('dashboardState', dashboardState)
    // if(dashboardState.activeZone.name){
    let path = `/dashboard/${dashboardState.activeZone.name.trim()}/farm-management/zone-details`;
    history.push(path, { params: zoneData[0] });
    // } 
  }

  // useEffect(() => {

  // },[dashboardState.activeZone.name])

  return (
    <>
      <AdvanceTableWrapper
        columns={getColumns(handleConfig)}
        data={zonesData}
        // selection
        // selectionColumnWidth={30}
        sortable
        pagination
        perPage={10}
      >
        <Card>
          <FalconCardHeader
            title="Farm Zones"
            titleTag="h6"
            className="py-3"
          />
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer className="p-0 bg-light">
            <Button
              as={Link}
              variant="link"
              size="sm"
              to="/dashboard/addZone"
              className="w-100 py-2"
            >
              <FontAwesomeIcon icon="plus" className="me-1 fs--2" />
              Add New Zone
            </Button>
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};

export default FarmZones;

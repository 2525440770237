import Register from 'Pages/Authentication/Register';
import React, { useContext } from 'react'
import { useRouteMatch, Route, Redirect, useHistory } from 'react-router-dom'
import Login from '../Pages/Authentication/Login'
import AuthContext from 'Context/AuthContext';
import Logout from '../Pages/Authentication/Logout';
import ForgetPassword from 'Pages/Authentication/ForgetPassword';

const AuthRoutes = () => {
    const { url } = useRouteMatch();
    const isAuthenticated = useContext(AuthContext).authState.isAuth;
    console.log(isAuthenticated);
    const history = useHistory();
    if (isAuthenticated) {
        history.push('/type-selection')
    }
    return (
        <>
            <Route path={`${url}/signin`} exact component={Login} />
            <Route path={`${url}/signup`} exact component={Register} />
            <Route path={`${url}/logout`} exact component={Logout} />
            <Route path={`${url}/forgot-password`} exact component={ForgetPassword} />
        </>
    )
}

export default AuthRoutes
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { timeList } from 'helpers/timeList';

const ZoneSetup = ({ register, errors, setValue, getValues }) => {
  let timeArr = []
  timeList.map((time) => {
    timeArr.push(time.value)
  })
  const [timeErr, setTimeErr] = useState(false)

  useEffect(() => {
    console.log(getValues('sunrise'), getValues('sunset'))
    if (getValues('sunrise') === getValues('sunset')) {
      setTimeErr(true)
    } else {
      setTimeErr(false)
    }
  }, [getValues('sunrise'), getValues('sunset')])

  return (
    <>
      <WizardInput
        label="Zone Name*"
        name="zone_name"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('zone_name', {
            required: 'Zone Name is requred',
            validate: value => String(value).length < 25 || 'Name should be less than 25 characters',
          })
        }}
      />

      <WizardInput
        type="select"
        name="zone_stage"
        label="Stage*"
        errors={errors}
        placeholder="-- Select --"
        options={[
          "seedling",
          "vegetative",
          "flowering"
        ]}
        formGroupProps={{ as: Col, sm: 6 }}
        formControlProps={{
          ...register('zone_stage', {
            required: 'Select a stage',
          })
        }}
      />
      <WizardInput
        label="Sunrise*"
        name="sunrise"
        errors={errors}
        placeholder="-- Select --"
        type="selectTime"
        options={timeList}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('sunrise', {
            required: 'Sunrise Time is requred',
            validate: () => getValues('sunrise') !== getValues('sunset') || 'Sunrise time cannot be same as sunset time'
          })
        }}
      />

      <WizardInput
        label="Sunset*"
        name="sunset"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        placeholder="-- Select --"
        type="selectTime"
        options={timeList}
        formControlProps={{
          ...register('sunset', {
            required: 'Sunset Time is requred',
            validate: () => getValues('sunrise') !== getValues('sunset') || 'Sunrise time cannot be same as sunset time'

          })
        }}
      />

    </>
  );
};

ZoneSetup.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired
};

export default ZoneSetup;

import React, {useState} from 'react'
import { Card, Col, Row, Form, FormControl, Button, Dropdown, InputGroup } from 'react-bootstrap';

const Notification = () => {
    const [productID, setProductID] = useState(""); 
    const [notificationAlert, setNotificationAlert] = useState(false);

      const inputAlertEvent = () => {
                console.log(notificationAlert);
                setNotificationAlert(!notificationAlert);
              };
  const inputEvent = (event) => {
    console.log(event.target.value);
    setProductID(event.target.value);
  };

    return (
        <>
            <Card className="bg-light my-3">
                <Card.Body className="p-3 position-relative">
                    <Row className="align-items-center row">
                        <Col xs-auto="true">
                            <p className=" mb-0 text-left text-primary">
                                <strong className='fs-0'>Notification & Alerts</strong>    
                            </p>
                        </Col>
                    </Row> 
                    <Row className="align-items-center row mt-sm-0 mt-2">
                        <Form.Check
                            className='ms-2' 
                            type='checkbox'
                            id='defaultCheckbox'>
                            <Form.Check.Input type='checkbox' className='fs--2 mt-sm-2 mt-0' onChange={inputAlertEvent}/>
                            <Form.Check.Label className="text-500 fs--2 lh-sm">Send me a notification on Email/Whatsapp only if it breaches the set threshold range. </Form.Check.Label>
                            </Form.Check>                        
                    </Row>
                    <Row>
                    <Form>
                    <Row className='no-gutters justify-content-start'>
                    <Col className='col-4 col-sm-2'>
                        <Form.Text className='text-nowrap mt-1 text-primary'>
                
                        </Form.Text>
                        </Col>
                        <Col className='col-4 col-sm-5 mb-2 text-center'>
                        <Form.Text className='text-nowrap mt-1 text-primary'>
                            <strong className='fs-0'>Lower Limit</strong>
                        </Form.Text>
                        </Col>
                        <Col className='col-4 col-sm-5 mb-2 text-center'>
                        <Form.Text className='text-nowrap mt-1 text-primary'>
                            <strong className='fs-0'>Upper Limit</strong>
                        </Form.Text>
                        </Col>
                        <div className="w-100"></div>
                        <Col className='col-4 col-sm-2'>
                        <Form.Text className='text-nowrap mt-1 text-primary'>
                            <strong className='fs-0'>Temperature</strong>
                        </Form.Text>
                        </Col>
                        <Col className='col-4 col-sm-5 mb-2'>
                        <InputGroup>
                                <FormControl className='fs--1' aria-label=""  name=''
                                    type="number" placeholder=''disabled={notificationAlert ? '' : 'disabled'}/>
                                    <InputGroup.Text id="" className='fs--2'>°C</InputGroup.Text>
                        </InputGroup>
                        </Col>
                        <Col className='col-4 col-sm-5'>
                        <InputGroup>
                                <FormControl className='fs--1' aria-label=""  name=''
                                    type="number" placeholder='' disabled={notificationAlert ? '' : 'disabled'}/>
                                    <InputGroup.Text id="" className='fs--2'>°C</InputGroup.Text>
                        </InputGroup>
                        </Col>
                        <div className="w-100"></div>
                        <Col className='col-4 col-sm-2 mt-2'>
                        <Form.Text className='text-nowrap mt-1 text-primary'>
                            <strong className='fs-0'>Humidity</strong>
                        </Form.Text>
                        </Col>
                        <Col className='col-4 col-sm-5 mb-2'>
                        <InputGroup>
                                <FormControl className='fs--1' aria-label=""  name=''
                                    type="number" placeholder=''disabled={notificationAlert ? '' : 'disabled'}/>
                                    <InputGroup.Text id="" className='fs--2'>%</InputGroup.Text>
                        </InputGroup>
                        </Col>
                        <Col className='col-4 col-sm-5 mb-2'>
                        <InputGroup>
                                <FormControl className='fs--1' aria-label=""  name=''
                                    type="number" placeholder=''disabled={notificationAlert ? '' : 'disabled'}/>
                                    <InputGroup.Text id="" className='fs--2'>%</InputGroup.Text>
                        </InputGroup>
                        </Col>
                        <div className="w-100"></div>
                        <Col className='col-4 col-sm-2 mt-2'>
                        <Form.Text className='text-nowrap mt-1 text-primary'>
                            <strong className='fs-0'>Product ID</strong>
                        </Form.Text>
                        </Col>
                        <Col className='col-4 col-sm-5'>
                        <InputGroup>
                                <FormControl className='fs--1' aria-label=""  name=''
                                    type="number" placeholder='' disabled={notificationAlert ? '' : 'disabled'}/>
                                    <InputGroup.Text id="" className='fs--2'>kPa</InputGroup.Text>
                        </InputGroup>
                        </Col>
                        <Col className='col-4 col-sm-5'>
                        <InputGroup>
                                <FormControl className='fs--1' aria-label=""  name=''
                                    type="number" placeholder='' disabled={notificationAlert ? '' : 'disabled'}/>
                                    <InputGroup.Text id="" className='fs--2'>kPa</InputGroup.Text>
                        </InputGroup>
                        </Col>
                        </Row>
                    </Form>
                    </Row>
                    <Row className="align-items-center row mt-sm-4 mt-3 ms-1 me-1">
                        
                        <button type="button" className="btn btn-primary btn-block" disabled={productID=="" ? 'disabled' : ''}>Save
                        </button>
                    </Row> 
                </Card.Body>
            </Card>
        </>
    )
}

export default Notification

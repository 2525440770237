import axios from 'axios';
import { apiRoutes } from './apiRoutes';

export const loginSubmit = async (email, password) => {
    try {
        let response = await axios.post(apiRoutes.login.path, {
            email: email,
            password: password
        })

        if (response.data.access_token) {
            localStorage.setItem('access_token', response.data.access_token);
            return {
                status: "success",
                data: response.data
            };
        }

    }
    catch (err) {
        if (err.response) {
            return {
                status: "error",
                data: err.response.data.detail
            }
        }
        else {
            return {
                status: "error",
                data: "Something went wrong ! Please try again later."
            }
        }
    }
}


export const registerSubmit = async (formData) => { 
    try {
        let response = await axios.post(apiRoutes.register.path, formData)
        if (response.data.access_token) {
            localStorage.setItem('access_token', response.data.access_token);
            return {
                status: "success",
                data: response.data
            };
        }
    }
    catch (err) {
        if (err.response) {
            return {
                status: "error",
                data: err.response.data.detail
            }
        }
        else {
            return {
                status: "error",
                data: "Something went wrong ! Please try again later."
            }
        }
    }
}


export const verifyToken = async (token, authDispatch) => {
    try {
        let data = await axios.post(apiRoutes.verifyUser.path, {}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        authDispatch({
            type: 'LOGIN_SUCCESS',
            payload: {
                accessToken: token,
                username: data.data.name,
                email: data.data.email
            }
        })
    }
    catch (err) {
        authDispatch({
            type: "LOGOUT",
            payload: {}
        })
    }
}
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col } from 'react-bootstrap';
import Divider from '../common/Divider';
import { faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmPasswordReset, sendOTPForgetPassword } from 'API/profileAPI';


const ForgetPasswordForm = () => {
  // State
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    email: false
  });
  const [otpSent, setOtpSent] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    otp: '',
    password: '',
    confirmPassword: ''
  });

  // useEffect

  const handleFieldChange = (e) => {
    if (e.target.name === 'otp') {
      if (!isNaN(e.target.value) && e.target.value.length <= 6) {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        });
      }
    }
    else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      })
    }
  }

  // OTP Handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({
      email: false
    })
    if (email) {
      setIsLoading(true);
      let resData = await sendOTPForgetPassword(email);
      if (resData.success) {
        toast.success(`An email is sent to ${email} with password reset OTP`);
        setFormData({
          ...formData,
          email: email
        })
        setOtpSent(true);
      }
      else {
        setErrors({
          email: resData.error
        })
      }
    }
  };

  const confirmPasswordChange = async (e) => {
    setErrors({})
    e.preventDefault();
    console.log(formData);
    if (formData.password.length < 8) {
      setErrors({
        password: 'Password must be atleast 8 characters long'
      })
    }
    else {
      const resData = await confirmPasswordReset(formData);
      console.log(resData)
      if (resData.error) {
        if (resData.error === "OTP doesn't match") {
          setErrors({
            otp: 'OTP doesn\'t match'
          })
        }
      }
      if(resData.success){
        toast.success('Password changed successfully');
        setFormData({
          email: '',
          otp: '',
          password: '',
          confirmPassword: ''
        })
        setOtpSent(false);
        history.push('/login');
      }
    }
  }

  const differentEmail = (e) => {
    e.preventDefault();
    setOtpSent(false);
    setFormData({
      email: '',
      otp: '',
      password: '',
      confirmPassword: ''
    })
    setEmail('');
  }

  return (
    <>
      {!otpSent &&
        <Form className="mt-4" onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Control
              placeholder={'Email address'}
              value={email}
              name="email"
              onChange={({ target }) => setEmail(target.value)}
              type="email"
            />
          </Form.Group>
          {errors.email && <span className="text-danger fs--1 mb-2">{errors.email}</span>}

          <Form.Group className="mb-3">
            <Button className="w-100" type="submit" disabled={!email}>
              Send OTP
            </Button>
          </Form.Group>
        </Form>
      }
      {otpSent &&
        <Form className='mt-4' onSubmit={confirmPasswordChange}>
          <span className='fs--1 mb-2 fw-bold'>Enter 6 digit OTP sent to {email}</span>
          <Form.Group className="mb-3 mt-3">
            <Form.Control
              placeholder={'OTP'}
              value={formData.otp}
              name="otp"
              onChange={handleFieldChange}
              type="text"
              isInvalid={errors.otp}
              required
            />
            <Form.Control.Feedback type="invalid">{errors.otp}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3 d-flex align-items-center">
            <Form.Control
              placeholder={'New Password'}
              value={formData.password}
              name="password"
              onChange={handleFieldChange}
              type={showPassword ? 'text' : 'password'}
              required
              isInvalid={errors.password}
            />
            <FontAwesomeIcon className="mx-2" type='button' icon={!showPassword ? 'eye' : faEyeSlash} onClick={() => setShowPassword(!showPassword)} />
            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              placeholder={'Confirm New Password'}
              value={formData.confirmPassword}
              name="confirmPassword"
              onChange={handleFieldChange}
              type={'password'}
              isInvalid={formData.confirmPassword && formData.password !== formData.confirmPassword}
              required
            />
            <Form.Control.Feedback type="invalid">Passwords do not match</Form.Control.Feedback>
          </Form.Group>
          <Link
            as={Button}
            className="fs--1 mb-0"
            onClick={handleSubmit}
          >
            Resend OTP
          </Link>
          <br />
          <Link
            as={Button}
            className="fs--1 mt-2"
            onClick={differentEmail}
          >
            <span> &#8592;</span>
            Try with different email address
          </Link>
          <Button className="w-100 mt-2" type="submit" disabled={!formData.otp || !formData.password}> Confirm </Button>

        </Form>}

      <Divider className="mt-4"></Divider>
      <Row className="justify-content-center align-items-center">

        <Col xs="auto">
          <Link
            className="fs--1 mb-0"
            to={`/auth/signin`}
          >
            Sign In
          </Link>
        </Col>
        <Col xs="auto">
          <Link
            className="fs--1 mb-0"
            to={`/auth/singup`}
          >
            Register
          </Link>
        </Col>
      </Row>
    </>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };

export default ForgetPasswordForm;

export const EditDeviceSettingsData = [
    {
      title: 'Product ID',
      text: 'FAVA21456AA',
      icon: 'chess-rook',
      color: 'primary'
    },
    {
      title: 'Network',
      text: 'iCreate Campus',
      icon: 'crown',
      color: 'warning'
    },
    {
        title: 'Last Calibrates',
        text: 'Jan 01 2019',
        icon: 'crown',
        color: 'warning'
    },
    {
      title: 'Device Status',
      text: 'Connected',
      icon: 'video',
      color: 'success'
    },
    {
      title: 'Last synced',
      text: 'Dec 06 2021 14:23:41',
      icon: 'user',
      color: 'info'
    }
  ];
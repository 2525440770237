export const EditDeviceSettingsData = [
    {
      title: 'Product ID',
      text: 'REL55837AA',
      icon: 'chess-rook',
      color: 'primary'
    },
    {
      title: 'Network',
      text: 'iCreate Campus',
      icon: 'crown',
      color: 'warning'
    },
    {
      title: 'Device Status',
      text: 'Connected',
      icon: 'video',
      color: 'success'
    },
    {
      title: 'Last synced',
      text: 'active Now',
      icon: 'user',
      color: 'info'
    }
  ];
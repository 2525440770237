import { setItemToStore } from 'helpers/utils';

export const farmReducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case 'SET_FARM_TYPE':
            let newState = {};
            if (payload.farmType === 'hydroponics') {
                newState = {
                    ...state,
                    farmType: "hydroponics"
                }
            }
            else if (payload.farmType === 'aquaculture') {
                newState = {
                    ...state,
                    farmType: "aquaculture"
                }
            }
            else if (payload.farmType === 'samrt_farming') {
                newState = {
                    ...state,
                    farmType: "samrt_farming"
                }
            }
            setItemToStore('farmState', newState);
            return newState;

        case 'SET_FARM_LOADING':
            return {
                ...state,
                isLoading: true
            }

        case 'SET_FARM_LISTS':
            if (payload.length > 0) {
                let invitedFarms = [];
                let ownedFarms = [];
                payload.forEach(farm => {
                    if (farm.own) {
                        invitedFarms.push(farm);
                    }
                    else {
                        ownedFarms.push(farm);
                    }
                });
                setItemToStore('farmState', {
                    ...state,
                    farmList: ownedFarms,
                    invitedFarmList: invitedFarms,
                    isLoading: false
                })
                return {
                    ...state,
                    farmList: ownedFarms,
                    invitedFarmList: invitedFarms,
                    isLoading: false
                }
            }
            else {
                setItemToStore('farmState', {
                    ...state,
                    isLoading: false
                }
                )
                return {
                    ...state,
                    isLoading: false
                }
            }

        case 'SET_CURRENT_FARM':
            let updatedState = {
                ...state,
                currentFarmData: {
                    farm: payload,
                    zones: []
                }
            }
            setItemToStore('farmState', updatedState);
            return updatedState;

        case 'SET_CURRENT_FARM_ZONES':
            updatedState = {
                ...state,
                currentFarmData: {
                    farm: state.currentFarmData.farm,
                    zones: payload
                }
            }
            setItemToStore('farmState', updatedState);
            return updatedState

        case 'UPDATE_FARM_STATE':
            return payload

        default:
            return state;
    }
}
import React, { useState } from 'react'
import { Modal, Button, Form, ButtonGroup, Spinner } from 'react-bootstrap';
import Lottie from 'react-lottie';
import animationData from './lottie/warning-light.json';
import Flex from 'Components/common/Flex';
import FalconCloseButton from 'Components/common/FalconCloseButton';

const CalibrationModal = ({ modal, setModal, deviceId, calibrateDevice }) => {
    let [confirmDisabled, setConfirmDisabled] = useState(true);
    let [confirmText, setConfirmText] = useState('');
    let [isLoading, setIsLoading] = useState(false);

    const onChangeText = (e) => {
        e.preventDefault();
        setConfirmText(e.target.value.toUpperCase());
        if (e.target.value === deviceId) {
            setConfirmDisabled(false);
        }
        else {
            setConfirmDisabled(true);
        }
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const onDeleteDevice = (e) => {
        e.preventDefault();
        setIsLoading(true);
        calibrateDevice();
        setIsLoading(false);
        setModal(false);
    }

    return (
        <Modal show={modal} centered dialogClassName="wizard-modal">
            <Modal.Body className="p-4">
                <FalconCloseButton
                    size="sm"
                    className="position-absolute top-0 end-0 me-2 mt-2"
                    onClick={() => setModal(!modal)}
                />
                <Flex justifyContent="center" alignItems="center">
                    <Lottie options={defaultOptions} style={{ width: '100px' }} />
                    <p className="mb-0 flex-1">
                        This action cannot be reversed!<br />
                        Confirm the Device Id to proceed
                    </p>
                </Flex>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Control placeholder={deviceId} value={confirmText} onChange={onChangeText} />
                    </Form.Group>
                    <div className='d-flex justify-content-center'>
                        <Button className="mx-1" disabled={confirmDisabled || isLoading} onClick={onDeleteDevice} >
                            {
                                isLoading ?
                                    <Spinner animation="border" size="sm" /> :
                                    'Confirm'
                            }
                        </Button>

                        <Button variant='danger' disabled={isLoading} className="mx-1" onClick={() => setModal(false)}>Cancel</Button>
                    </div>
                </Form>

            </Modal.Body>
        </Modal>
    )
}

export default CalibrationModal
import climatedata from 'Components/Others/climatedata';


export const dashboardRoutes = {
  label: 'My Farm',
  labelDisable: true,
  children: [
    {
      name: 'My Farm',
      active: true,
      icon: 'chart-pie',
      children: [
      ]
    }
  ]
};

export const analysisRoutes = {
  label: 'analysis',
  labelDisable: true,
  children: [
    {
      name: 'Analysis',
      active: false,
      icon: 'calendar-alt',
      to: '/dashboard/analysis',
      // active: true,
    }]}


export const pagesRoutes = {
  label: 'pages',
  children: [
  // {
  //   name: 'IOT Receiver Hub',
  //   active: false,
  //   icon: 'chart-pie',
  //   children: [
  //   // {
  //   //   name: 'Zub 1',
  //   //   to: '/dashboard/analytics',
  //   //   active: true
  //   // },
  //   // {
  //   //   name: 'Zub 2',
  //   //   to: '#',
  //   //   active: true
  //   // }
  //   ,]},
    {
      name: 'Subscription',
      icon: 'tags',
      active: false,
      to: '#',
    },
    {
      name: 'Shop',
      icon: 'shopping-cart',
      active: false,
      to: '#',
      },
    {
      name: 'Help',
      icon: 'question-circle',
      active: false,
      to: '#',
    },
  ]
};


export default [
  dashboardRoutes,
  analysisRoutes,
  pagesRoutes
];

import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Col,
  Row,
  Form,
  FormControl,
  DropdownButton,
  Dropdown,
  InputGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import { duration } from "dayjs";
import moment from "moment";
import { RelayConfigContext } from ".";
import { faGalacticSenate } from "@fortawesome/free-brands-svg-icons";
import { faAssistiveListeningSystems } from "@fortawesome/free-solid-svg-icons";
import { e, isInteger } from "mathjs";
import {
  getRelayDeviceDetails,
  UpdateSensorBasedData,
} from "API/hydroponicsFarmsAPI";
import AuthContext from "Context/AuthContext";
import { useHistory } from "react-router-dom";
import DashboardContext from "Context/DashboardContext";
import { toast } from "react-toastify";
const SensorBasedTrigger = (props) => {
  const zoneData = useContext(RelayConfigContext);
  const generalDevice = props.deviceData;
  const [sensorTrigger, setSensorTrigger] = useState({
    relay: props.RelayDeviceId,
    sensor_comparison: "",
    upperLimit: "",
    sensorType: "",
    upperLimitUnit: "",
    lowerLimit: "",
    lowerLimitUnit: "",
    onDuration: "",
    onDurationTime: "hr",
    interval: "",
    intervalTime: "hr",
    performanceMode: false,
    sensorId: "",
  });
  const [settleingTimeOption, setSettelingTimeOption] = useState(0);
  console.log(zoneData, { sensorTrigger });
  const { dashboardState } = useContext(DashboardContext);

  const history = useHistory();
  const { authState } = useContext(AuthContext);
  const [relayIds, setRelayIds] = useState([
    zoneData.zoneData.productDetails.id,
  ]);
  let temp2 = [];
  useEffect(
    (e) => {
      setRelayIds(relayIds);
    },
    [zoneData]
  );

  console.log(sensorTrigger, { props });
  // useEffect((e)=>{
  //     setSensorTrigger((preValue)=>{
  //         ...preValue,

  //     })
  // },[zoneData])
  const loadDeviceDetails = async (deviceId) => {
    let resData = await getRelayDeviceDetails(authState.accessToken, {
      deviceId,
    });
    console.log("resdata,relay", resData);

    if (resData.success) {
      if (resData.data[props.channel].sensor_trigger !== undefined) {
        let i = 0;
        for (
          i = 0;
          i < resData.data[props.channel].sensor_trigger.on_duration?.length;
          i++
        ) {
          if (
            resData.data[props.channel].sensor_trigger.on_duration[i] == "*"
          ) {
            break;
          }
        }
        let onDurationTemp = resData.data[
          props.channel
        ].sensor_trigger.on_duration?.slice(0, i);
        let onDurationTimeTemp = resData.data[
          props.channel
        ].sensor_trigger.on_duration?.slice(
          i + 1,
          resData.data[props.channel].sensor_trigger.on_duration?.length
        );
        setSensorTrigger((preValue) => {
          return {
            ...preValue,
            sensorId: resData.data[props.channel].sensor_trigger.sensor_id,
            sensorType: resData.data[props.channel].sensor_trigger.sensor_type,
            sensor_comparison: resData.data[props.channel].sensor_trigger.type,
            upperLimit: resData.data[props.channel].sensor_trigger.upperLimit,
            lowerLimit: resData.data[props.channel].sensor_trigger.lowerLimit,
            onDuration: onDurationTemp,
            onDurationTime: onDurationTimeTemp,
            performanceMode:
              resData.data[props.channel].sensor_trigger.is_performance_mode,
          };
        });
        if (
          resData.data[props.channel].sensor_trigger.off_duration !==
            undefined &&
          resData.data[props.channel].sensor_trigger.off_duration !== null
        ) {
          for (
            i = 0;
            i < resData.data[props.channel].sensor_trigger.off_duration.length;
            i++
          ) {
            if (
              resData.data[props.channel].sensor_trigger.off_duration[i] == "*"
            ) {
              break;
            }
          }
          let offDurationTemp = resData.data[
            props.channel
          ].sensor_trigger.off_duration.slice(0, i);
          let offDurationTimeTemp = resData.data[
            props.channel
          ].sensor_trigger.off_duration.slice(
            i + 1,
            resData.data[props.channel].sensor_trigger.off_duration.length
          );

          setSensorTrigger((preValue) => {
            return {
              ...preValue,
              interval: offDurationTemp,
              intervalTime: offDurationTimeTemp,
            };
          });
          setSettelingTimeOption(1);
        }
      } else if (resData.data[props.channel].time_trigger !== undefined) {
      }
    }
  };
  useEffect(() => {
    loadDeviceDetails(props.RelayDeviceId);
  }, [props]);

  const inputEvent = (event) => {
    //console.log(event.target.selectedOptions[0].text);
    event.preventDefault();
    const value = event.target.value;
    const name = event.target.name;
    console.log({ name }, { value });
    if (
      name == "lowerLimit" ||
      name == "upperLimit" ||
      name == "onDuration" ||
      name == "interval"
    ) {
      if (value >= 0) {
        setSensorTrigger((preValue) => {
          return {
            ...preValue,
            [name]: value,
          };
        });
      }
    } else {
      setSensorTrigger((preValue) => {
        return {
          ...preValue,
          [name]: value,
        };
      });
    }
  };
  const inputEventToggle = (event) => {
    let v;
    if (sensorTrigger.performanceMode) {
      setSensorTrigger((preValue) => {
        return {
          ...preValue,
          performanceMode: false,
        };
      });
    } else {
      setSensorTrigger((preValue) => {
        return {
          ...preValue,
          performanceMode: true,
        };
      });
    }
  };
  const inputSettlingCheckBox = (event) => {
    setSensorTrigger((pre2) => {
      return {
        ...pre2,
        interval: "",
      };
    });
    setSettelingTimeOption((preValue) => {
      return !preValue;
    });
  };
  const inputDevice = (event) => {
    //console.log(event.target.selectedOptions[0].text);
    event.preventDefault();
    let value = event.target.value;
    const name = event.target.name;

    let temp;

    console.log("value", relayIds, value);
    if (value[0] == "h") {
      value = relayIds[0];
      temp = "hum";
    } else {
      value = relayIds[0];
      temp = "temp";
    }
    setSensorTrigger((preValue) => {
      return {
        ...preValue,
        [name]: value,
        sensorType: temp,
      };
    });
  };

  function validatePayload(payload) {
    console.log({ payload });

    if (payload.sensor_comparison === "") {
      return {
        status: true,
        msg: "sensor comparison cant be empty",
      };
    }
    if (payload.sensorType === "") {
      return {
        status: true,
        msg: "sensor sensor type cant be empty",
      };
    }
    if (payload.onDuration) {
      const [duration, unit] = payload.onDuration.split("*");
      if (!isInteger(duration)) {
        return {
          status: true,
          msg: "onduration must be a natural number",
        };
      }
      console.log("payload duration, onduration", duration, unit);

      if (unit === "hr" && (duration < 1 || duration > 24)) {
        return {
          status: true,
          msg: "hour duration must be in range of 1 to 24",
        };
      }
      if (unit === "min" && (duration < 1 || duration > 600)) {
        return {
          status: true,
          msg: "minute duration must be in range of 1 to 600",
        };
      }
    }

    if (payload.offDuration) {
      const [duration, unit] = payload.offDuration.split("*");
      if (!isInteger(duration)) {
        return {
          status: true,
          msg: "offduration must be a natural number",
        };
      }
      if (unit === "hr" && (duration < 1 || duration > 24)) {
        return {
          status: true,
          msg: "hour duration must be in range of 1 to 24",
        };
      }
      if (unit === "min" && (duration < 1 || duration > 600)) {
        return {
          status: true,
          msg: "minute duration must be in range of 1 to 600",
        };
      }
    }
    if (payload.lowerLimit === "" || payload.upperLimit === "") {
      return {
        status: true,
        msg: "Lower or upper limit cant be empty",
      };
    }
    console.log("payload: ", isInteger(payload.lowerLimit));

    if (!isInteger(payload.lowerLimit)) {
      return { status: true, msg: "lower limit must be a natural number" };
    }

    if (!isInteger(payload.upperLimit)) {
      return { status: true, msg: "upper limit must be a natural number" };
    }

    if (payload.lowerLimit < 0) {
      return {
        status: true,
        msg: "lower limit must be greater than 0",
      };
    }
    if (payload.lowerLimit >= payload.upperLimit) {
      return { status: true, msg: "lower limit must be less than upperlimit" };
    }
    if (payload.sensorType === "temp" && payload.upperLimit > 60) {
      return { status: true, msg: "upper limit of temperature sensor is 60" };
    }
    if (payload.sensorType === "hum" && payload.upperLimit > 100) {
      return { status: true, msg: "upper limit of humidity sensor is 100" };
    }
    return { status: false };
  }

  const onSubmit = async (event) => {
    event.preventDefault();

    console.log(sensorTrigger.interval + "*" + sensorTrigger.intervalTime);
    const payload = {
      name: generalDevice.deviceName,
      channel: props.channel,
      purpose: generalDevice.purpose,
      ratedPower: generalDevice.ratedPower,
      noOfLoads: generalDevice.noOfLoads,
      relayId: props.RelayDeviceId,
      sensorId: sensorTrigger.sensorId,
      sensorType: sensorTrigger.sensorType,
      sensor_comparison: sensorTrigger.sensor_comparison,
      upperLimit: sensorTrigger.upperLimit,
      lowerLimit: sensorTrigger.lowerLimit,
      // onDuration:
      //   sensorTrigger.onDuration + '*' + sensorTrigger.onDurationTime,
      performanceMode: sensorTrigger.performanceMode,
    };
    if (settleingTimeOption) {
      payload["onDuration"] =
        sensorTrigger.onDuration + "*" + sensorTrigger.onDurationTime;
      payload["offDuration"] =
        sensorTrigger.interval + "*" + sensorTrigger.intervalTime;
    }
    const validationResult = validatePayload(payload);
    // return;
    console.log("payload validation result", validationResult);
    if (validationResult.status) {
      toast.info(validationResult.msg);
      return;
    }

    let response = await UpdateSensorBasedData(authState.accessToken, payload);
    console.log("respone ", response, dashboardState, props);
    if (response.success == true) {
      if (dashboardState.activeZone && dashboardState.activeZone.name)
        history.push(
          "/dashboard/" +
            dashboardState.activeZone.name.trim() +
            "/farm-management"
        );
    }
  };

  return (
    <>
      <Card className="bg-light my-3">
        <Card.Body className="p-3 position-relative">
          <Row className="align-items-center row">
            <Col xs-auto="true">
              <p className=" mb-3 text-left text-primary">
                <strong className="fs-0">
                  Sensor-based Trigger | Activate sadsadsadad
                </strong>
              </p>
            </Col>
          </Row>
          <Row className="align-items-center row">
            <Form onSubmit={onSubmit}>
              <Row className="no-gutters justify-content-start">
                <Col className="col-4 col-sm-2">
                  {/* <Form.Text className='text-nowrap me-2 mt-3 text-primary fs-0'>
                    Sensor
                    <strong className='fs-0'>Sensor</strong>
                  </Form.Text> */}
                </Col>
                <Col className="col-8 col-sm-8 mt-0">
                  {relayIds == "" || relayIds == undefined ? (
                    <select
                      className="form-select fs--1"
                      aria-label="Default select example"
                      name="sensorId"
                      value={sensorTrigger.sensorType}
                      onChange={inputDevice}
                    >
                      <option value="" disabled selected>
                        --choose--
                      </option>
                    </select>
                  ) : (
                    <select
                      className="form-select fs--1"
                      aria-label="Default select example"
                      name="sensorId"
                      value={sensorTrigger.sensorType}
                      onChange={inputDevice}
                    >
                      <option value="" disabled selected>
                        --Choose Sensor --
                      </option>
                      <option
                        disabled={props.sensorState == "0"}
                        value={"temp"}
                      >
                        Air_Temperature {relayIds}
                      </option>
                      <option disabled={props.sensorState == "0"} value={"hum"}>
                        Relative_Humidity {relayIds}
                      </option>
                    </select>
                  )}
                </Col>
                <div className="w-100"></div>
                <Col
                  className="col-4 mt-2 col-sm-2"
                  style={{ paddingTop: "4px" }}
                >
                  {/* <Form.Text className='text-nowrap me-2 mt-3 text-primary fs-0'>
                    Condition
                    <strong className='fs-0'>Sensor</strong>
                  </Form.Text> */}
                </Col>
                <Col className="col-8 col-sm-8 mt-2">
                  <select
                    className="form-select fs--1"
                    aria-label="Default select example"
                    name="sensor_comparison"
                    onChange={inputEvent}
                    value={sensorTrigger.sensor_comparison}
                  >
                    <option value="" disabled selected>
                      --choose--
                    </option>
                    <option value="gte">greater than equal to</option>

                    <option value="lte">lesser than equal to</option>
                  </select>
                </Col>

                {/* <Col className={`col-8 col-sm-2 mt-2`}>
                  <Form.Text className='text-nowrap me-2 mt-6 text-primary fs-0'>
                    Condition
                  </Form.Text>
                </Col>

                <Col className={`col-8 col-sm-8 mt-2`}>
                  <select
                    className='form-select fs--1'
                    aria-label='Default select example'
                    name='sensor_comparison'
                    onChange={inputEvent}
                    value={sensorTrigger.sensor_comparison}
                  >
                    <option value='' disabled selected>
                      --choose--
                    </option>
                    <option value='gte'>greater than equal to</option>

                    <option value='lte'>lesser than equal to</option>
                  </select>
                </Col> */}
                <div className="w-100"></div>

                <Col
                  className="col-8 col-sm-2 mt-3"
                  style={{ paddingTop: "4px" }}
                >
                  <Form.Text className="text-nowrap mt-1 text-primary fs-0">
                    Set Range
                  </Form.Text>
                </Col>

                <Col className="col-6 col-sm-4 mt-3">
                  <InputGroup>
                    <FormControl
                      className="fs--1"
                      value={sensorTrigger.lowerLimit}
                      aria-label="Lower Limit"
                      type="number"
                      placeholder="Lower Limit"
                      name="lowerLimit"
                      onChange={inputEvent}
                    />
                    <InputGroup.Text id="">
                      {sensorTrigger.sensorType
                        ? sensorTrigger.sensorType === "temp"
                          ? "°C"
                          : "%"
                        : "Unit"}
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
                <Col className="col-6 col-sm-4 mt-3">
                  <InputGroup>
                    <FormControl
                      className="fs--1"
                      value={sensorTrigger.upperLimit}
                      aria-label="Upper Limit"
                      type="number"
                      placeholder="Upper Limit"
                      name="upperLimit"
                      onChange={inputEvent}
                    />
                    <InputGroup.Text id="">
                      {sensorTrigger.sensorType
                        ? sensorTrigger.sensorType === "temp"
                          ? "°C"
                          : "%"
                        : "Unit"}
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
              </Row>
              <Row
                className="align-items-center row mt-3"
                style={{ marginBottom: "-0.5rem" }}
              >
                {settleingTimeOption === 1 ? (
                  <Form.Check
                    className="ms-2"
                    type="checkbox"
                    id="defaultCheckbox"
                  >
                    <Form.Check.Input
                      type="checkbox"
                      className="fs--1"
                      style={{ marginTop: "0.37rem" }}
                      value="1"
                      onChange={inputSettlingCheckBox}
                      checked
                    />
                    <Form.Check.Label className="text-500 fs--1 lh-sm">
                      Do you think your actuator need sttling time?
                    </Form.Check.Label>
                  </Form.Check>
                ) : (
                  <Form.Check
                    className="ms-2"
                    type="checkbox"
                    id="defaultCheckbox"
                  >
                    <Form.Check.Input
                      type="checkbox"
                      className="fs--2 mt-sm-2 mt-0"
                      value="0"
                      onChange={inputSettlingCheckBox}
                    />
                    <Form.Check.Label className="text-500 fs--2 lh-sm">
                      Do you think your actuator need settling time?
                    </Form.Check.Label>
                  </Form.Check>
                )}
              </Row>

              <Row className="text-align-center" style={{ marginTop: "0rem" }}>
                {settleingTimeOption ? (
                  <>
                    <Col
                      className="col-4 col-sm-2 "
                      style={{ paddingTop: "3px" }}
                    >
                      <Form.Text className="text-nowrap mt-2 text-primary fs-0">
                        On Duration
                      </Form.Text>
                    </Col>
                    <Col className="col-8 col-sm-8">
                      <InputGroup>
                        <FormControl
                          className="fs--1 me-1"
                          placeholder="Enter Value"
                          aria-label="number input with dropdown button"
                          name="onDuration"
                          onChange={inputEvent}
                          value={sensorTrigger.onDuration}
                          type="number"
                        />
                        <select
                          className="form-select fs--1 "
                          aria-label="Default select example"
                          name="onDurationTime"
                          value={sensorTrigger.onDurationTime}
                          onChange={inputEvent}
                        >
                          <option className="" value="hr">
                            Hours
                          </option>
                          <option className="" value="min" selected>
                            Minute
                          </option>
                          <option className="" value="sec" selected>
                            Second
                          </option>
                        </select>
                      </InputGroup>
                    </Col>
                    <div className="w-100"></div>
                    <Col className="col-4 col-sm-2 mt-2">
                      <Form.Text className="text-nowrap mt-1 text-primary fs-0">
                        Off Duration
                      </Form.Text>
                    </Col>
                    <Col className="col-8 col-sm-8 mt-2 mb-2">
                      <InputGroup>
                        <FormControl
                          className="fs--1 me-1"
                          placeholder="Enter Interval Duration"
                          aria-label="Text input with dropdown button"
                          type="number"
                          name="interval"
                          value={sensorTrigger.interval}
                          onChange={inputEvent}
                        />
                        <select
                          className="form-select fs--1"
                          aria-label="Default select example"
                          name="intervalTime"
                          value={sensorTrigger.intervalTime}
                          onChange={inputEvent}
                        >
                          <option className="" value="hr">
                            Hours
                          </option>
                          <option className="" value="min" selected>
                            Minute
                          </option>
                        </select>
                      </InputGroup>
                    </Col>{" "}
                  </>
                ) : (
                  ""
                )}
                <Row className="align-items-center row mt-0">
                  <Form.Check
                    className="ms-2"
                    type="checkbox"
                    id="defaultNotiCheckbox"
                  >
                    <Form.Check.Input
                      type="checkbox"
                      className="fs--2 mt-sm-2 mt-0"
                      style={{ marginTop: "0.37rem" }}
                    />
                    <Form.Check.Label className=" text-500 fs--2 lh-sm">
                      Send me a notification on Email/Whatsapp whenever this
                      trigger is activated.
                    </Form.Check.Label>
                  </Form.Check>
                </Row>
              </Row>
              <div className="w-100"></div>
              {/* <Row className='align-items-center row mt-2'>
                <Col className='col-8 col-sm-1 mt-2'>
                  <Form.Text className='text-nowrap mt-1 text-primary fs-0'>
                    Set Range
                  </Form.Text>
                </Col>
                <Col className='mt-2'>
                  <label className='switch'>
                    <input type='checkbox' checked={sensorTrigger.performanceMode} name ='performanceMode' onChange={inputEventToggle}/>
                    <span className='slider_auto round'  ></span>
                  </label>
                </Col>
                <Col className='col-8 col-sm-10 mt-2'>
                  <Form.Text className='text-nowrap mt-1 text-primary fs-0'>
                    Set Range
                  </Form.Text>
                </Col>
              </Row> */}
              {/* <Row className='no-gutters mt-2'>
                <Col className='col-lg-1 col-md-2 col-4 me-4 me-md-2 me-lg-5'>
                  <p className='fs-0 text-primary text-nowrap'>Eco Mode</p>
                </Col>
                <Col className='col-md-1'>
                  <Form.Check
                    type='switch'
                    id='checkedSwitch'
                    checked={sensorTrigger.performanceMode}
                    name='performanceMode'
                    onChange={inputEventToggle}
                  />
                </Col>
                <Col className='col-lg-2 col-md-2 col-4 me-4 me-md-2 me-lg-1 '>
                  <p className='fs-0 text-primary text-nowrap'>
                    Performance Mode
                  </p>
                </Col>
              </Row>
              <Row className='align-items-center row mt-3'>
                <h6 className='text-500 fs--2'>
                  You have selected{' '}
                  {sensorTrigger.performanceMode == false
                    ? ' “ECO Mode”. Maximum Power Savings...'
                    : '“Performance Mode”. Farm autopilot will make sure your sensor reading is closer to the mid of your set upper & lower limits i.e 28 °C' +
                      sensorTrigger.upperLimit +
                      ' & Lower Limit ' +
                      sensorTrigger.lowerLimit +
                      '.'}
                </h6>
              </Row> */}
              <Row className="align-items-center row mt-3">
                <h6 className="text-500 fs--2">Trigger Summary</h6>
                <h6 className="text-500 fs--2">
                  1. Turn ON{" "}
                  <strong style={{ fontWeight: "bolder" }}>
                    {props.deviceData.purpose}
                  </strong>{" "}
                  relay switch when{" "}
                  <strong>
                    {sensorTrigger.sensorType == "temp"
                      ? "Air_Temperature"
                      : "Relative_Humidity"}{" "}
                  </strong>
                  {sensorTrigger.sensorId} readings is{" "}
                  <strong>
                    {sensorTrigger.sensor_comparison == "gte"
                      ? `greater than equal to ${
                          sensorTrigger.upperLimit !== ""
                            ? sensorTrigger.upperLimit
                            : "Upper Limit"
                        }`
                      : `lesser than equal to ${
                          sensorTrigger.lowerLimit !== ""
                            ? sensorTrigger.lowerLimit
                            : "Lower Limit"
                        }`}{" "}
                    {/* {sensorTrigger.upperLimit !== ''
                    ? sensorTrigger.upperLimit
                    : 'Upper Limit'}{' '} */}
                  </strong>
                  {/* {sensorTrigger.sensorType == 'temp' ? '°C' : '%'}, till the{' '}
                  
                  {sensorTrigger.sensorType == 'temp'
                    ? 'Air_Temperature'
                    : 'Relative_Humidity'}{' '}
                  {sensorTrigger.sensorId} readings reaches Middle value of
                  Lower Limit and Upper Limit i.e{' '}
                  {sensorTrigger.upperLimit !== '' &&
                  sensorTrigger.lowerLimit !== ''
                    ? parseInt(
                        sensorTrigger.upperLimit +
                          parseInt(sensorTrigger.lowerLimit)
                      ) / 2
                    : ''}{' '} */}
                  {sensorTrigger.sensorType == "temp" ? "°C" : "%"} or else turn
                  it OFF.
                  <br />
                  2. Autopilot will maintain your readings between your set
                  range of {sensorTrigger.lowerLimit}{" "}
                  {sensorTrigger.sensorType == "temp" ? "°C" : "%"} and{" "}
                  {sensorTrigger.upperLimit}{" "}
                  {sensorTrigger.sensorType == "temp" ? "°C" : "%"}.
                  <br />
                  <br />
                  {sensorTrigger.performanceMode === true &&
                    "Automatic trigger is set at tolerance of 10% change in range i.e “0.2” °C"}
                  {sensorTrigger.interval !== ""
                    ? "After every successful trigger of " +
                      sensorTrigger.onDuration +
                      " " +
                      sensorTrigger.onDurationTime +
                      " the system will rest for " +
                      sensorTrigger.interval +
                      " " +
                      sensorTrigger.intervalTime +
                      " before executing the next trigger."
                    : ""}
                </h6>
              </Row>
              <Row className="align-items-center row mt-sm-4 mt-3 ms-1 me-1">
                <button
                  type="submit"
                  className="btn btn-primary btn-block text-nowrap"
                >
                  Save
                </button>
              </Row>
            </Form>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default SensorBasedTrigger;

import React, { useContext, useState, useEffect } from 'react'
import { Button, Form, FormGroup, FormControl, FormLabel, Col, Row, Spinner } from "react-bootstrap";
import { Card } from 'react-bootstrap';
import { ZoneConfigContext } from '.';
import RemoveDeviceModal from './RemoveDeviceModal';
import AuthContext from 'Context/AuthContext';
import { useHistory } from 'react-router-dom';
import DashboardContext from 'Context/DashboardContext';
import { removeDevice } from 'API/hydroponicsFarmsAPI';


const RemoveDeviceForm = () => {
  const { dashboardState } = useContext(DashboardContext)
  const zoneData = useContext(ZoneConfigContext)
  const { authState } = useContext(AuthContext)
  const history = useHistory();

  const zoneConfigData = useContext(ZoneConfigContext)
  const [productDetails, setProductDetails] = useState({});
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    farmId: dashboardState.farmDetails._id ? dashboardState.farmDetails._id : "",
    zoneId: zoneData.zoneData.zoneId ? zoneData.zoneData.zoneId : "",
    deviceId: zoneConfigData.zoneData.productDetails.id ? zoneConfigData.zoneData.productDetails.id : "",
  })

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setModal(true);
  }

  const onDeleteDevice = async() => {
    console.log("delte requestr", formData);
    
    const resData = await removeDevice(authState.accessToken, formData);
    if(resData.success){
      setModal(false);
      history.push('/dashboard');
      history.go(0);
    }
    if(resData.error){
      setModal(false);
      console.log("error", resData.error);
    }

  };

  useEffect(() => {
    setFormData((prevState) => {
      return {
        ...prevState,
        farmId: dashboardState.farmDetails._id ? dashboardState.farmDetails._id : "",
        zoneId: zoneData.zoneData.zoneId ? zoneData.zoneData.zoneId : "",
        deviceId: zoneConfigData.zoneData.productDetails.id ? zoneConfigData.zoneData.productDetails.id : "",
      }
    })
  }, [zoneConfigData.zoneData.productDetails, dashboardState, zoneData])

  useEffect(() => {
    setProductDetails(zoneConfigData.zoneData.productDetails);
  }, [zoneConfigData.zoneData.productDetails])

  useEffect(() => {
    if (!modal) {
      setIsLoading(false);
    }
  }, [modal])

  return (
    <>
      <RemoveDeviceModal modal={modal} setModal={setModal} deviceId={productDetails.id} deleteDevice={onDeleteDevice} />
      <Card className="bg-light my-3">
        <Card.Body className="p-3 position-relative">
          <Form className='' onSubmit={onSubmit}>
            <Row className='w-100 mb-2'>
              <Col>
                <Form.Text className='text-nowrap me-2 mt-1 text-danger'>
                  <strong className='fs-0'>Remove Device</strong>
                </Form.Text>
              </Col>
            </Row>
            <div className="d-flex flex-row">
              <FormControl className='fs--1'
                placeholder="Enter the Product ID"
                aria-label="Product ID"
                aria-describedby="basic-addon2"
                disabled={true}
                value={productDetails.id ? productDetails.id : ''}
              />
              <Button className="btn btn-danger" id="button-addon2" type="submit">
                {isLoading ?
                  <Spinner animation='border' size='sm' /> :
                  <>
                    <span className='d-none d-sm-block'>Confirm</span><span className='d-sm-none d-block'>Remove</span>
                  </>
                }
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  )
}

export default RemoveDeviceForm

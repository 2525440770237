import React, { useState, useContext, useEffect } from 'react';
import { Button, Card, Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Background from 'Components/common/Background';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import bgImage from 'assets/img/icons/spot-illustrations/corner-5.png';
import connectCircle from 'assets/img/icons/connect-circle.png';
import Flex from 'Components/common/Flex';
import { useHistory } from "react-router-dom";
import { ZoneContext } from './index';
import DashboardContext from 'Context/DashboardContext';

// import climatedata from 'components/climatedata';

const FarmEnvironmentConfig = ({ deviceStatus }) => {
  const { dashboardState, dispatchDashboardState } = useContext(DashboardContext)
  const zoneData = useContext(ZoneContext)

  useEffect(() => {
    console.log(zoneData)
  }, [zoneData])


  const history = useHistory();

  const routeChangeConfig = () => {
    let path = `/dashboard/${dashboardState.activeZone.name.trim()}/farm-management/farm-environment/farm-config`;
    history.push(path, { params: zoneData });
  }

  const routeChangeAnalytics = () => {
    let path = `/dashboard/${dashboardState.activeZone.name.trim()}/farm-management/farm-environment/farm-analytics`;
    history.push(path, { params: zoneData });
  }

  return (
    <Card>
      <Background
        image={bgImage}
        className="bg-card"
        style={{
          borderTopRightRadius: '0.375rem',
          borderBottomRightRadius: '0.375rem'
        }}
      />
      <Card.Body className="position-relative">
        <Row className=" align-items-sm-center">
          <Col xs="auto" className='d-none d-sm-block '>
            <img className="img-responsive" src={connectCircle} alt="connectCircle" height="45" />
          </Col>
          <Col>
            <Row className="align-items-center">
              <Col className="pe-xl-8">
                <h5 className="fs-sm-0 fs--1 mb-2 mb-sm-1 text-primary text-nowrap text-truncate">
                  Farm Environment {zoneData.name ? `| ${zoneData.name}` : ""} {zoneData.productDetails.id ? `| ${zoneData.productDetails.name}` : ""}
                </h5>

              </Col>
              <Col xs="auto" className="ms-md-auto ms-0">
                <p className="fs--2 mb-3 mb-sm-1 align-items-sm-center"> Device ID: {zoneData.productDetails.id ? zoneData.productDetails.id : "--"}
                {/* <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip> Device Offline</Tooltip>
                  }
                >
                  <div>
                    <FontAwesomeIcon
                      icon={'circle'}
                      transform="shrink-1"
                      className="ms-1"
                      id="avgCallTooltip"
                      color='#e63757'
                    />
                  </div>
                </OverlayTrigger> */}
                </p>
              </Col>
              <Col xs="auto" className="ms-auto me-auto align-items-center">
                <Flex>

                  <Button className="px-4 ms-1 fs-sm-0 fs--1" variant="falcon-primary" disabled={zoneData.productDetails.id ? false : true} onClick={routeChangeAnalytics}>Analytics</Button>
                  <Button className="px-4 ms-3 fs-sm-0 fs--1" variant="falcon-primary" onClick={routeChangeConfig}>Configure</Button>
                </Flex>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default FarmEnvironmentConfig;

import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import classNames from 'classnames';
import Background from '../common/Background';
import { useHistory } from "react-router-dom";

const FarmCard = ({ stat, meta, dispatchFarmState,...rest }) => {
  const {
    _id,
    name,
    city,
    state,
    zip,
    className
  } = stat;

  const history = useHistory();

  const routeChange = (data) => {
    let path = `/zone-1/farm-management`;
    history.push(path, { params: data });
  }

  const go_to_farm = async () => {
    console.log("go to farm", name, _id);
    dispatchFarmState({
      type:"SET_CURRENT_FARM",
      payload: stat
    });
    history.push('/dashboard');
  }

  return (
    <div onClick={go_to_farm} style={{ cursor: "pointer" }}>
      <Card className={classNames(className, 'overflow-hidden')} {...rest}>
        <Background image={meta.image} className="bg-card" />
        <Card.Body className="position-relative">
          <h6>
            {meta.type}
          </h6>
          <div
            className={classNames(
              meta.valueClassName,
              'display-4 fs-4 mb-2 fw-normal font-sans-serif'
            )}
          >
            {name}
          </div>
          <span className="fw-semi-bold fs--1 text-nowrap" style={{ color: "#2c7be5" }}>
            {city}, {state} - {zip}
          </span>
        </Card.Body>
      </Card>
    </div>
  );
};

FarmCard.propTypes = {
  stat: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.number.isRequired,
    //image: PropTypes.string,
    className: PropTypes.string
  }),
  meta: PropTypes.shape({
    type: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    valueClassNames: PropTypes.string
  })
};

export default FarmCard;

import React, { useContext, useState, useEffect } from 'react';
import FarmSettingsBanner from '../FarmSettingsBanner';
import coverSrc from 'assets/img/generic/4.jpg';
import { Col, Row, Spinner } from 'react-bootstrap';
import BackTop from '../../BackTop';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import InvitePeople from './invite-people/InvitePeople';
import Roles from './Roles/Roles';
import DashboardContext from 'Context/DashboardContext';
import { getInvitedUsers, inviteUser } from 'API/hydroponicsFarmsAPI';
import AuthContext from 'Context/AuthContext';
import BackButton from 'Components/common/BackButton';

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 2000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}
const Permissions = () => {

  const { dashboardState } = useContext(DashboardContext)
  const { authState } = useContext(AuthContext)
  const [data, setData] = useState({
    farmId: dashboardState.farmDetails ? dashboardState.farmDetails._id : '',
    invitedUserData: [],
    canInvite: false
  })
  const [isLoading, setIsLoading] = useState(false);


  const getInvitedUsersData = async () => {
    setIsLoading(true)
    let resData = await getInvitedUsers(authState.accessToken, { farmId: data.farmId })
    setIsLoading(false)
    if (resData.success) {
      console.log(resData.data)
      setData((prevState) => {
        return {
          ...prevState,
          invitedUserData: resData.data
        }
      })
    }
  }


  useEffect(() => {
    console.log()
    setData((prevState) => {
      return {
        ...prevState,
        farmId: dashboardState.farmDetails ? dashboardState.farmDetails._id : '',
        canInvite: dashboardState.farmDetails ? (dashboardState.farmDetails.own ? false : true) : false
      }
    })

  },
    [dashboardState.farmDetails])

  useEffect(() => {
    if (data.farmId) {
      getInvitedUsersData()
    }
  }, [data.farmId])

  return (
    <>
      <AlertProvider template={AlertTemplate} {...options}>
        <BackTop />
        <BackButton />
        {/* <FarmSettingsBanner>
          <FarmSettingsBanner.Header
            coverSrc={coverSrc}
            className="mb-0"
          />
        </FarmSettingsBanner> */}
        <Row className="g-3 mb-3">
          <Col xxl={9}>
            <InvitePeople canInvite={data.canInvite} getInvitedUsersData={getInvitedUsersData} />
          </Col>
        </Row>
        <Row className="g-3 mb-3">
          <Col xxl={9}>
            {isLoading ?
              <div className="text-center">
                <Spinner animation="border" variant="primary" />
              </div>
              :
              <Roles invitedUserData={data.invitedUserData} canUnInvite={data.canInvite} getInvitedUsersData={getInvitedUsersData}/>}
          </Col>
        </Row>
      </AlertProvider>
    </>
  );
};

export default Permissions;

import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Form, Row, Col, Spinner, InputGroup } from 'react-bootstrap';
import Divider from '../common/Divider';
import SocialAuthButtons from '../common/SocialAuthButtons';
import { useHistory } from "react-router-dom";
import { loginSubmit } from '../../API/Authentication';
import AuthContext from 'Context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/free-regular-svg-icons'

const LoginForm = ({ hasLabel, layout }) => {
  // State
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });

  const { authState, authDispatch } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setLoginError('');
    let resData = await loginSubmit(formData.email, formData.password);
    setIsLoading(false);
    if (resData.status === 'success') {
      authDispatch({
        type: "LOGIN_SUCCESS",
        payload: {
          accessToken: resData.data.access_token,
          username: resData.data.user_name,
          email: resData.data.email
        }
      })
      history.push('/type-selection')
    }
    if (resData.status == 'error') {
      console.log(resData)
      setLoginError(resData.data);
    }
    // console.log(resData);
  }

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };


  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>
      <Form.Group className="mb-3 d-flex align-items-center">
          <Form.Control
            placeholder={!hasLabel ? 'Password' : ''}
            value={formData.password}
            name="password"
            onChange={handleFieldChange}
            type={showPassword ? 'text' : 'password'}
            
          />
          <FontAwesomeIcon className="mx-2" type='button' icon={ !showPassword ? 'eye' : faEyeSlash } onClick={() => setShowPassword(!showPassword)} />
          

      </Form.Group>
      <Row className="justify-content-between align-items-center">
        {/* <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="ms-2 mb-0">
              Remember Me
            </Form.Check.Label>
          </Form.Check>
        </Col> */}
        <Col xs="auto">
          <Link
            className="fs--1 mb-0"
            to={`/auth/forgot-password`}
          >
            Forget Password?
          </Link>
        </Col>
      </Row>

      <Row>
        <p className="fs--1 mb-0 text-danger">
          {loginError ? loginError : ''}
        </p>
      </Row>

      <Form.Group >
        {
          <Button
            type="submit"
            color="primary"
            className="mt-3 w-100"
            disabled={!formData.email || !formData.password || isLoading}
          >
            {!isLoading ? 'Login' : <Spinner animation="border" />}
          </Button>
        }
      </Form.Group>
      <Divider className="mt-4">Don't have an account</Divider>
      {/* <SocialAuthButtons /> */}
      <div className="text-center">
        <Link
          className=" text-decoration-underline"
          to="/auth/signup"
        >
          Register
        </Link>
      </div>
    </Form>
  );
};
LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};
LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};
export default LoginForm;
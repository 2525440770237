export const timeList = [
    {
        val: 0,
        label: '12:00 AM'
    },
    {
        val: 1,
        label: '1:00 AM'
    },
    {
        val: 2,
        label: '2:00 AM'
    },
    {
        val: 3,
        label: '3:00 AM'
    },
    {
        val: 4,
        label: '4:00 AM'
    },
    {
        val: 5,
        label: '5:00 AM'
    },
    {
        val: 6,
        label: '6:00 AM'
    },
    {
        val: 7,
        label: '7:00 AM'
    },
    {
        val: 8,
        label: '8:00 AM'
    },
    {
        val: 9,
        label: '9:00 AM'
    },
    {
        val: 10,
        label: '10:00 AM'
    },
    {
        val: 11,
        label: '11:00 AM'
    },
    {
        val: 12,
        label: '12:00 PM'
    },
    {
        val: 13,
        label: '1:00 PM'
    },
    {
        val: 14,
        label: '2:00 PM'
    },
    {
        val: 15,
        label: '3:00 PM'
    },
    {
        val: 16,
        label: '4:00 PM'
    },
    {
        val: 17,
        label: '5:00 PM'
    },
    {
        val: 18,
        label: '6:00 PM'
    },
    {
        val: 19,
        label: '7:00 PM'
    },
    {
        val: 20,
        label: '8:00 PM'
    },
    {
        val: 21,
        label: '9:00 PM'
    },
    {
        val: 22,
        label: '10:00 PM'
    },
    {
        val: 23,
        label: '11:00 PM'
    }

]
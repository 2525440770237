import React, { useState, useContext, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap';
import ZoneIntro from './ZoneIntro';
import { EditDeviceSettingsData } from './EditDeviceSettings/EditDeviceSettingsdata';
import EditDeviceSettings from './EditDeviceSettings/EditDeviceSettings';
import AddDeviceForm from './AddDeviceForm';
import RemoveDeviceForm from './RemoveDeviceForm';
import GeneralSettings from './GeneralSettings';
import DeviceName from './DeviceName';
import VpdGuidance from './VpdGuidance';
import Callibaration from './Callibaration';
import BackButton from './BackButton';
import BackTop from '../BackTop';
import climatedata from 'Components/Others/climatedata';
import { useLocation } from 'react-router-dom';
import { createContext } from 'react';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import Notification from './Notification';
import AuthContext from 'Context/AuthContext';
import { getDeviceDetails } from 'API/hydroponicsFarmsAPI';
// import { ZoneContext } from '../index'
const ZoneConfigContext = createContext();


const options = {
    // you can also just use 'bottom center'
    position: positions.BOTTOM_CENTER,
    timeout: 2000,
    offset: '30px',
    // you can also just use 'scale'
    transition: transitions.SCALE
}

const FarmConfig = () => {
    const zoneData = useLocation();
    const { authState } = useContext(AuthContext);
    const [data, setData] = useState({
        zoneData: zoneData.state.params,
        deviceData: {}
    });

    const loadDeviceDetails = async(deviceId) => {
        let resData = await getDeviceDetails(authState.accessToken, { deviceId });
        console.log(resData);
        if (resData.success) {
            setData({
                ...data,
                deviceData: resData.data
            })
        }
    }

    useEffect(() => {
        setData({
            zoneData: zoneData.state.params,
            deviceData: {}
        });
        if (data.zoneData.productDetails.id) {
            
            loadDeviceDetails(data.zoneData.productDetails.id);
        }
    }, [zoneData]);




    return (
        <ZoneConfigContext.Provider value={data}>
            <AlertProvider template={AlertTemplate} {...options}>
                <BackTop />
                {/* <ContextData.Provider value={data}> */}
                <Row className='g-3 mb-0'>
                    <Col xxl={12}>
                        <BackButton />
                        <ZoneIntro />
                    </Col>
                </Row>
                <Row className='g-3 mb-0'>
                    <Col xxl={12} lg={12}>
                        <EditDeviceSettings titledata={EditDeviceSettingsData} />
                    </Col>
                </Row>
                <Row className='g-3 mb-0'>
                    <Col xxl={12}>
                        {!data.zoneData.productDetails.id ? (<AddDeviceForm />) : (<RemoveDeviceForm />)}
                        {!data.zoneData.productDetails.id ? '' : (<GeneralSettings />)}
                        {!data.zoneData.productDetails.id ? '' : (<DeviceName />)}
                        {!data.zoneData.productDetails.id ? '' : (<VpdGuidance />)}
                        {!data.zoneData.productDetails.id ? '' : (<Callibaration />)}
                        {/* {!data.zoneData.productDetails.id ? '' : (<Notification />)} */}
                    </Col>
                </Row>
                {/* </ContextData.Provider> */}
            </AlertProvider>
        </ZoneConfigContext.Provider>
    )
}

export default FarmConfig
export { ZoneConfigContext };

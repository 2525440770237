import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart, BarChart } from 'echarts/charts';
import { getColor, getPastDates } from 'helpers/utils';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import dayjs from 'dayjs';
import moment from 'moment';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent,
  BarChart
]);

const tooltipFormatter = (params, unit, name) => {
  return `<div class="card">
              <div class="card-header bg-light py-2">
                <h6 class="text-600 mb-0">${moment(params[0].axisValue, 'DD-MM-YYYY hh-mm-ss').format('DD-MMM-YY HH:mm')}</h6>
              </div>
            <div class="card-body py-2">
              <h6 class="text-600 fw-normal">
                <span class="dot me-1 d-inline-block bg-primary"></span>
                
                <span class="fw-medium">${name} : ${params[0].data} ${unit}</span>
              </h6>
              
            </div>
          </div>`;
};

const getOptions = (data1, xData, tf_index, unit, name) => (
  {
    color: getColor('white'),
    tooltip: {
      trigger: 'axis',
      padding: 0,
      backgroundColor: 'transparent',
      borderWidth: 0,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      },
      formatter: params => {
        return tooltipFormatter(params, unit, name);
      }
    },
    xAxis: {
      type: 'category',
      // data: getPastDates(24).map(date => dayjs(date).format('DD MMM, YYYY')),
      data: xData,
      axisLabel: {
        color: getColor('600'),
        formatter: value => (tf_index == 0) ? moment(value, 'DD-MM-YYYY hh:mm:ss').format('HH:mm') : moment(value, 'DD-MM-YYYY hh:mm:ss').format('DD MMM'),
        align: 'left',
        fontSize: 11,
        padding: [0, 0, 0, 5],
        showMaxLabel: false
      },
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      boundaryGap: true
    },
    yAxis: {
      position: 'right',
      axisPointer: { type: 'none' },
      axisTick: 'none',
      splitLine: {
        show: false
      },
      axisLine: {
        show: false
      },
      axisLabel: {
        show: false
      },
    },
    series: [
      {
        type: 'line',
        name: 'Device Data',
        data: data1,
        symbol: 'circle',
        symbolSize: 6,
        animation: false,
        itemStyle: {
          color: getColor('warning')
        },
        lineStyle: {
          type: 'dashed',
          width: 2,
          color: getColor('warning')
        }
      }
    ],
    grid: { right: 5, left: 5, bottom: '8%', top: '5%' }
  });

const MonitoringParamatersChart = ({ data, tf_index, xData, unit, name }) => {
  // const [xDataFormat, setXDataFormat] = useState("");
  useEffect(() => {
    console.log(xData);
    // let dataFormat = [];
    // if(tf_index === '0'){
    //   xData.forEach(element => {
    //     dataFormat.push(moment(element).format('DD MMM, YYYY'));
    //   })
    //   setXDataFormat(dataFormat);
    // }
    console.log(tf_index);
  }, [tf_index]);
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOptions(data, xData, tf_index, unit, name)}
      style={{ height: '20rem' }}
    />
  );
};

// MonitoringParamatersChart.propTypes = {
//   data: PropTypes.arrayOf(PropTypes.array).isRequired
// };

export default MonitoringParamatersChart;

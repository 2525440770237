import React from 'react';
import PropTypes from 'prop-types';
import WizardLayout from './WizardLayout';
import FarmSetupContextProvider from './FarmSetupContextProvider';

const Wizard = () => {
  return (
    <FarmSetupContextProvider>
      <WizardLayout
        variant={""}
        validation={true}
        progressBar={true}
      />
    </FarmSetupContextProvider>
  );
};

// Wizard.propTypes = {
//   variant: PropTypes.oneOf(['pills']),
//   validation: PropTypes.bool,
//   progressBar: PropTypes.bool
// };

export default Wizard;

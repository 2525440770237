import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button,Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const GeneralSettings = (props) => {

  return (
    <Card className="bg-light my-3">
      <Card.Body className="p-3 position-relative">
        <Row className="align-items-center row">
          <Col className="order-1 col-12 col-lg-9 col-md-8 col-sm-7">
            <p className="fs--1 mb-0 text-left text-primary">
              <FontAwesomeIcon
                icon="exchange-alt"
                className="me-2"
                transform="rotate-90"
              />
              <strong className="fs-0">General Settings</strong>
              {props && props.multiDeviceChannel && (
                <span>
                  {"  "}
                  for "<strong className='fs--1'>Channel</strong>" {parseInt(props.channel)+1} Automation Panel
                </span>
              )}
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default GeneralSettings;

export const DeviceInfoData = [
    {
      title: 'Devices',
      text: '25',
      icon: 'chess-rook',
      color: 'primary'
    },
    {
      title: 'Farm Size',
      text: '2000 Sqft',
      icon: 'crown',
      color: 'warning'
    },
    {
      title: 'Total Zones',
      text: '10',
      icon: 'user',
      color: 'info'
    },
    {
      title: 'Location',
      text: 'Ahmedabad',
      icon: 'video',
      color: 'success'
    },
    {
      title: 'Capacity',
      text: '2000 Sqft',
      icon: 'crown',
      color: 'info'
    },
    {
      title: 'Farm Type',
      text: 'Hydrophonics',
      icon: 'user',
      color: 'info'
    },
    
  ];

import React, { useContext } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import AuthRoutes from './AuthRoutes'
import ProtectedRoute from './ProtectedRoute'
import FarmSelection from 'Pages/Farms/FarmSelection'
import FarmTypeSelection from 'Pages/Farms/FarmTypeSelection'
import FarmSetup from 'Pages/Farms/FarmSetup'
import AuthContext from 'Context/AuthContext'
import DashboardLayout from 'Pages/Dashboard/DashboardLayout'
import { toast, ToastContainer } from 'react-toastify';

import FarmRoutesProvider from './FarmRoutesProvider'
import DashboardProvider from 'Pages/Dashboard/DashboardProvider'

const SetupRoutes = () => {
  return (
    <>
      <Switch>
        <Route path="/auth" component={AuthRoutes} />
        <ProtectedRoute exact path="/type-selection" component={FarmTypeSelection} contextComponentProvider={FarmRoutesProvider} />
        <ProtectedRoute exact path="/farm-selection" component={FarmSelection} contextComponentProvider={FarmRoutesProvider} />
        <ProtectedRoute exact path='/addFarm' component={FarmSetup} contextComponentProvider={FarmRoutesProvider} />
        <ProtectedRoute path='/dashboard' component={DashboardLayout} contextComponentProvider={FarmRoutesProvider} secondaryContextProvider={DashboardProvider} />
        <Route render={() => <Redirect to="/type-selection" />} />
      </Switch>
      <ToastContainer
        position={toast.POSITION.BOTTOM_CENTER}
      />
    </>
  )
}

export default SetupRoutes
import React, { useState, useEffect, useContext } from 'react';
import { Card, Col, Row, Form, FormControl, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getRelayDeviceDetails,
  UpdateSensorBasedData,
  updateTriggerDetails,
  updateTriggerDetails2,
} from 'API/hydroponicsFarmsAPI';
import AuthContext from 'Context/AuthContext';
import DashboardContext from 'Context/DashboardContext';
import { date } from 'is_js';
import { subsetTransformDependencies } from 'mathjs';
const Triggers = ({ triggerChanger, ...rest }) => {
  console.log({ triggerChanger, rest });
  const [automation, setAutomation] = useState(true);
  const [triggerType, setTriggerType] = useState(0);
  const data = useContext(DashboardContext);
  const generalDevice = rest.deviceData;
  const { authState } = useContext(AuthContext);

  const [timeTrigger, setTimeTrigger] = useState({
    runTrigger: "Every 5 mins",

    daily_trigger: [],
    onCustomDuration: "",
    offCustomDuration: "",
  });
  const [sensorTrigger, setSensorTrigger] = useState({
    sensor_comparison: "",
    upperLimit: "",
    sensorType: "",
    upperLimitUnit: "",
    lowerLimit: "",

    onDuration: "",

    interval: "",

    performanceMode: false,
    sensorId: "",
  });

  const loadDeviceDetails = async (deviceId) => {
    console.log("loaddevicedetails", deviceId);
    let resData = await getRelayDeviceDetails(authState.accessToken, {
      deviceId,
    });
    console.log("resdata", resData);
    // rest.channel
    if (resData.success) {
      if (automation !== resData.data[rest.channel].is_automation) {
        setAutomation(resData.data[rest.channel].is_automation);
      }

      console.log(resData.data[rest.channel].sensor_id);
      console.log(resData.data[rest.channel].run_trigger);
      if (resData.data[rest.channel].sensor_trigger !== undefined) {
        setSensorTrigger((preValue) => {
          return {
            ...preValue,
            sensorId: resData.data[rest.channel].sensor_trigger.sensor_id,
            sensorType: resData.data[rest.channel].sensor_trigger.sensor_type,
            sensor_comparison: resData.data[rest.channel].sensor_trigger.type,
            upperLimit: resData.data[rest.channel].sensor_trigger.upperLimit,
            lowerLimit: resData.data[rest.channel].sensor_trigger.lowerLimit,
            onDuration: resData.data[rest.channel].sensor_trigger.on_duration,
            interval:
              resData.data[rest.channel].sensor_trigger.off_duration !== undefined
                ? resData.data[rest.channel].sensor_trigger.off_duration
                : "",
            performanceMode: resData.data[rest.channel].sensor_trigger.is_performance_mode,
          };
        });

        setTriggerType(2);
      } else if (resData.data[rest.channel].time_trigger !== undefined) {
        setTimeTrigger((preValue) => {
          return {
            ...preValue,
            runTrigger: resData.data[rest.channel].time_trigger.run_trigger,
            onCustomDuration:
              resData.data[rest.channel].time_trigger.on_duration !== undefined
                ? resData.data[rest.channel].time_trigger.on_duration
                : "",
            offCustomDuration:
              resData.data[rest.channel].time_trigger.off_duration !== undefined
                ? resData.data[rest.channel].time_trigger.off_duration
                : "",
            daily_trigger:
              resData.data[rest.channel].time_trigger.daily_trigger !== undefined
                ? resData.data[rest.channel].time_trigger.daily_trigger
                : "",
          };
        });
    // 0 -> rest.channel

        setTriggerType(1);
      } else {
        setTriggerType(0);
      }
    }
  };

  useEffect(() => {
    loadDeviceDetails(rest.RelayDeviceId);
    console.log("calle",rest);
  }, [automation,rest.channel]);

  console.log("automation", automation);
  function TempAutomationSetting(autoTemp1) {
    setAutomation(!automation);
  }
  const inputEvent = async (e) => {
    //console.log(automation);
    console.log("evet ",e.target.checked);
    console.log("trigger tye",)
    let auto = !automation;
    console.log("123automationtrigger",{ timeTrigger, triggerType, authState,generalDevice });
    let response;
    if (triggerType == 1 || triggerType == 0 ) {
      if (timeTrigger.runTrigger == "Daily at") {
        response = await updateTriggerDetails(authState.accessToken, {
          auto: auto,
          pushDetails: timeTrigger.daily_trigger,
          channel:rest.channel,
          name: generalDevice.deviceName,
          purpose: generalDevice.purpose,
          ratedPower: generalDevice.ratedPower,
          noOfLoads: generalDevice.noOfLoads,
          id: rest.RelayDeviceId,
        });
      } else {
        response = await updateTriggerDetails2(authState.accessToken, {
          auto: auto,
          name: generalDevice.deviceName,
          purpose: generalDevice.purpose,
          channel:rest.channel,
          ratedPower: generalDevice.ratedPower,
          noOfLoads: generalDevice.noOfLoads,
          pushDetails: timeTrigger.runTrigger,
          onDuration: timeTrigger.onCustomDuration,
          offDuration: timeTrigger.offCustomDuration,
          id: rest.RelayDeviceId,
        });
      }
    } else if (triggerType == 2) {
      
      // sensorId: sensorTrigger.sensorId ==="" ? "FAV99999AA":sensorTrigger.sensorId ,
      // sensorType: sensorTrigger.sensorType === ""?"":sensorTrigger.sensorType,
      // sensor_comparison: sensorTrigger.sensor_comparison,
      // upperLimit: sensorTrigger.upperLimit === ""? "30":sensorTrigger.upperLimit ,
      // lowerLimit: sensorTrigger.lowerLimit === ""? "20":sensorTrigger.lowerLimit,
      // onDuration: sensorTrigger.onDuration === ""? "30*min":sensorTrigger.onDuration,
      // offDuration: sensorTrigger.interval === ""? "30*min":sensorTrigger.interval,


      response = await UpdateSensorBasedData(authState.accessToken, {
        name: generalDevice.deviceName,
        purpose: generalDevice.purpose,
        ratedPower: generalDevice.ratedPower,
        channel:rest.channel,
        noOfLoads: generalDevice.noOfLoads,
        auto: auto,
        relayId: rest.RelayDeviceId,
        sensorId: sensorTrigger.sensorId,
        sensorType: sensorTrigger.sensorType ,
        sensor_comparison: sensorTrigger.sensor_comparison,
        upperLimit: sensorTrigger.upperLimit ,
        lowerLimit: sensorTrigger.lowerLimit,
        onDuration: sensorTrigger.onDuration ,
        offDuration: sensorTrigger.interval,
        performanceMode: sensorTrigger.performanceMode,
      });
    }
    if (response && response.success) {
      setTimeout(TempAutomationSetting, 200);
    }
    // if(e.target.checked)
    // setAutomation(1);
  };

  const selectEvent = (event) => {
    //console.log(event.target.value);
    // console.log("event", event.target.value,triggerType);
    // triggerChanger(event.target.value);
    setTriggerType(event.target.value);
  };

  useEffect(() => {
    if (automation) {
      //console.log(triggerType);
      triggerChanger(triggerType);
    } else {
      //console.log('');
      triggerChanger(0);
    }
  }, [automation, triggerType]);
  console.log("trigger",triggerType)

  return (
    <>
      <Card className="bg-light my-3">
        <Card.Body className="p-3 position-relative">
          <Row className="align-items-center row">
            <Col>
              <p className=" mb-0 text-left text-primary">
                <strong className="fs-0">Triggers</strong>
              </p>
            </Col>
          </Row>
          <Row className="no-gutters mt-2">
            <Col className="col-lg-1 col-md-2 col-4 me-4 me-md-2 me-lg-5">
              <p className="fs-0 text-primary text-nowrap">Automation</p>
            </Col>
            <Col className="col-md-2">
              <Form.Check
                type="switch"
                id="checkedSwitch"
                checked={automation}
                onChange={inputEvent}
              />
            </Col>
          </Row>
          <Row className="align-items-center row">
            <Form>
              <Row className="no-gutters justify-content-start">
                <Col className="col-4 col-sm-2">
                  <Form.Text className="text-nowrap me-2 mt-3 text-primary fs-0">
                    Add Trigger
                    {/* <strong className='fs-0'>Add Trigger</strong> */}
                    <FontAwesomeIcon icon="plus" className="fs--1 ms-1" />
                  </Form.Text>
                </Col>
                <Col className="col-8 col-sm-4 mt-0">
                  <select
                    className="form-select fs--1"
                    disabled={automation ? "" : "disabled"}
                    aria-label="Default select example"
                    value={triggerType}
                    onChange={selectEvent}
                  >
                    <option value="0" disabled selected>
                      choose...
                    </option>
                    <option value="1">Time-based trigger</option>
                    <option value="2">Sensor-based trigger</option>{" "}
                    {/* 
                            <option value='1' selected>Time-based trigger</option>
                            <option value='2'>Sensor-based trigger</option> */}
                  </select>
                </Col>
              </Row>
            </Form>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default Triggers;

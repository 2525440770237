import Section from 'Components/common/Section'
import React from 'react'
import { Button, Row, Col, Image } from 'react-bootstrap'
import Wizard from './FarmSetup/Wizard'
import logo from 'assets/img/illustrations/4Climate_Logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom';

const FarmSetup = () => {
    const history = useHistory();
    const backHome = () => {
        history.push('/farm-selection');
    }
    return (
        <Section fluid className="py-0 ">
            <Row className="justify-content-center pt-4">

                <Col sm={10} lg={7} className="col-xxl-5 text-center">
                    <Image
                        className="mb-3"
                        src={logo}
                        width="200em"
                    />
                </Col>
            </Row>
            <Row className='mb-4'>
                <Col>
                    <Button variant="outline-primary" onClick={backHome}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                </Col>
               
            </Row>
            <Wizard />
        </Section>
    )
}

export default FarmSetup
import React, { useState, useContext, useEffect } from 'react';
import FarmSettingsBanner from './user/FarmSettingsBanner';
import coverSrc from 'assets/img/generic/4.jpg';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import FalconCardHeader from 'Components/common/FalconCardHeader';
// import { state } from '../../user/profile/StateCity';
import BackTop from './BackTop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BackButton from './FarmEnvironmentConfigPage/BackButton';
import DashboardContext from 'Context/DashboardContext';
import { useHistory } from 'react-router-dom';
import AuthContext from 'Context/AuthContext';
import { deleteZone, updateZone } from 'API/hydroponicsFarmsAPI';
import ZoneDeleteModal from './ZoneDeleteModal';
import { useLocation } from 'react-router-dom';
import { timeList } from 'helpers/timeList';

const ZoneDetails = () => {

  const { dashboardState } = useContext(DashboardContext);
  let zoneData = useLocation().state.params;
  const { authState } = useContext(AuthContext);
  const history = useHistory();

  const [formData, setFormData] = useState({
    zone_stage: '',
    zone_name: '',
    sunrise: '',
    sunset: '',
    farm_id: dashboardState.farmDetails._id ? dashboardState.farmDetails._id : '',
    zone_id: zoneData._id ? zoneData._id : '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);


  useEffect(() => {
    if (Object.keys(zoneData).length === 0 && Object.keys(dashboardState.activeZone).length === 0) {
      console.log("No ZOne Selected");
      history.push('/dashboard');
    }
    else if (Object.keys(zoneData).length === 0 && Object.keys(dashboardState.activeZone).length !== 0) {
      zoneData = dashboardState.activeZone;
    }
    console.log("zone details", zoneData);
    setFormData({
      zone_name: zoneData.name,
      zone_stage: zoneData.stage,
      sunrise: zoneData.sunrise,
      sunset: zoneData.sunset,
    })
  }, []);

  useEffect(() => {
    if (Object.keys(zoneData).length === 0 && Object.keys(dashboardState.activeZone).length !== 0) {
      zoneData = dashboardState.activeZone;
    }
    setFormData({
      zone_name: zoneData.name,
      zone_stage: zoneData.stage,
      sunrise: zoneData.sunrise,
      sunset: zoneData.sunset,
      farm_id: dashboardState.farmDetails._id ? dashboardState.farmDetails._id : '',
      zone_id: zoneData._id ? zoneData._id : '',
    })
    // if(zoneData)
  },
    [dashboardState])

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    setIsLoading(true);
    let resData = await updateZone(authState.accessToken, formData);
    setIsLoading(false);
    console.log(resData);
    if (resData.success) {
      history.push('/dashboard');
      history.go(0);
    }
  };


  const handleDeleteZone = async () => {
    setIsLoading(true);
    let resData = await deleteZone(authState.accessToken, { farm_id: formData.farm_id, zone_id: formData.zone_id });
    setIsLoading(false);
    console.log(resData);
    if (resData.success) {
      history.push('/dashboard');
      history.go(0);
    }
    if (resData.error) {
      console.log(resData.error)
    }
  }


  const toggleModal = (modal) => {
    setShowDeleteModal(modal);
  }

  const deleteZoneModal = (e) => {
    e.preventDefault();
    setShowDeleteModal(true);
  }


  return (
    <>
      <ZoneDeleteModal modal={showDeleteModal} setModal={toggleModal} zone_name={formData.zone_name} deleteZone={handleDeleteZone} />
      <BackTop />
      <BackButton />
      <FarmSettingsBanner>
        <FarmSettingsBanner.Header
          coverSrc={coverSrc}
          className="mb-0"
        />
      </FarmSettingsBanner>

      <Card>
        <FalconCardHeader title="Zone Details" />
        <Card.Body className="bg-light">
          {!isLoading ? <Form onSubmit={handleSubmit}>

            <Form.Group className="mb-3" controlId="zone_name">
              <Form.Label>Zone Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Zone Name"
                value={formData.zone_name}
                name="zone_name"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="zone_stage">
              <Form.Label>Stage</Form.Label>
              <Form.Select aria-label="Select Farm" placeholder='Select your Farm' value={formData.zone_stage}
                name="zone_stage"
                onChange={handleChange}>
                <option>--Select--</option>
                <option value='seedling'>Seedling</option>
                <option value='vegetative'>Vegetative</option>
                <option value='flowering'>Flowering</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="sunrise">
              <Form.Label>Sunrise Time</Form.Label>
              <Form.Select
                placeholder="sunrise Time"
                value={formData.sunrise}
                name="sunrise"
                onChange={handleChange}
              >
                <option value="">--Select--</option>
                {timeList.map((time) => {
                  return (
                    <option key={time.val} value={time.val}>{time.label}</option>
                  )
                })}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="sunrise">
              <Form.Label>Sunset Time</Form.Label>
              <Form.Select
                placeholder="sunset Time"
                value={formData.sunset}
                name="sunset"
                onChange={handleChange}
              >
                <option value="">--Select--</option>
                {timeList.map((time) => {
                  return (
                    <option key={time.val} value={time.val}>{time.label}</option>
                  )
                })}
              </Form.Select>
            </Form.Group>

            {/* <Form.Group className="mb-3" controlId="sunrise">
              <Form.Label>Sunrise Time</Form.Label>
              <Form.Control
                type="text"
                placeholder="sunrise Time"
                value={formData.sunrise}
                name="sunrise"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="sunrise">
              <Form.Label>Sunset Time</Form.Label>
              <Form.Control
                type="text"
                placeholder="Sunset Time"
                value={formData.sunset}
                name="sunset"
                onChange={handleChange}
              />
            </Form.Group> */}


            <Form.Group className="mt-3">
              <Button variant="primary" type="submit" style={{ float: "right" }}>
                Save
              </Button>
            </Form.Group>
            <Button variant="danger" style={{ float: "right", marginRight: "5px" }} onClick={deleteZoneModal}>
              Delete
            </Button>
          </Form>

            :
            <div className="text-center">
              <Spinner animation="border" variant="primary" />
            </div>
          }
        </Card.Body>
      </Card>
    </>
  )
}

export default ZoneDetails

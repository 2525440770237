import React, { useEffect } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import Background from 'Components/common/Background';
import bgImage from 'assets/img/icons/spot-illustrations/corner-5.png';
import connectCircle from 'assets/img/icons/connect-circle.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { ZoneContext } from './index';
import DashboardContext from 'Context/DashboardContext';
import { useContext } from 'react';

const RelaySwitches = () => {
  const { dashboardState, dispatchDashboardState } = useContext(DashboardContext);
  const zoneData = useContext(ZoneContext);

  
  const history = useHistory();
  const routeChangeRelay = (e) => {
    e.preventDefault();
    let path = `/dashboard/${dashboardState.activeZone.name.trim()}/farm-management/relay-switch/relay-config`;
    
    console.log(zoneData);
    history.push(path, { params: zoneData , deviceStatus: true});
  };
  return (
    <Card>
      <Background
        image={bgImage}
        className='bg-card'
        style={{
          borderTopRightRadius: '0.375rem',
          borderBottomRightRadius: '0.375rem',
        }}
      />
      <Card.Body className='position-relative'>
        <Row className=' align-items-sm-center'>
          <Col xs='auto' className='d-none d-sm-block '>
            <img
              className='img-responsive'
              src={connectCircle}
              alt='connectCircle'
              height='45'
            />
          </Col>
          <Col>
            <Row className='align-items-center'>
              <Col className='pe-xl-8'>
                <h5 className='fs-sm-0 fs--1 mb-1 mb-sm-1 text-primary text-nowrap'>
                  IoT Farm Automation
                </h5>
              </Col>
              <Col xs='auto' className='ms-auto me-auto align-items-center'>
                <Button
                  className='px-2 me-1 fs-sm-0 fs-0'
                  style={{ borderRadius: '999px' , height: "35px",
                  width: "35px", }}
                  variant='falcon-primary'
                  onClick={routeChangeRelay}
                >
                  <FontAwesomeIcon icon='plus' className='fs-0' />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default RelaySwitches;

import React, { useState, useEffect, useContext } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import climatedata from 'Components/Others/climatedata';
import { ZoneConfigContext } from '.';
import { getItemFromStore } from 'helpers/utils';
import AuthContext from 'Context/AuthContext';


const ZoneIntro = () => {
  const { authState } = useContext(AuthContext);
  const data = useContext(ZoneConfigContext);
  //console.log(data.zoneVal);
  const [zoneVal, setZone] = useState(1);

  const [zoneName, setZoneName] = useState('Zone 1');

  useEffect(() => {
    setZone(1);
    console.log(data)
    //setZoneName(climatedata[0].postalcode.zone[zoneVal-1].zonename)
  }, [data]);
  useEffect(() => {
    //console.log(climatedata[0].postalcode.zone[zoneVal-1]);
    setZoneName(climatedata[0].postalcode.zone[zoneVal - 1].zonename ?? "Zone 1")
  }, [zoneVal]);

  return (

    <Card className="bg-light my-3">
      <Card.Body className="p-3 position-relative">
        <Row className="align-items-center row">
          <Col className="order-1 col-12 col-lg-9 col-md-8 col-sm-7">
            <p className="fs--1 mb-0 text-left">
              <Link style={{ pointerEvents: 'none' }}>
                <FontAwesomeIcon
                  icon="exchange-alt"
                  className="me-2"
                  transform="rotate-90"
                />
                Hi! "{authState.username}". </Link>{' '}
              This is "device configuration" & settings of AER 360 device deployed in " {data.zoneData.name} ({getItemFromStore('farmState').currentFarmData.farm.name})"
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ZoneIntro;

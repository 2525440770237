const LightingEnvironmentdata = [
    {
        id: 1,
        sname: "Light Intensity",
        readings: "1200 lux",
        comment: "Trendline for last 6 hours",
        data: [8, 15, 12, 14, 18, 12, 12, 25, 13, 12, 10, 13, 15]
    },
    {
        id: 2,
        sname: "Par",
        readings: "1000 PAR",
        comment: "Trendline for last 6 hours",
        data: [8, 18, 12, 12, 25, 13, 12, 10, 15, 12, 14, 13, 15]
    },
]

export default LightingEnvironmentdata;
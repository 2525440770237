import React, { useState, useContext, useEffect } from 'react';
import { Button, Form, FormControl, Row } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ZoneContext } from '../index';
import DashboardContext from 'Context/DashboardContext';
import AuthContext from 'Context/AuthContext';
import { useHistory } from 'react-router-dom';
import { addDevice } from 'API/hydroponicsFarmsAPI';
import { RelayConfigContext } from '.';

const AddDeviceForm = ({ stateChanger, idchanger,...rest }) => {
  const zoneData = useContext(RelayConfigContext);
  const [productID, setProductID] = useState('');
  const {dashboardState}  = useContext(DashboardContext);
  const history = useHistory();
  const { authState } = useContext(AuthContext);
 
  const [formData, setFormData] = useState({
    deviceId: '',
    deviceName: 'Default Name',
    farmId: dashboardState.farmDetails._id
      ? dashboardState.farmDetails._id
      : '',
    zoneId: zoneData.zoneData.zoneId ? zoneData.zoneData.zoneId : '',
  });
  const [error, setError] = useState('');
  const [NameError, setNameError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const routeChangeConfig = () => {
   
    let path = `/dashboard`;
    
  };
  const inputEvent = (e) => {
    let value = e.target.value;
    if (e.target.name === 'deviceId') {
      value = value.toUpperCase();
    }
    setFormData({
      ...formData,
      [e.target.name]: value,
    });
    setNameError(false);
    setProductID(false);
  };
  function reload(){
    history.go();
  }
  const onSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);

    setNameError(false);

    setIsLoading(true);
    setError('');
    let resData = await addDevice(authState.accessToken, formData);
    if (resData.success) {
      setIsLoading(false);
      setError('');
      // stateChanger(false);
      // idchanger(formData.deviceId)
      history.push('/dashboard/'+dashboardState.activeZone.name.trim()+'/farm-management')
      setTimeout( reload , 100)
    }
    if (resData.error) {
      setIsLoading(false);
      setError(resData.error);
      console.log(resData.error);
    }
    
  };
  useEffect(() => {
    setFormData((prevState) => {
      return {
        ...prevState,
        farmId: dashboardState.farmDetails._id
          ? dashboardState.farmDetails._id
          : '',
        zoneId: zoneData.zoneData.zoneId ? zoneData.zoneData.zoneId : '',
      };
    });
  }, [dashboardState, zoneData]);
  return (
    <>
      <Card className='bg-light my-3'>
        <Card.Body className='p-3 position-relative'>
          <Form className='d-flex flex-row' onSubmit={onSubmit}>
            <Form.Text className='text-nowrap me-2 mt-1 text-primary d-none d-sm-block '>
              <FontAwesomeIcon icon='plus' className='fs-0 me-1' />
              <strong className='fs-0'>Add Device</strong>
            </Form.Text>
            <FormControl
              className='fs--1'
              placeholder='Enter the Product ID'
              aria-label='Product ID'
              aria-describedby='basic-addon2'
              onChange={inputEvent}
              name='deviceId'
              value={formData.deviceId}
              required
            />
            <Button
              className='btn btn-primary text-nowrap'
              id='button-addon2'
              type='submit'
            >
              <span className='d-none d-sm-block'>Confirm</span>
              <span className='d-sm-none d-block'>Add</span>
            </Button>
            
          </Form>
          <div className='w-100'></div>
            <Row className='w-100 mt-2'>
              <span className='fs-0 text-danger'>{error ? error : ''}</span>
            </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default AddDeviceForm;

const RelaySwitchesdata = [
    {
        id: 1,
        device_id: "REL1011XXQ",
        switch_name: "Grow Lights 1"
    },
    {
        id: 2,
        device_id: "REL1022XXQ",
        switch_name: "Pump 1 HP"
    },
    {
        id: 3,
        device_id: "REL1033XXQ",
        switch_name: "Grow Lights 2"
    },
    {
        id: 4,
        device_id: "REL1033XXQ",
        switch_name: "Grow Lights 2"
    },
    
]

export default RelaySwitchesdata;
import React, { useState, useContext, useEffect } from 'react';
import Flex from 'Components/common/Flex';
import { Card, Col, Row } from 'react-bootstrap';
import barChart from 'assets/img/illustrations/crm-bar-chart.png';
import lineChart from 'assets/img/illustrations/crm-line-chart.png';
import GreetingsDate from './GreetingsDate';
import ExportButton from '../ExportButton';
import { GraphContext } from '../index';
import moment from 'moment';
import { sendReport } from 'API/hydroponicsFarmsAPI';
import AuthContext from 'Context/AuthContext';
import { useAlert } from 'react-alert';

const ExportSection = () => {
  const data = useContext(GraphContext);
  const { authState } = useContext(AuthContext);

  const date = new Date();
  const [formData, setFormData] = useState({
    dev_id: '',
    format: 'csv',
    start_date: moment(date.setDate(date.getDate() - 7)).format('YYYY-MM-DD'),
    end_date: moment(new Date()).format('YYYY-MM-DD'),
  })
  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const changeDate = (start_date, end_date) => {
    start_date = start_date ? moment(start_date).format('YYYY-MM-DD') : '';
    end_date = end_date ? moment(end_date).format('YYYY-MM-DD') : '';
    setFormData({
      ...formData,
      start_date,
      end_date,
    })
  }

  // useEffect(() => {
  //   console.log(formData)
  // },[formData])

  useEffect(() => {
    setFormData((prevState) => {
      return {
        ...prevState,
        dev_id: data.zoneData.productDetails.id
      }
    })
  }, [data.zoneData.productDetails])


  const exportData = async (e) => {
    e.preventDefault();
    if (formData.start_date && formData.end_date) {
      setLoading(true);
      let resData = await sendReport(authState.accessToken, formData);
      setLoading(false);
      if (resData.success) {
        console.log('Report sent successfully');
        alert.success('Report will be mailed in 5-10 minutes');
      }
      else {
        alert.error('Something went wrong');
      }
    }
    console.log(formData)

  }

  return (
    <>
      <Card className="bg-100 shadow-none border mb-3">
        <Card.Body className="py-0">
          <Row className="g-0 justify-content-between">
            <Col sm="auto">
              <Flex alignItems="center">
                <img src={barChart} width={90} alt="..." className="ms-n4" />
                <div>
                  {/* <h6 className="text-primary fs--1 mb-0">Welcome to </h6> */}
                  <h4 className="text-primary fw-bold mb-0">
                    Data
                    <span className="text-info fw-medium"> Export</span>
                  </h4>
                </div>
                <img
                  src={lineChart}
                  width={140}
                  alt="..."
                  className="ms-n4 d-md-none d-lg-block"
                />
              </Flex>
            </Col>
            <Col md="auto" className="mb-3 mb-md-0">
              <Row className="g-3 gy-md-0 h-100 align-items-center">
                <Col md="auto">
                  <select className="form-select" >
                    <option value="csv">.csv Format</option>
                    <option value="pdf" disabled>.pdf Format</option>
                    {/* <option value="2">Two</option>
                <option value="3">Three</option> */}
                  </select>
                </Col>
              </Row>
            </Col>
            <Col md="auto" className="mb-3 mb-md-0">
              <Row className="g-3 gy-md-0 h-100 align-items-center">
                <Col xs="auto">
                  <h6 className="text-700 mb-0 text-nowrap">Showing Data For:</h6>
                </Col>
                <Col md="auto">
                  <GreetingsDate changeDate={changeDate} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <ExportButton handleExport={exportData} loading={loading} />
    </>
  );
};

export default ExportSection;

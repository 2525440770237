import React, { useState, useEffect, useContext } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GraphContext } from './index';
import climatedata from 'Components/Others/climatedata';
import AuthContext from 'Context/AuthContext';
import { capitalize } from 'helpers/utils';

const ZoneAnalyticsIntro = () => {
  const data = useContext(GraphContext);
  const { authState }= useContext(AuthContext)
  //console.log(data.zoneVal);

  const [zoneName, setZoneName] = useState('');

  useEffect(() => {
    setZoneName(data.zoneData.name)
  }, [data.zoneData]);

  return (

    <Card className="bg-light my-3">
      <Card.Body className="p-3 position-relative">
        <Row className="align-items-center row">
          <Col className="order-1 col-12 col-lg-9 col-md-8 col-sm-7">
            <p className="fs--1 mb-0 text-left">
              <Link to="#!" style={{ pointerEvents: "none" }}>
                <FontAwesomeIcon
                  icon="exchange-alt"
                  className="me-2"
                  transform="rotate-90"
                />
                Hi! "{capitalize(authState.username)}". </Link>{' '}
              This is the analytics page for your farm environment monitoring device at {zoneName}
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ZoneAnalyticsIntro;

import React, { useState } from 'react'
import { Modal, Button, Form, ButtonGroup } from 'react-bootstrap';
import Lottie from 'react-lottie';
import animationData from '../FarmSetup/lottie/warning-light.json';
import Flex from 'Components/common/Flex';
import FalconCloseButton from 'Components/common/FalconCloseButton';

const ZoneDeleteModal = ({ modal, setModal, zone_name, deleteZone }) => {

    let [confirmDisabled, setConfirmDisabled] = useState(true);
    let [confirmText, setConfirmText] = useState('');

    const onChangeText = (e) => {
        e.preventDefault();
        setConfirmText(e.target.value);
        if (e.target.value === zone_name) {
            setConfirmDisabled(false);
        }
        else {
            setConfirmDisabled(true);
        }
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const onDeleteZone = (e) => {
        e.preventDefault();

        setModal(false);
        deleteZone();
    }

    return (
        <Modal show={modal} centered dialogClassName="wizard-modal">
            <Modal.Body className="p-4">
                <FalconCloseButton
                    size="sm"
                    className="position-absolute top-0 end-0 me-2 mt-2"
                    onClick={() => setModal(!modal)}
                />
                <Flex justifyContent="center" alignItems="center">
                    <Lottie options={defaultOptions} style={{ width: '100px' }} />
                    <p className="mb-0 flex-1">
                        This action cannot be reversed!<br />
                        Confirm the name of zone to be deleted
                    </p>
                </Flex>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Control placeholder={zone_name} value={confirmText} onChange={onChangeText} />
                    </Form.Group>
                    <div className='d-flex justify-content-center'>
                        <Button className="mx-1" disabled={confirmDisabled} onClick={onDeleteZone} >Confirm</Button>
                        <Button variant='danger' className="mx-1" onClick={() => setModal(false)}>Cancel</Button>
                    </div>
                </Form>

            </Modal.Body>
        </Modal>
    )
}

export default ZoneDeleteModal
const backendUrl = 'https://backend.4climate.in';

export const apiRoutes =
{
    webSocket : {
        path: `wss://backend.4climate.in/farm/data`
    },
    login: {
        path: `${backendUrl}/sign_in`,
    },
    verifyUser: {
        path: `${backendUrl}/verifyUser`,
    },
    register: {
        path: `${backendUrl}/sign_up`,
    },
    forgetPasswordOTP: {
        path: `${backendUrl}/forgotpassword`,
    },
    forgetPasswordConfirm:{
        path: `${backendUrl}/forgotpassword/reset`,
    },
    user: {
        getUserProfile: {
            path: `${backendUrl}/user/details`,
        },
        updateUserProfile: {
            path: `${backendUrl}/user/modify`,
        },
        changePassword:{
            path: `${backendUrl}/user/changepassword`,
        }
    },
    hydroponics: {
        loadfarms: {
            path: `${backendUrl}/farm/load`,
        },
        addFarm: {
            path: `${backendUrl}/farm/create`,
        },
        getZones: {
            path: `${backendUrl}/farm/zone/load`,
        },
        updateZone: {
            path: `${backendUrl}/farm/zone/modify`,
        },
        createZone: {
            path: `${backendUrl}/farm/zone/create`,
        },
        deleteZone: {
            path: `${backendUrl}/farm/zone/delete`,
        },
        addDevice: {
            path: `${backendUrl}/farm/zone/device/add`,
        },
        removeDevice: {
            path: `${backendUrl}/farm/zone/device/remove`,
        },
        editDevice: {
            path: `${backendUrl}/farm/zone/device/edit`,
        },
        calibrateVpd: {
            path: `${backendUrl}/farm/calibrate/vpd`,
        },
        deviceDetails: {
            path: `${backendUrl}/farm/zone/device/details`,
        },
        actuateRelay: {
            path: `${backendUrl}/farm/actuate/relay`,
        },
        getFarmDetails: {
            path: `${backendUrl}/farm/details`,
        },
        updateFarmDetails: {
            path: `${backendUrl}/farm/modify`,
        },
        deleteFarm: {
            path: `${backendUrl}/farm/delete`,
        },
        getInvitedUsers: {
            path: `${backendUrl}/farm/invite`,
        },
        inviteUser: {
            path: `${backendUrl}/farm/invite`,
        },
        uninviteUser: {
            path: `${backendUrl}/farm/uninvite`,
        },
        getTrendLine: {
            path: `${backendUrl}/trendline`,
        },
        getFarmAnalytics: {
            path: `${backendUrl}/graph_values`,
        },
        sendReport:{
            path: `${backendUrl}/report`,
        }
    }
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Background from 'Components/common/Background';
import Flex from 'Components/common/Flex';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import corner1 from 'assets/img/icons/spot-illustrations/corner-3.png';
import PropTypes from 'prop-types';

const EditDeviceSettings = (props) => {
  const EditDeviceSettingsData = [
    {
      title: 'Product ID',
      text: props.RelayDeviceId,
      icon: 'chess-rook',
      color: 'primary'
    },
    {
      title: 'Network',
      text: props.network,
      icon: 'crown',
      color: 'warning'
    },
    {
      title: 'Device Status',
      text: props.status==="1"?"Online":"Offline",
      icon: 'video',
      color: props.status==="1"?'success':"warning"
    },
    {
      title: 'Signal strength',
      text: '--/--/--',
      icon: 'user',
      color: 'info'
    }
  ];
  console.log("efgh1234props",props);
  
  return (
    <Card className="h-100">
      <Background image={corner1} className="rounded-soft bg-card" />
      <Card.Header className="z-index-1">
        <h5 className="text-primary">Edit Device Settings | Relay Switches | "{props.deviceData.deviceName }"</h5>
        <h6 className="text-600">Here are some quick information about your device</h6>
      </Card.Header>
      <Card.Body className="z-index-1">
        <Row className="g-2 h-100 align-items-end">
          {EditDeviceSettingsData.map(({ icon, color, title, text }) => {
            return (
              <Col xs={5} md={5} key={title}>
                <Flex className="position-relative">
                  <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                    <FontAwesomeIcon icon={icon} className={`text-${color}`} />
                  </div>
                  <div className="flex-1">
                    <Link to="#!" className="stretched-link">
                      <h6 className="text-800 mb-0">{title}</h6>
                    </Link>
                    <p className="mb-0 fs--2 text-500 ">{text}</p>
                  </div>
                </Flex>
              </Col>
            );
          })}
        </Row>
      </Card.Body>
    </Card>
  );
};


export default EditDeviceSettings;

import React, { useState, useContext, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Flex from 'Components/common/Flex';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ZoneContext } from './index';
import { vpdIntelligence } from 'helpers/intelligence';

const FarmIntelligence = ({ deviceValues }) => {

  const zoneData = useContext(ZoneContext);

  const [data, setData] = useState({
    currentTemp: 0,
    currentHumidity: 0,
    currentStage: '',
  });
  const [displayData, setDisplayData] = useState({});

  useEffect(() => {
    setData({
      currentTemp: deviceValues.temp,
      currentHumidity: deviceValues.hum,
      currentStage: zoneData.stage,
    })
  }, [zoneData, deviceValues]);

  useEffect(() => {
    console.log(data)
    let returnData = vpdIntelligence(data.currentTemp, data.currentHumidity, data.currentStage);
    console.log(returnData)
    setDisplayData(returnData);
  }, [data])

  return (

    <Card className="bg-light mb-3">
      <Card.Body className="p-3 position-relative">

        <Flex alignItems="center" className="mb-3">
          <FontAwesomeIcon icon='bug' className="text-primary" />
          <Link to="#!" className="stretched-link text-decoration-none">
            <h5 className="fs--1 text-600 mb-0 ps-3">Farm Intelligence (Beta)</h5>
          </Link>
        </Flex>
        {
          Object.keys(displayData).length > 0 ?
            (displayData.perfect) ?
              <p className="fs--1">Continue current setting for best growth!</p>
              :
              <p className="fs--1">Your current farm environment for the selected <strong>{displayData.stage}</strong> stage is <strong>{displayData.state}</strong>.
                The optimal vpd range should be between <strong>{displayData.optimalVpdmin}</strong> to <strong>{displayData.optimalVpdmax}</strong> kPa for faster growth.
                <br />
                {/* Your set target VPD is <strong>1.5kPa</strong> */}
                <br />
                Try keeping target temperature of around <strong>{displayData.idealTemp}</strong> degree celsius and Relative Humidity of <strong>{displayData.idealRH} %</strong> to achieve best farm environment conditions.
              </p>
            : ""
        }
        {/* <p className="fs--1">Take the temperature to around “10” degree celcius and Relative Humidity to “95” to acheive optimal vpd range between “1.2” to “1.5” kPa for faster growth.</p>
                        <p className="fs--1">Your set target VPD is 1.5kPa</p> */}

      </Card.Body>
    </Card>
  );
};

export default FarmIntelligence;

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col,  } from 'react-bootstrap'
import Flex from 'Components/common/Flex';
import { Link } from 'react-router-dom';

const DeviceSettingsCard = ({title , text, icon, color }) => {
    return (
        <Col xs={6} md={4} xxl={2} key={title}>
            <Flex className="position-relative">
                <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                    <FontAwesomeIcon icon={icon} className={`text-${color}`} />
                </div>
                <div className="flex-1">
                    <Link to="#!" className="stretched-link" style={{ pointerEvents: 'none' }}>
                        <h6 className="text-800 mb-0">{title}</h6>
                    </Link>
                    <p className="mb-0 fs--2 text-500">{text}</p>
                </div>
            </Flex>
        </Col>
    );
}

export default DeviceSettingsCard
import React, { useReducer, useState, useEffect } from 'react'
import { GlobalContext } from './Context/GlobalContext'
import AuthContext from './Context/AuthContext'
import useToggleStyle from './hooks/useToggleStyle'
import { getColor, getItemFromStore, setItemToStore } from './helpers/utils';
import { settings } from 'helpers/config';
import { configReducer } from 'reducers/configReducer';
import axios from 'axios';
import { apiRoutes } from 'API/apiRoutes';
import { authReducer } from 'reducers/authReducer';
import { verifyToken } from 'API/Authentication';

const Main = (props) => {

    const configState = {
        isFluid: getItemFromStore('isFluid', settings.isFluid),
        isRTL: getItemFromStore('isRTL', settings.isRTL),
        isDark: getItemFromStore('isDark', settings.isDark),
        navbarPosition: getItemFromStore('navbarPosition', settings.navbarPosition),
        isNavbarVerticalCollapsed: getItemFromStore(
            'isNavbarVerticalCollapsed',
            settings.isNavbarVerticalCollapsed
        ),
        navbarStyle: getItemFromStore('navbarStyle', settings.navbarStyle),
        currency: settings.currency,
        showBurgerMenu: settings.showBurgerMenu,
        showSettingPanel: false,
        navbarCollapsed: false,
    };
    const authStateinit = {
        isAuth: false,
        accessToken: "",
        username: "",
        isLoading: false,
        email:""
    }

    //Load default config for show farmintelligence
    if(getItemFromStore('showFarmIntelligence') === undefined){
        setItemToStore('showFarmIntelligence', "true");
    }

    const [config, configDispatch] = useReducer(configReducer, configState);
    const [authState, authDispatch] = useReducer(authReducer, authStateinit);

    useEffect(() => {
        
        let accessToken = localStorage.getItem('access_token');
        if (accessToken) {
            authDispatch({
                type: 'LOGIN_LOAD',
                payload: {}
            })
            verifyToken(accessToken, authDispatch);
        }
        else {
            console.log("no token");
            authDispatch({
                type: "LOGOUT",
                payload: {}
            })
        }

    }, [])


    const { isLoaded } = useToggleStyle(
        false,
        configState.isDark, //dark mode
        configDispatch
    );

    const setConfig = (key, value) => {
        configDispatch({
            type: 'SET_CONFIG',
            payload: {
                key,
                value,
                setInStore: [
                    'isFluid',
                    'isRTL',
                    'isDark',
                    'navbarPosition',
                    'isNavbarVerticalCollapsed',
                    'navbarStyle'
                ].includes(key)
            }
        });
    };


    if (!isLoaded) {
        return (
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    backgroundColor: configState.isDark ? getColor('dark') : getColor('light')
                }}
            />
        );
    }
    return (
        <GlobalContext.Provider value={{ config, setConfig }}>
            <AuthContext.Provider value={{ authState, authDispatch }}>
                {props.children}
            </AuthContext.Provider>
        </GlobalContext.Provider>
    )
}

export default Main
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'react-bootstrap';
import Lottie from 'react-lottie';
import animationData from './lottie/treecelebration.json';
import AddFarmContext from 'Context/AddFarmContext';
import { useHistory } from "react-router-dom";

const Success = ({ reset }) => {
  const { setStep } = useContext(AddFarmContext);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const emptyData = () => {
    setStep(1);
    reset();
  };


  const history = useHistory();

  const routeFinish = () =>{ 
    history.push('/farm-selection');
  }

  return (
    <>
      <Row>
        <Col className="text-center">
          <div className="wizard-lottie-wrapper"> 
            <div className="wizard-lottie mx-auto" style={{transform: "translateY(-50%)"}}>
              <Lottie options={defaultOptions} />
            </div>
          </div>
          <h4 className="mb-1">Your Farm is all set!</h4>
          <p className="fs-0">Now you can access to your Farms</p>
          <Row>
            <Col className='col-sm-6 col-12'>
          {/* <Button variant="falcon-primary" className="px-5 my-3 me-sm-1 me-0 w-100" onClick={emptyData}>
            Start Over
          </Button> */}
          </Col>
          <Col className='col-sm-6 col-12'>
          <Button className="px-5 my-3 ms-sm-1 ms-0 mw-100" variant="primary" onClick={routeFinish}> Done! </Button>
          </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

Success.propTypes = {
  reset: PropTypes.func.isRequired
};

export default Success;

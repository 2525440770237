import React, { useContext, useEffect, useState } from 'react';
import ProfileBanner from '../ProfileBanner';
import coverSrc from 'assets/img/generic/5.jpg';
import avatar from 'assets/img/team/avatar.png';
import { Col, Row } from 'react-bootstrap';
import ProfileSettings from './ProfileSettings';
import BillingSettings from './BillingSettings';
import ChangePassword from './ChangePassword';
import DangerZone from './DangerZone';
import AuthContext from 'Context/AuthContext';
import { getUserProfile } from 'API/profileAPI'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 2000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}

const Profile = () => {
  const { authState } = useContext(AuthContext)

  const [userData, setUserData] = useState({})
  const [isLoading, setIsLoading] = useState(false);

  const getUserProfileData = async () => {
    let resData = await getUserProfile(authState.accessToken)
    if (resData.success) {
      setUserData(resData.data)
    }
  }

  // const [isLoading, setIsLoading] = useState(false)


  useEffect(() => {
    getUserProfileData()
  }, [])

  return (
    <>
      <AlertProvider template={AlertTemplate} {...options}>
        <ProfileBanner>
          <ProfileBanner.Header
            coverSrc={coverSrc}
            avatar={avatar}
            className="mb-8"
          />
        </ProfileBanner>
        <Row className="g-3 mb-3">
          <Col xxl={12}>
            <ProfileSettings data={userData} />
          </Col>
        </Row>
        <Row className="g-3 mb-3">
          <Col xxl={12}>
            {/* <BillingSettings /> */}
            <ChangePassword />
            {/* <DangerZone /> */}
          </Col>
        </Row>
      </AlertProvider>
      </>
      );
};

      export default Profile;
